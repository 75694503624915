import { MeshBuilder, Texture, Vector3 } from '@babylonjs/core';
import { createAlphaMaterial } from '../../materials';
import { optimizationMesh } from './optimizationMesh';

export function createBucksModel() {
  const texture = new Texture('textures/sprites/cash.png');
  const material = createAlphaMaterial('cash', texture);
  const plane = MeshBuilder.CreatePlane('cashBase', { width: 0.3, height: 0.3 });
  plane.position = new Vector3(0, -2, 0);
  plane.material = material;
  optimizationMesh(plane);
  plane.setEnabled(false);
}
