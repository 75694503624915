import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { AppButton, Counter, Pointers, Praise, Top3, Tutorial } from '../../babylon/components';
import { DeadMenu } from '../../babylon/components/deadMenu/deadMenu';
import { Joystick } from '../../babylon/components/joystick/joystick';
import { ReactComponent as Coin } from '../../babylon/components/svg/coin.svg';

import { ReactComponent as Skull } from '../../babylon/components/svg/skull.svg';
import { gameStore } from '../../babylon/stores/gameStore';
import style from './style.module.sass';

const Ui = observer(() => {
  const skull: ReactElement = <Skull />;
  const coin: ReactElement = <Coin />;

  return (
    <>
      {gameStore.showTutorial && <Tutorial />}
      {gameStore.canUseJoystick && <Joystick />}
      <Pointers />
      <div className={style.counters}>
        <Counter img={skull} value={gameStore.killCounter} />
        <Counter img={coin} value={gameStore.moneyPickUp} size={40} />
      </div>
      <div className={style.leftContainer}>
        {process.env.NODE_ENV === 'development' && <AppButton text="Debug" color="yellow" onClick={gameStore.turnShowDebugLayer.bind(gameStore)} />}
        <AppButton text="Выход" color="yellow" onClick={gameStore.onGoHome} />
        <Top3 />
      </div>
      {gameStore.showPraise && <Praise text={gameStore.textPraise} />}
      {gameStore.showDeadMenu && <DeadMenu />}
    </>
  );
});

export default Ui;
