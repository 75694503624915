import { useDrop } from 'react-dnd';

import { Place } from '../../../../components/Place';
import { MergingPlaceProps } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export function EmptyPlace({ place }: { place: MergingPlaceProps }) {
  const [, drop] = useDrop(() => ({
    accept: ['FactoryPlace', 'MergingPlace', 'ParkingPlace'],
    drop: () => ({ place }),
  }));

  return (
    <Place type="merging" dataTutorial="merging-empty-place">
      <span ref={drop} className={style.icon} />
    </Place>
  );
}
