import { useNavigate, To } from 'react-router-dom';
import classNames from 'classnames';

import style from './style.module.scss';

export interface CloseButtonProps {
  path?: To;
  color: 'purple' | 'blue';
  className?: string
}

export function CloseButton({ path, color, className }: CloseButtonProps) {
  const navigate = useNavigate();

  const iconClass = classNames(style.close__icon, {
    [style.close__icon_purple]: color === 'purple',
    [style.close__icon_blue]: color === 'blue',
  });

  const onClose = () => {
    navigate(path || '');
  };

  return (
    <button type="button" className={classNames(style.close, className)} onClick={onClose}>
      <svg className={iconClass} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_26_9)">
          <path d="M2.95724 2.95731L10.8719 10.8719M10.8719 2.95731L2.95724 10.8719" stroke="none" strokeWidth="5" strokeLinecap="round" />
        </g>
        <defs>
          <filter
            id="filter0_i_26_9"
            x="0.457275"
            y="0.457275"
            width="12.9146"
            height="13.9147"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_26_9" />
          </filter>
        </defs>
      </svg>
    </button>
  );
}

CloseButton.defaultProps = {
  path: -1,
};
