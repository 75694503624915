// import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { ReactComponent as Note } from '../../assets/images/garage/musicalNote.svg';
import { ReactComponent as Speaker } from '../../assets/images/garage/speaker.svg';
import { gameStore } from '../../babylon/stores/gameStore';
import { CloseButton } from '../../components/CloseButton';
import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import Slider from './components/slider/slider';
import Toggle from './components/toggler/toggle';
import style from './style.module.scss';

export const Settings = observer(() => {
  const soundSettings = {
    category: 'Звуки',
    icon: <Speaker />,
    maxValue: 0.35,
    minValue: 0,
    value: gameStore.soundVolume,
    setter: gameStore.setSoundVolume.bind(gameStore),
  };

  const musicSettings = {
    category: 'Музыка',
    icon: <Note />,
    maxValue: 0.07,
    minValue: 0,
    value: gameStore.musicVolume,
    setter: gameStore.setMusicVolume.bind(gameStore),
  };

  const joystickSettings = {
    text: 'Зафиксировать джойстик',
    value: gameStore.staticJoyStick,
    setter: gameStore.setStaticJoyStick.bind(gameStore),
  };

  return (
    <ModalWindow>
      <div className={style.container}>
        <div className={style.close_container}>
          <CloseButton color="blue" />
        </div>

        <div className={style.header}>
          <StrokeText text="Настройки" maxWidth={800} style={style.text} />
        </div>

        <div className={style.content}>
          <div className={style.content_container}>
            <Slider {...soundSettings} />
            <Slider {...musicSettings} />
            <Toggle {...joystickSettings} />
          </div>
        </div>
      </div>
    </ModalWindow>
  );
});
