import { booleanPointInPolygon, buffer, lineString, nearestPointOnLine, polygonToLineString, simplify, union } from '@turf/turf';
import { Real } from '../../types';
import { ConvertorTurf } from '../converters';

function getNextPoint(point: Real, angle: number, distance: number) {
  const x = point.x + Math.cos(angle) * distance;
  const y = point.y + Math.sin(angle) * distance;
  return new Real(x, y);
}

export function getExtendedZone(zonePoints: Real[], tailPoints: Real[]) {
  // создаем полигон
  const polygon = ConvertorTurf.fromReal(zonePoints);
  const polygonLine = polygonToLineString(polygon);

  // создаем ломаную линию
  const line = lineString(ConvertorTurf.toLineString(tailPoints));
  const fistPoint = line.geometry.coordinates[0];
  const secondPoint = line.geometry.coordinates[2];
  if (fistPoint && secondPoint) {
    const prevPoint = getNextPoint(
      new Real(fistPoint[0], fistPoint[1]),
      Math.atan2(fistPoint[1] - secondPoint[1], fistPoint[0] - secondPoint[0]),
      0.2,
    ).toArray();

    if (!booleanPointInPolygon(fistPoint, polygon)) {
      if (polygonLine.type === 'Feature') {
        const nearPoint = nearestPointOnLine(polygonLine, prevPoint);
        line.geometry.coordinates.unshift(nearPoint.geometry.coordinates);
      } else {
        const nearPoint = nearestPointOnLine(polygonLine.features[0], prevPoint);
        line.geometry.coordinates.unshift(nearPoint.geometry.coordinates);
      }
    }
  }

  const lastPoint = line.geometry.coordinates[line.geometry.coordinates.length - 2];
  const penultimatePoint = line.geometry.coordinates[line.geometry.coordinates.length - 4];
  if (lastPoint && penultimatePoint) {
    const nextPoint = getNextPoint(
      new Real(lastPoint[0], lastPoint[1]),
      Math.atan2(lastPoint[1] - penultimatePoint[1], lastPoint[0] - penultimatePoint[0]),
      0.1,
    ).toArray();

    if (!booleanPointInPolygon(lastPoint, polygon)) {
      if (polygonLine.type === 'Feature') {
        const nearPoint = nearestPointOnLine(polygonLine, nextPoint);
        line.geometry.coordinates.splice(-1, 0, nearPoint.geometry.coordinates);
      } else {
        const nearPoint = nearestPointOnLine(polygonLine.features[0], nextPoint);
        line.geometry.coordinates.splice(-1, 0, nearPoint.geometry.coordinates);
      }
    }
  }

  // создаем полигон на основе ломаной линии
  const lineBuffer = buffer(line, 10, { units: 'meters' });

  const result = union(polygon, lineBuffer);
  if (!result) return undefined;
  const simplifiedPolygon = simplify(result, { tolerance: 0.005 });

  if (simplifiedPolygon?.geometry.type === 'Polygon') {
    return ConvertorTurf.toReal(simplifiedPolygon.geometry.coordinates);
  }

  return ConvertorTurf.toReal(simplifiedPolygon.geometry.coordinates[0]);
}
