import { useEffect } from 'react';
import { ConnectDragSource, ConnectDragPreview } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import style from './style.module.scss';

export interface CarIconProps {
  drag: ConnectDragSource;
  preview: ConnectDragPreview;
  src: string;
  lvl: number;
  type: 'parking' | 'factory' | 'merging';
}

const colorType = {
  factory: '#A73EC887',
  merging: '#A73EC887',
  parking: '#00AEE8',
};

export function CarIcon({ drag, preview, src, lvl, type }: CarIconProps) {
  const styleIcon = {
    display: 'inline-block',
    width: '47px',
    height: '41px',
    backgroundImage: `url(${src})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return (
    <div>
      <span ref={drag} style={styleIcon} />
      <div style={{ backgroundColor: colorType[type] }} className={style.level}>
        {lvl}
      </div>
    </div>
  );
}
