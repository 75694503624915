import { Vector2 } from '@babylonjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { gameStore } from '../../stores/gameStore';
import style from './style.module.sass';

function getNearestPointOnCircle(center: Vector2, radius: number, point: Vector2): Vector2 {
  const distance = Math.sqrt((point.x - center.x) ** 2 + (point.y - center.y) ** 2);
  if (distance <= radius) {
    return point.clone(); // точка уже на границе круга
  }

  const direction = point.subtract(center).normalize();
  return center.add(direction.scale(radius)); // координаты ближайшей точки на границе
}

function isPointInsideCircle(point: Vector2, startPosition: Vector2, radius: number): boolean {
  const distance = Math.sqrt((point.x - startPosition.x) ** 2 + (point.y - startPosition.y) ** 2);
  return distance <= radius;
}

function normalize(value: number, min: number, max: number): number {
  return ((value - min) / (max - min)) * 2 - 1;
}

export function Joystick() {
  const [positionDisk, setPositionDisk] = useState({ x: 0, y: 0 });
  const [positionStick, setPositionStick] = useState({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const diskRef = useRef<HTMLDivElement>(null);
  const stickRef = useRef<HTMLDivElement>(null);

  const isStatic = gameStore.staticJoyStick;

  function onFocus() {
    gameStore.setDirectionalJoystick(undefined);
  }

  function onBlur() {
    if (!containerRef.current || !diskRef.current || !stickRef.current) return;

    const rectDisk = diskRef.current.getBoundingClientRect();
    const rectStick = stickRef.current.getBoundingClientRect();

    const startPosStickX = rectDisk.width / 2 - rectStick.width / 2;
    const startPosStickY = rectDisk.height / 2 - rectStick.height / 2;
    setPositionStick({
      x: startPosStickX,
      y: startPosStickY,
    });
  }

  useEffect(() => {
    if (!containerRef.current || !diskRef.current || !stickRef.current) {
      return () => {};
    }

    let rectContainer = containerRef.current.getBoundingClientRect();
    let rectDisk = diskRef.current.getBoundingClientRect();
    let rectStick = stickRef.current.getBoundingClientRect();

    const startPosDiskX = rectContainer.width / 2 - rectDisk.width / 2;
    const startPosDiskY = rectContainer.height / 1.2 - rectDisk.height / 2;
    setPositionDisk({
      x: startPosDiskX,
      y: startPosDiskY,
    });

    const startPosStickX = rectDisk.width / 2 - rectStick.width / 2;
    const startPosStickY = rectDisk.height / 2 - rectStick.height / 2;
    setPositionStick({
      x: startPosStickX,
      y: startPosStickY,
    });

    const startDisk = { x: startPosDiskX, y: startPosDiskY };

    const handleClick = (event: MouseEvent | TouchEvent) => {
      event.preventDefault();
      if (!containerRef.current || !diskRef.current || !stickRef.current) return;
      rectContainer = containerRef.current.getBoundingClientRect();
      rectDisk = diskRef.current.getBoundingClientRect();
      rectStick = stickRef.current.getBoundingClientRect();

      gameStore.setPressedJoystick(true);

      let dx;
      let dy;

      if ('touches' in event) {
        dx = event.touches[0].clientX - rectDisk.width / 2;
        dy = event.touches[0].clientY - rectDisk.height / 2;
      } else {
        dx = event.clientX - rectDisk.width / 2;
        dy = event.clientY - rectDisk.height / 2;
      }

      startDisk.x = dx;
      startDisk.y = dy;

      setPositionDisk({
        x: dx,
        y: dy,
      });

      setPositionStick(({
        x: rectDisk.width / 2 - rectStick.width / 2,
        y: rectDisk.height / 2 - rectStick.height / 2,
      }));
    };

    const handleMouseUp = () => {
      gameStore.setPressedJoystick(false);
      setPositionStick({
        x: rectDisk.width / 2 - rectStick.width / 2,
        y: rectDisk.height / 2 - rectStick.height / 2,
      });
    };

    const handleMouseMove = (event: MouseEvent | TouchEvent) => {
      event.preventDefault();
      if (!gameStore.joystickIsPressed) return;

      let x;
      let y;

      if ('touches' in event) {
        x = event.touches[0].clientX - startDisk.x - rectStick.width / 2;
        y = event.touches[0].clientY - startDisk.y - rectStick.height / 2;
      } else {
        x = event.clientX - startDisk.x - rectStick.width / 2;
        y = event.clientY - startDisk.y - rectStick.height / 2;
      }

      const radius = rectDisk.width / 2 - rectStick.width / 2;

      const centerForInner = new Vector2(rectDisk.width / 2 - rectStick.width / 2, rectDisk.height / 2 - rectStick.height / 2);

      if (!isPointInsideCircle(new Vector2(x, y), centerForInner, radius)) {
        const nearPoint = getNearestPointOnCircle(centerForInner, radius, new Vector2(x, y));
        if (!isStatic) {
          // calculate distance between outer circle and nearest point on inner circle
          const distanceToNearestPoint = Vector2.Distance(nearPoint, new Vector2(x, y)) * -1;

          // calculate movement direction and distance
          const dirVector = new Vector2(nearPoint.x - x, nearPoint.y - y).normalize().scale(distanceToNearestPoint);

          // move the outer circle
          setPositionDisk({
            x: startDisk.x + dirVector.x,
            y: startDisk.y + dirVector.y,
          });

          startDisk.x += dirVector.x;
          startDisk.y += dirVector.y;
        }

        x = nearPoint.x;
        y = nearPoint.y;
      }

      setPositionStick({ x, y });

      const dirX = -normalize(x, 0, radius * 2);
      const dirY = -normalize(y, 0, radius * 2);
      const direction = new Vector2(dirX, dirY);
      if (!isPointInsideCircle(direction, new Vector2(0, 0), 0.45)) {
        gameStore.setDirectionalJoystick(direction);
      } else {
        gameStore.setDirectionalJoystick(undefined);
      }
    };

    containerRef.current.addEventListener('mousedown', handleClick);
    containerRef.current.addEventListener('touchstart', handleClick);
    containerRef.current.addEventListener('mouseup', handleMouseUp);
    containerRef.current.addEventListener('touchend', handleMouseUp);
    containerRef.current.addEventListener('mousemove', handleMouseMove);
    containerRef.current.addEventListener('touchmove', handleMouseMove);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);

      if (!containerRef.current) return;
      containerRef.current.removeEventListener('mousedown', handleClick);
      containerRef.current.removeEventListener('touchstart', handleClick);
      containerRef.current.removeEventListener('mouseup', handleMouseUp);
      containerRef.current.removeEventListener('touchend', handleMouseUp);
      containerRef.current.removeEventListener('mousemove', handleMouseMove);
      containerRef.current.removeEventListener('touchmove', handleMouseMove);
    };
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={style.container}
    >
      <div
        ref={diskRef}
        className={style.disk}
        style={{
          left: positionDisk.x,
          top: positionDisk.y,
        }}
      >
        <div
          ref={stickRef}
          className={style.stick}
          style={{
            left: positionStick.x,
            top: positionStick.y,
          }}
        />
      </div>
    </div>
  );
}
