import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CoinsCount } from '../../../../../components/CoinsCount';
import { Place } from '../../../../../components/Place';
import { useStore } from '../../../../../hooks/useStore';
import { ParkPlaceProps } from '../../../../../stores/ParkingStore/types';
import style from './style.module.scss';

import { ReactComponent as Tube1 } from './TN1.svg';
import { ReactComponent as Tube2 } from './TN2.svg';
import { ReactComponent as Tube3 } from './TN3.svg';
import { ReactComponent as Tube4 } from './TN4.svg';
import './style.css';

export interface DropResult {
  line: ParkPlaceProps;
}

export const RefuelingPlace = observer(({ line }: DropResult) => {
  const { parkingStore, userStore, carsStore } = useStore();
  const { cars } = carsStore;
  const { user } = userStore;
  let timer: NodeJS.Timeout | null = null;
  const navigate = useNavigate();
  const [idTube, setTube] = useState<null | number>(null);
  const statusBuyPlace = line.places[0].statusPlace !== 'sale';
  const isGasStationSold = line.statusGas === 'sold';
  const isGasStationSale = line.statusGas === 'sale';
  const lineCars = cars.filter((car) => line.places.find((carInLine) => carInLine.carId === car.id));
  const isLineHasCar = lineCars.length > 0;

  const iconClass = classNames(style.icon, {
    [style.icon_active]: isGasStationSold,
    [style.icon_buy]: isGasStationSale,
    [style.icon_buy_noBuy]: isGasStationSale && user.soft - line.priceGas < 0,
    [style.icon_locked]: isGasStationSale && !statusBuyPlace,
    [style.icon_charging]: idTube !== null,
  });

  const onClickPlace = () => {
    if (line.statusGas === 'sale') {
      if (!statusBuyPlace) return;
      if (isGasStationSale && user.soft - line.priceGas < 0) {
        navigate('/shop');
        return;
      }

      parkingStore.buyGas(user.id, line.line);
    }
  };

  const choiceTube = () => {
    setTube(null);
    for (let i = 0; i < line.places.length; i++) {
      if (!line.places[i].carId) continue;
      const car = cars.find((carLoop) => carLoop.id === line.places[i].carId);
      if (!car) continue;
      if (car.fuel >= car.maxFuel) continue;
      setTube(i);
      break;
    }
  };

  const setTimer = () => {
    if (!isGasStationSold) return;
    if (!line.timer) return;
    if (timer) return;

    const handleTimeout = async () => {
      if (timer) clearTimeout(timer);

      await parkingStore.loadParking(user.id);
      await carsStore.loadCarsByUser(user.id);
    };

    for (const car of lineCars) {
      if (car.fuel >= car.maxFuel) continue;
      timer = setTimeout(handleTimeout, line.timer);
      return;
    }
  };

  useEffect(() => {
    if (isLineHasCar) setTimer();

    return () => {
      if (!timer) return;
      clearTimeout(timer);
      timer = null;
    };
  }, [line.timer]);

  useEffect(() => {
    choiceTube();
  }, [cars, line]);

  return (
    <>
      <Place onClick={onClickPlace} type="parking" size="small" status={idTube !== null ? 'active charging' : 'active'}>
        {isGasStationSale && statusBuyPlace ? <CoinsCount fontSize="small" count={line.priceGas} place="parking" /> : <span className={iconClass} />}
      </Place>
      <div className={classNames(style.petrolTubes)}>
        {line.places[0].carId && idTube === 0 && isGasStationSold && <Tube1 />}
        {line.places[1].carId && idTube === 1 && isGasStationSold && <Tube2 />}
        {line.places[2].carId && idTube === 2 && isGasStationSold && <Tube3 />}
        {line.places[3].carId && idTube === 3 && isGasStationSold && <Tube4 />}
      </div>
    </>
  );
});
