import { ConvertorPoints } from './ConvertorPoints';
import { ConvertorVector } from './ConvertorVector';
import { ConvertorVectors } from './ConvertorVectors';
import { ConvertorServer } from './ConvertorServer';
import { ConvertorTurf } from './ConvertorTurf';

export class Convertors {
  static points = ConvertorPoints;
  static vector = ConvertorVector;
  static vectors = ConvertorVectors;
  static server = ConvertorServer;
  static polygons = ConvertorTurf;
}
