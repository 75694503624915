import classNames from 'classnames';

import { Reward } from '../../../../stores/RewardsStore';
import { StrokeText } from '../../../../components/StrokeText';

import style from './style.module.scss';

export interface RewardPlaceProps {
  reward: Reward;
}

export function RewardPlace({ reward }: RewardPlaceProps) {
  const { leagueLevel, min, title, hard, up, down, nextLevel } = reward;

  const first = min === 1;

  const headerClass = classNames(style.header, style[`header_${leagueLevel}`]);
  const headerTextClass = classNames(style.header__text, classNames(style[`header__text_${leagueLevel}`]));
  const contentClass = classNames(style.content, classNames(style[`content_${leagueLevel}`]));
  const rewardItemClass = classNames(style.content__reward__item, style[`content__reward__item_${leagueLevel}`]);
  const rewardedItemHardClass = classNames(style.content__reward__item_hard, style[`content__reward__item__text_${leagueLevel}`]);
  const rewardedItemLevelClass = classNames(style.content__reward__item_level, style[`content__reward__item__text_${leagueLevel}`]);

  return (
    <div className={style.container}>
      <header className={headerClass}>
        <StrokeText style={headerTextClass} text={title} />
      </header>
      <div className={contentClass}>
        <div className={style.content__reward}>
          <div className={rewardItemClass}>
            <span className={style.icon_hard} />
            <StrokeText style={rewardedItemHardClass} text={hard} />
          </div>
          <div className={rewardItemClass}>
            <span className={style.icon_cup} />
            {up && <span className={style.icon_up} />}
            {down && <span className={style.icon_down} />}
            <StrokeText style={rewardedItemLevelClass} text={nextLevel || ''} />
          </div>
        </div>
        {first && <span className={style.icon_crown} />}
      </div>
    </div>
  );
}
