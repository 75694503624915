import * as BABYLON from '@babylonjs/core';
import { Observable, Scene, Sound } from '@babylonjs/core';
import { top3Store } from '../../components/top3/top3store';

import { getDistance, inverseLerp, lerp } from '../../helpers';
import { Bucks } from '../../meshs/environment/bonuses/bucks';
import { PickupItem } from '../../meshs/environment/bonuses/pickupItem';
import { gameStore } from '../../stores/gameStore';
import { Real } from '../../types';
import { BaseAgent, Coin, IBaseAgent } from '../agent';
import { Crown } from '../agent/crown';
import { IPlayerMP, Player, TrackedEntity } from '../player';
import { PlayerController } from './playerController';

export class ManagerMP {
  agents: BaseAgent[] = [];
  curPlayer?: Player;
  playerController: PlayerController;
  coins: Map<string, Coin> = new Map();
  bonuses: Map<string, PickupItem> = new Map();
  bucks: Map<string, Bucks> = new Map();
  top: BaseAgent[] = [];

  crown: Crown;

  onCreateCurPlayerObservable = new Observable<Player>();

  onBeforeRenderLoopObservable = new Observable();
  onAfterRenderLoopObservable = new Observable();
  onBeforeLogicLoopObservable = new Observable();
  onAfterLogicLoopObservable = new Observable();

  timerLogic: NodeJS.Timer;

  volume = gameStore.soundVolume;
  killSound!: Sound;
  reviveSound!: Sound;
  coinPickUpSound!: Sound;
  deathSound!: Sound;
  expansionSound!: Sound;
  canPlaySound = true;

  scene: Scene;

  constructor(scene: Scene) {
    this.scene = scene;
    scene.registerBeforeRender(this.renderLoop.bind(this));
    this.timerLogic = setInterval(this.logicLoop.bind(this), 10);
    this.playerController = new PlayerController(this);
    this.crown = new Crown(this.scene);

    this.createSound();
  }

  get(id: string) {
    return this.agents.find((agent) => agent.id === id);
  }

  getCoin(id: string) {
    return this.coins.get(id);
  }

  getBonus(id: string) {
    return this.bonuses.get(id);
  }

  public renderLoop() {
    if (this.scene.isDisposed) return;
    this.onBeforeRenderLoopObservable.notifyObservers(this);
    this.crown.updatePosition();
    this.crown.updateRotation();
    this.onAfterRenderLoopObservable.notifyObservers(this);
  }

  public logicLoop() {
    if (this.scene.isDisposed) return;
    this.onBeforeLogicLoopObservable.notifyObservers(this);
    this.onAfterLogicLoopObservable.notifyObservers(this);
  }

  createPlayer(params: IPlayerMP) {
    const player = new Player(params);
    this.agents.push(player);
    this.curPlayer = player;
    this.onCreateCurPlayerObservable.notifyObservers(player);

    this.playerController.commitPlayer(player);
    this.onBeforeRenderLoopObservable.add(() => {
      player.checkDirection();
      this.playerController.movePlayer(player);
      player.writeTail();
    });

    player.onDeathObservable.add(() => {
      if (!this.canPlaySound) return;
      this.deathSound.setVolume(this.volume);
      this.deathSound.play();
    });

    player.onSpawnObservable.add(() => {
      if (!this.canPlaySound) return;
      this.deathSound.setVolume(this.volume);
      this.reviveSound.play();
    });

    player.zone.onExtendActionObserver.add(() => {
      if (!this.canPlaySound) return;
      this.expansionSound.play();
    });

    player.onKillObserver.add(() => {
      if (!this.canPlaySound) return;
      this.killSound.play();
    });

    return player;
  }

  createConnectedPlayer(params: IBaseAgent) {
    const connectedPlayer = new TrackedEntity(params);
    this.agents.push(connectedPlayer);

    this.playerController.commitPlayer(connectedPlayer);
    this.onBeforeRenderLoopObservable.add(() => {
      connectedPlayer.writeTail();
    });

    connectedPlayer.onDeathObservable.add(() => {
      if (!this.canPlaySound) return;
      if (!connectedPlayer.car.mesh) return;
      if (!connectedPlayer.car.mesh.position) return;
      if (!this.curPlayer) return;
      if (!this.curPlayer.car.mesh) return;
      if (!this.curPlayer.car.mesh.position) return;
      const distance = getDistance(connectedPlayer.getPos(), this.curPlayer.getPos());
      if (distance > 10) return;
      const factor = inverseLerp(5, 10, distance);
      const volume = lerp(this.volume, 0, factor);
      this.deathSound.setVolume(volume);
      this.deathSound.play();
    });

    connectedPlayer.onSpawnObservable.add(() => {
      if (!this.canPlaySound) return;
      if (!connectedPlayer.car.mesh) return;
      if (!connectedPlayer.car.mesh.position) return;
      if (!this.curPlayer) return;
      if (!this.curPlayer.car.mesh) return;
      if (!this.curPlayer.car.mesh.position) return;
      const distance = getDistance(connectedPlayer.getPos(), this.curPlayer.getPos());
      if (distance > 10) return;
      const factor = inverseLerp(5, 10, distance);
      const volume = lerp(this.volume, 0, factor);
      this.reviveSound.setVolume(volume);
      this.reviveSound.play();
    });

    return connectedPlayer;
  }

  getAllPlayer() {
    return this.agents;
  }

  createSound() {
    const { volume } = this;
    this.killSound = new BABYLON.Sound('kill', 'sounds/kill.mp3', null, null, { volume });
    this.reviveSound = new BABYLON.Sound('revive', 'sounds/revive.mp3', null, null, { volume });
    this.deathSound = new BABYLON.Sound('death', 'sounds/death2.mp3', null, null, { volume });
    this.expansionSound = new BABYLON.Sound('expansion', 'sounds/zoneExtension.mp3', null, null, { volume });
    this.coinPickUpSound = new BABYLON.Sound('coinPickUp', 'sounds/pickupCoin.mp3', null, null, { volume });

    window.addEventListener('blur', () => {
      if (this.scene.isDisposed) return;
      this.killSound.stop();
      this.reviveSound.stop();
      this.deathSound.stop();
      this.expansionSound.stop();
      this.coinPickUpSound.stop();

      this.canPlaySound = false;
    });

    window.addEventListener('focus', () => {
      if (this.scene.isDisposed) return;
      this.canPlaySound = true;
    });
  }

  getCurrentPlayer() {
    return this.curPlayer;
  }

  calculateTop() {
    this.top = [];
    for (const entity of this.getAllPlayer()) {
      if (!entity.isAlive) continue;
      this.top.push(entity);
    }

    this.top.sort((a, b) => b.zone.percentageArea - a.zone.percentageArea);

    this.makeKing(this.top[0]);

    top3Store.calculateTop3(this.top);
  }

  makeKing(player: BaseAgent) {
    if (this.scene.isDisposed) return;
    if (!player.isAlive) return;

    const oldKing = this.crown.owner;
    if (oldKing && (oldKing.id === player.id)) return;

    gameStore.setIdKing(player.id);
    if (player.id === this.curPlayer?.id) {
      this.crown.updateHeight(1);
    } else {
      this.crown.updateHeight(1.5);
    }

    if (!this.crown.mesh) {
      this.crown.create(player);
    } else {
      this.crown.updateOwner(player);
    }
  }

  createBucks(id: string, pos: Real) {
    if (this.scene.isDisposed) return;
    const createdBucks = new Bucks({ id, position: pos });
    this.bucks.set(id, createdBucks);
    createdBucks.onDelete.add(() => {
      this.deleteBucks(id);
    });
  }

  getBucks(id: string) {
    return this.bucks.get(id);
  }

  deleteBucks(id: string) {
    this.bucks.delete(id);
  }
}
