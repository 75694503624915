import { Color3, MeshBuilder, Tags, Vector2, Vector3 } from '@babylonjs/core';
import { Convertors } from '../../helpers';
import { createColorMaterial } from '../../materials';

interface IOptions {
  height?: number;
  diameterBottom?: number;
  diameterTop?: number;
  color?: Color3;
  delay?: number;
}

export function createMark(point: Vector3 | Vector2, options?: IOptions) {
  const {
    height = 1,
    diameterBottom = 0.05,
    diameterTop = 0,
    color = new Color3(1, 0, 0),
    delay = 1,
  } = options ?? {};

  const mark = MeshBuilder.CreateCylinder('mark', { height, diameterBottom, diameterTop });
  mark.position = Convertors.vector.toV3(point);
  mark.position.y += height / 2;
  mark.material = createColorMaterial(color.r, color.g, color.b, 'materialMark');
  mark.material.alpha = 0.75;
  Tags.AddTagsTo(mark, 'mark');

  if (delay <= 0) return mark;

  setTimeout(() => {
    mark.dispose(false, true);
  }, delay * 1000);

  return mark;
}
