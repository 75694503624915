import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import style from './style.module.scss';

interface IProps {
  text: string;
  value: boolean;
  setter: (value: boolean) => void;
}

const Toggle = observer((props: IProps) => {
  const { text, value, setter } = props;

  const sliderClassName = classNames(style.round, style.slider);

  const onClick = () => setter(!value);

  return (
    <div className={style.container}>
      <div className={style.text}>
        {text}
      </div>
      <label className={style.switch}>
        <input type="checkbox" className={style.checkbox} checked={value} />
        <span className={sliderClassName} onClick={onClick} />
      </label>
    </div>
  );
});
export default Toggle;
