import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Panel } from '../../../components/Panel';
import { StrokeText } from '../../../components/StrokeText';
import { MainSection } from './MainSection';

import style from './style.module.scss';
import { UpgradeSection } from './UpgradeSection';

export interface ParkingProps {
  wrapped: boolean;
  onWrapped: (state: boolean) => void;
}

export function Parking({ wrapped, onWrapped }: ParkingProps) {
  const [upgrade] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const contentClass = classNames(style.content, {
    [style.content_overflow]: overflow,
    [style.content_unwrap]: !wrapped,
  });

  useEffect(() => {
    if (!contentRef.current) return;
    const { scrollHeight, clientHeight } = contentRef.current;
    setOverflow(scrollHeight > clientHeight);
  }, [wrapped]);

  // const upgradeButton = upgrade ? (
  //   <button type="button" onClick={() => setUpgrade(false)}>
  //     <StrokeText text="Назад" />
  //   </button>
  // ) : (
  //   <button type="button" onClick={() => setUpgrade(true)}>
  //     <StrokeText text="Улучшить" />
  //   </button>
  // );

  const header = (
    <div className={style.header}>
      <div>
        <StrokeText text="Парковка" />
        {/* {upgradeButton} */}
      </div>
      <div className={style.wrapParking__icon}>
        <input
          type="checkbox"
          id="wrapParking_cb"
          className={style.wrapParking__icon_cb}
          checked={wrapped}
          onChange={(e) => onWrapped(e.target.checked)}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="wrapParking_cb">
          <span className={style.wrapParking__icon_wrap} />
          <span className={style.wrapParking__icon_unwrap} />
        </label>
      </div>
    </div>
  );

  const content = (
    <div ref={contentRef} className={contentClass}>
      {upgrade ? <UpgradeSection /> : <MainSection />}
    </div>
  );

  return <Panel type="parking" header={header} content={content} />;
}
