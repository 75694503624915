import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { CarIcon } from '../../../../components/CarIcon';
import { Place, PlaceStatusType } from '../../../../components/Place';

import { useStore } from '../../../../hooks/useStore';
import { Car } from '../../../../stores/CarsStore/Car';
import { FactoryPlaceProps, MergingPlaceProps, ParkPlacesPlace } from '../../../../stores/ParkingStore/types';
import style from './style.module.scss';

export interface DropResult {
  place: FactoryPlaceProps | ParkPlacesPlace;
}

export const IssuePlace = observer(({ place }: { place: MergingPlaceProps }) => {
  const navigate = useNavigate();
  const { parkingStore, carsStore, userStore, tutorialStore } = useStore();
  const { cars } = carsStore;
  const [car, setCar] = useState<Car>();

  const status: PlaceStatusType = carsStore.selectedCar?.id === place.carId ? 'selected' : 'active';
  const refIndicatorFill = useRef<HTMLSpanElement>(null);
  const noFull = car ? car.fuel < car.car.maxFuel : false;
  const indicatorClass = classNames(style.indicator, style.indicator_idle);

  const [, drop] = useDrop(() => ({
    accept: ['FactoryPlace', 'MergingPlace', 'ParkingPlace'],
    drop: () => ({ place }),
  }));

  const [, drag, preview] = useDrag(() => ({
    type: 'MergingPlace',
    item: place,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (!dropResult || !item.carId) return;
      parkingStore.moveCar(
        userStore.user.id,
        item.carId,
        dropResult.place.type,
        dropResult.place.idx,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dropResult.place.idxLine ? dropResult.place.idxLine : 0,
      );
    },
  }));

  useEffect(() => {
    const temp = cars.find((c) => c.id === place.carId);
    if (temp) setCar(temp);
  }, []);

  useEffect(() => {
    if (car) {
      const cc = cars.filter((c) => c.id !== car.id && car.car.lvl <= c.car.lvl);
      // TODO Убрать поздравление в туторе
      if (cars.length > 0 && cc.length === 0 && tutorialStore.isLastStep) {
        navigate(`/congrats?id=${place.carId}`, { replace: true });
      }
    }
  }, [car]);

  useEffect(() => {
    if (!car || !refIndicatorFill.current) return;
    const fill = Math.floor((car.fuel / car.car.maxFuel) * 100);
    const fillEl = refIndicatorFill.current;
    fillEl.style.width = `${fill}%`;
  }, [car, car?.fuel]);

  const onClickPlace = () => {
    if (place.carId) carsStore.setSelectedCar(place.carId);
  };

  return (
    <Place type="merging" onClick={onClickPlace} status={status}>
      <span ref={drop}>
        {car && (
        <>
          <CarIcon type="merging" lvl={car.car.lvl} drag={drag} preview={preview} src={`/models/cars/${car.car.model}Icon.png`} />
          {noFull && (
          <div className={indicatorClass}>
            <span ref={refIndicatorFill} className={style.indicator_fill} />
            <span className={style.indicator_text}>{car.fuel}</span>
          </div>
          )}
        </>
        )}
      </span>
    </Place>
  );
});
