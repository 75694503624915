import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import style from './style.module.scss';
import plusIcon from '../../../assets/images/garage/icon-plus.png';
import coinIcon from '../../../assets/images/garage/icon-coin.png';
import cashIcon from '../../../assets/images/garage/icon-cash.png';
import abbrNum from '../../../services/abbrNum';

export interface AssetPanelProps {
  value: number;
  type: 'coin' | 'cash';
}

export function AssetPanel({ value, type }: AssetPanelProps) {
  const [coin, setCoin] = useState<null | number>(null);
  const [isPlus, setPlus] = useState<boolean>(true);
  const [isNotice, setNotice] = useState<boolean>(false);
  const [isAnimated, setAnimated] = useState<boolean>(false);
  const [deltaCoin, setDeltaCoin] = useState<number>(0);
  const assetIcon = useMemo(() => {
    if (type === 'coin') return coinIcon;
    if (type === 'cash') return cashIcon;
    return null;
  }, [type]);

  useEffect(() => {
    setCoin((prevCoin) => {
      if (prevCoin !== null && prevCoin !== value - prevCoin) {
        setPlus(value - prevCoin > 0);
        setDeltaCoin(value - prevCoin);
        if (value - prevCoin !== 0) {
          setNotice(true);
          setTimeout(() => {
            setAnimated(true);
          }, 300);
          setTimeout(() => {
            setNotice(false);
          }, 3000);
        }
      }
      return value;
    });
  }, [value]);

  const navigate = useNavigate();
  const onOpenShop = () => {
    navigate('shop');
  };

  const noticeStyle = classNames(style['panel__coin-notice'], {
    [style['panel__coin-notice-active']]: isNotice && coin !== deltaCoin,
    [style['panel__coin-notice-plus']]: isPlus,
    [style['panel__coin-notice-minus']]: !isPlus,
    [style['panel__coin-notice-animation']]: isAnimated,
  });

  return (
    <div className={style.panel}>
      <button type="button" className={style.panel__btnAdd} onClick={onOpenShop}>
        <img src={plusIcon} alt="plus button" />
      </button>
      <div className={style.panel__coin}>
        <span>{abbrNum(coin || 0)}</span>
        <span className={noticeStyle}>
          {isPlus && '+'}
          {deltaCoin}
        </span>
      </div>
      {assetIcon ? <img className={style.panel__icon} src={assetIcon} alt="asset's icon" /> : null}
    </div>
  );
}
