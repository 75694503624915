export interface Countdown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  totalInSec: number;
}

export class Timer {
  private static ONE_DAY = 24 * 60 * 60;
  private static ONE_HOUR = 60 * 60;
  private static ONE_MINUTE = 60;
  private deadlineInMS = 0;

  private timerId: ReturnType<typeof setTimeout> | null = null;

  public countdown = {} as Countdown;
  public running = false;
  public onUpdate?: (countdown: Countdown) => void;
  public onElapsed?: () => void;

  public constructor(private updateInSec = 0) {}

  public start(countDownInSec: number) {
    this.running = true;
    this.deadlineInMS = Date.now() + countDownInSec * 1000;
    this.updateLastTime();
  }

  public stop() {
    if (!this.timerId) return;
    clearTimeout(this.timerId);
    this.resetState();
  }

  public updateCountdown(seconds: number) {
    if (!this.timerId) return;
    clearTimeout(this.timerId);
    this.deadlineInMS -= seconds * 1000;

    if (this.deadlineInMS < 0) {
      this.countdown = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalInSec: 0,
      };
      this.resetState();
      this.emitElapsed();
    }

    this.updateLastTime();
  }

  private updateLastTime() {
    this.countdown = this.getCountdown();

    this.emitUpdate();
    if (this.countdown.totalInSec < 1) {
      this.resetState();
      this.emitElapsed();
      return;
    }

    const intervalInSec = this.calcIntervalInSec(this.countdown);
    this.timerId = setTimeout(() => this.updateLastTime(), intervalInSec * 1000);
  }

  private getCountdown(): Countdown {
    const currentTime = Math.round((this.deadlineInMS - Date.now()) / 1000);
    if (currentTime <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalInSec: 0,
      };
    }

    const days = Math.floor(currentTime / Timer.ONE_DAY);
    const hours = Math.floor((currentTime - days * Timer.ONE_DAY) / Timer.ONE_HOUR);
    const minutes = Math.floor((currentTime - days * Timer.ONE_DAY - hours * Timer.ONE_HOUR) / Timer.ONE_MINUTE);
    const seconds = Math.floor(currentTime - days * Timer.ONE_DAY - hours * Timer.ONE_HOUR - minutes * Timer.ONE_MINUTE);

    return {
      days,
      hours,
      minutes,
      seconds,
      totalInSec: currentTime,
    };
  }

  private calcIntervalInSec(countdown: Countdown) {
    if (this.updateInSec > 0) return this.updateInSec;
    return countdown.hours > 0 ? 60 : 1;
  }

  private emitUpdate() {
    if (this.onUpdate) this.onUpdate(this.countdown);
  }

  private emitElapsed() {
    if (this.onElapsed) this.onElapsed();
  }

  private resetState() {
    this.timerId = null;
    this.running = false;
  }
}

export function convertCountdownToPartFormat({ hours, minutes, seconds }: Countdown) {
  if (hours > 0) {
    let roundMinutes = minutes + (seconds > 0 ? 1 : 0);
    const roundHours = hours + (roundMinutes === 60 ? 1 : 0);
    roundMinutes = roundMinutes === 60 ? 0 : roundMinutes;

    return `${roundHours}ч ${roundMinutes.toLocaleString('en-US', { minimumIntegerDigits: 2 })}м`;
  }
  if (minutes > 0) {
    return `${minutes}м ${seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 })}с`;
  }
  return `${seconds}с`;
}

function printDays(count: number) {
  const lastDigit = count % 10;

  if (lastDigit === 1 && count !== 11) {
    return 'день';
  }
  if (lastDigit > 1 && lastDigit < 5 && count < 12 && count > 14) {
    return 'дня';
  }
  return 'дней';
}

export function convertCountdownToFullFormat({ days, hours, minutes, seconds }: Countdown) {
  const hoursTwoDigits = hours.toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const minutesTwoDigits = minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 });
  const secondsTwoDigits = seconds.toLocaleString('en-US', { minimumIntegerDigits: 2 });

  return `${days} ${printDays(days)} ${hoursTwoDigits}:${minutesTwoDigits}:${secondsTwoDigits}`;
}
