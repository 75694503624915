import React from 'react';
import style from './style.module.sass';

interface IProps<T extends string | number> {
  name: string;
  value: T;
  setter: (value: T) => void;
  tip?: string;
}

export function Field<T extends string | number>(props: IProps<T>) {
  const { name, value, setter, tip = '' } = props;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changeValue = event.target.value;
    const newValue = typeof value === 'number' ? Number(changeValue) : changeValue as T;
    setter(newValue as T);
  };

  return (
    <div className={style.main} title={tip}>
      <span>{name}</span>
      <input type="text" value={value} onChange={onChange} />
    </div>
  );
}
