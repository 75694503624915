import { observer } from 'mobx-react';
import React from 'react';
import amp from '../../../services/amplitude/Amplitude';
import { AdController } from '../../helpers';
import { gameStore } from '../../stores/gameStore';
import { CauseDeath } from '../couseDeath/causeDeath';
import { AppButton } from '../index';
import { ReactComponent as ClapperboardGreen } from '../svg/clapperboardGreen.svg';
import { ReactComponent as ClapperboardYellow } from '../svg/clapperboardYellow.svg';
import { CountCoins } from './components/countCoins/countCoins';
import { NewRecord } from './components/newRecord/newRecord';

import style from './style.module.sass';

export const DeadMenu = observer(() => {
  const maxTerritories = gameStore.curMaxTerritories;
  const curTerritories = gameStore.territoriesCaptured;
  const isRecord = curTerritories > maxTerritories;

  const ad = new AdController();
  ad.load();

  const on2xReward = () => {
    ad.show(() => {
      amp.adsShowed('get2xReward');
      gameStore.room.send('2xReward');
      gameStore.onGoHome();
    });
  };

  return (
    <div className={style.deadMenu}>
      {isRecord && <NewRecord percent={curTerritories} />}
      <CauseDeath />
      <div className={style.buttons}>
        <AppButton
          text="Заново"
          color="green"
          onClick={gameStore.respawnCallback}
          disabled={!gameStore.adIsPreloaded}
          style={{ padding: '6px 12px' }}
          icon={<ClapperboardGreen />}
        />
        <AppButton disabled={!gameStore.adIsPreloaded} text="Награда 2x" color="yellow" onClick={on2xReward} icon={<ClapperboardYellow />} />
        <AppButton text="В гараж" color="yellow" onClick={gameStore.onGoHome} />
      </div>
      <CountCoins />
    </div>
  );
});
