import { makeAutoObservable, runInAction } from 'mobx';
import backend from '../../services/backend';

import { type RootStore } from '../RootStore';
// import { Timer, Countdown, convertCountdownToPartFormat } from '../../services/timer';
import { FactoryPlaceProps, MergingPlaceProps, ParkPlaceProps } from './types';

export class ParkingStore {
  public parkId: string | null = null;
  public park: ParkPlaceProps[] = [];
  public factory: FactoryPlaceProps[] = [];
  public merging: MergingPlaceProps[] = [];
  public isLoading = true;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  public async loadParking(userId: string) {
    const data = await backend.loadParkingData(userId);
    this.parkId = data.id;
    let isBuyPlacePark = 0;
    const tempPark = data.park.map((line, idxLine) => {
      line.places = line.places.map((place, idx) => ({
        ...place,
        idx,
        idxLine,
        type: 'park',
        isNoBuy: place.statusPlace === 'sale' ? !!isBuyPlacePark++ : false,
      }));

      line.fullingIdx = null;

      return { ...line };
    });

    let isBuyPlaceFactory = 0;
    const tempFactory: FactoryPlaceProps[] = data.factory.map((place, idx) => ({
      ...place,
      idx,
      type: 'factory',
      isNoBuy: place.statusPlace === 'sale' ? !!isBuyPlaceFactory++ : false,
    }));

    let isBuyPlaceMerging = 0;
    const tempMerging: MergingPlaceProps[] = data.merging.map((place, idx) => ({
      ...place,
      idx,
      type: 'merging',
      isNoBuy: place.statusPlace === 'sale' ? !!isBuyPlaceMerging++ : false,
    }));

    runInAction(() => {
      this.park = tempPark;
      this.factory = tempFactory.map((i) => {
        if (!i.timer) return i;
        const now = Date.now();
        const timeLeft = i.timer - (now - i.startTime);
        return { ...i, statusPlace: timeLeft > 0 ? 'creating' : 'created' };
      });
      this.merging = tempMerging;
      this.isLoading = false;
    });
  }

  public async buyPlace(userId: string, typePlace: 'factory' | 'merging' | 'park', position: number, line?: number) {
    const res = await backend.buyPlaceForUser(userId, this.parkId || '', typePlace, position, line);
    if (!res) return;
    this.loadParking(userId);
    this.rootStore.userStore.loadUserData();
  }

  public async buyGas(userId: string, line: number) {
    const res = await backend.buyGasForUser(userId, this.parkId || '', line);
    if (!res) return;
    this.loadParking(userId);
    this.rootStore.userStore.loadUserData();
  }

  public async createCar(userId: string, position: number) {
    const res = await backend.createCar(userId, this.parkId || '', position);
    if (!res) return;
    this.loadParking(userId);
    this.rootStore.carsStore.loadCarsByUser(userId);
    this.rootStore.userStore.loadUserData();
  }

  public async moveCar(userId: string, carId: string, typePlace: 'factory' | 'merging' | 'park', position: number, line?: number) {
    const res = await backend.moveCar(userId, this.parkId || '', carId, typePlace, position, line || 0);
    if (!res) return false;
    await this.loadParking(userId);
    if (typePlace !== 'merging') return null;
    await this.rootStore.carsStore.loadCarsByUser(this.rootStore.userStore.user.id);
    await this.loadParking(userId);
    await this.rootStore.userStore.loadUserData();
    return true;
  }

  public async takeRevenue(userId: string, position: number, line: number) {
    const result = await backend.takeRevenue(userId, this.parkId || '', position, line);
    if (!result) return;
    await this.loadParking(userId);
    await this.rootStore.userStore.loadUserData();
  }

  public setTimerGas(idLine: number, timer: number | null) {
    this.park[idLine].timer = timer;
  }

  public setPlaceMoney(index: number, indexLine: number, coins: number) {
    this.park[indexLine].places[index].money = coins;
    this.park = [...this.park];
  }

  public setPlaceTimer(index: number, indexLine: number, timer: number) {
    this.park[indexLine].places[index].timer = timer;
    this.park = [...this.park];
  }

  public getPlaceByIdCar(idCar: string) {
    if (this.park.find((line) => line.places.find((place) => place.carId === idCar))) {
      return 'park';
    }

    if (this.factory.find((place) => place.carId === idCar)) {
      return 'factory';
    }

    if (this.merging.find((place) => place.carId === idCar)) {
      return 'merging';
    }

    return undefined;
  }
}
