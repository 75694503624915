import { gameStore } from '../../stores/gameStore';
import { ILoadingScreen } from './ILoadingScreen';

export class CustomLoadingScreen implements ILoadingScreen {
  // optional, but needed due to interface definitions
  loadingUIBackgroundColor = 'yellow';
  loadingUIText = 'Загрузка...';

  displayLoadingUI() {
    gameStore.setIsLoading(true);
    // console.log('Отображение загрузочного интерфейса');
  }

  hideLoadingUI() {
    gameStore.setIsLoading(false);
    gameStore.setCanUseJoystick(true);
    // console.log('Скрытие загрузочного интерфейса');
  }
}
