import { Engine, Scene } from '@babylonjs/core';
import { Observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { gameStore } from '../../babylon/stores/gameStore';
import styles from './styles.module.sass';

export default function gameWindow({
  antialias,
  engineOptions,
  adaptToDeviceRatio,
  sceneOptions,
  onRender,
  onSceneReady,
  ...rest
}: any) {
  const reactCanvas = useRef(null);

  let renderInterval: NodeJS.Timeout;

  function createScene(canvas: HTMLCanvasElement) {
    const engine = new Engine(canvas, antialias, engineOptions, true);
    engine.setHardwareScalingLevel(0.5);
    const sceneGame = new Scene(engine, sceneOptions);
    gameStore.setScene(sceneGame);

    if (sceneGame.isReady()) {
      onSceneReady(sceneGame);
    } else {
      sceneGame.onReadyObservable.addOnce((scene) => onSceneReady(scene));
    }

    engine.runRenderLoop(() => {
      if (typeof onRender === 'function') onRender(sceneGame);
      sceneGame.render();
    });

    // const customFPS = 60;// 120 or 250
    // renderInterval = setInterval(() => sceneGame.render(), 1000 / customFPS);

    const resize = () => {
      sceneGame.getEngine().resize();
    };

    if (window) {
      window.addEventListener('resize', resize);
    }
    return { sceneGame, resize };
  }

  // set up basic engine and scene
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return undefined;
    const { sceneGame, resize } = createScene(canvas);

    return () => {
      sceneGame.getEngine().dispose();
      clearInterval(renderInterval);

      if (window) {
        window.removeEventListener('resize', resize);
      }
    };
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

  // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
  return (
    <Observer>
      {() => (
        <canvas
          className={styles.renderCanvas}
          ref={reactCanvas}
          style={{ display: gameStore.isLoading ? 'none' : 'block' }}
          {...rest}
        />
      )}
    </Observer>
  );
}
