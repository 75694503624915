import { Engine, MeshBuilder, Scene, ScenePerformancePriority, Vector2 } from '@babylonjs/core';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import { DevGUI } from '../../../gui';
import { keyPressListener } from '../../../helpers';
import { CrashSprite, createBaseElements, DisposeEffect } from '../../../meshs';
import { gameStore } from '../../../stores/gameStore';

export const DeadEffects = async (
  scene: Scene,
) => {
  if (Engine.audioEngine) {
    Engine.audioEngine.useCustomUnlockedButton = true;
  }

  gameStore.createGui();
  scene.performancePriority = ScenePerformancePriority.Intermediate;
  scene.autoClear = false;
  scene.autoClearDepthAndStencil = false;

  const allowedCar = ['banana', 'robot1'];

  await createBaseElements(scene, allowedCar, { attachControl: true });
  new DevGUI(scene);

  keyPressListener('space', () => {
    new CrashSprite('skull', 'skull.png', new Vector2(0, 0), scene);
  });

  keyPressListener('keyA', () => {
    console.log('cloud');
    new DisposeEffect('steam', 'steam.gif', new Vector2(0, 0), scene);
  });

  MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);
};
