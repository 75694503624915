import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import { Panel } from '../../../components/Panel';
import { StrokeText } from '../../../components/StrokeText';
import { EmptyPlace } from './EmptyPlace';
import { WaitingPlace } from './WaitingPlace';
import { MergePlace } from './MergePlace';
import { IssuePlace } from './IssuePlace';
import { BuyPlace } from './BuyPlace';

export const Merging = observer(() => {
  const { parkingStore } = useStore();
  const { merging } = parkingStore;

  const header = <StrokeText text="Тюнинг" />;

  const content = merging.map((place) => {
    if (place.statusPlace === 'waiting' && place.carIdOne) {
      return <MergePlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'waiting') {
      return <EmptyPlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'merging') {
      return <WaitingPlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'merged') {
      return <IssuePlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'sale') {
      return <BuyPlace key={place.idx} place={place} />;
    }
    return null;
  });

  return <Panel type="merging" header={header} content={content} />;
});
