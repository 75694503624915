import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import plusIcon from '../../../../assets/images/factory/icon-plus.png';
import { CoinsCount } from '../../../../components/CoinsCount';

import { Place } from '../../../../components/Place';
import { useStore } from '../../../../hooks/useStore';
import { MergingPlaceProps } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export interface BuyPlaceProps {
  place: MergingPlaceProps;
  topButtonIcon?: string | null;
}

export const BuyPlace = observer(({ place, topButtonIcon }: BuyPlaceProps) => {
  const { parkingStore, userStore } = useStore();
  const { user } = userStore;
  const navigate = useNavigate();
  const status = !place.isNoBuy && user.soft >= place.pricePlace ? 'active' : 'inactive';
  const size = place.statusPlace === 'waiting' ? 'small' : 'medium';

  const iconLockClass = classNames(style.icon, {
    [style.icon_noBuy]: status === 'inactive',
    [style.icon_lock]: place.isNoBuy,
    [style.icon_small]: place.statusPlace === 'sale' && !place.isNoBuy,
    [style.icon_small_noBuy]: !place.isNoBuy,
    [style.icon_big]: place.statusPlace !== 'sale' && !place.isNoBuy,
    [style.icon_big_noBuy]: place.statusPlace !== 'sale' && place.isNoBuy,
  });

  const onBuy = () => {
    if (user.soft - place.pricePlace < 0) {
      navigate('/shop');
      return;
    }

    parkingStore.buyPlace(userStore.user.id, place.type, place.idx);
  };

  return (
    <Place type="merging" status={status} size={size} topButtonIcon={topButtonIcon}>
      {place.statusPlace === 'waiting' ? (
        <CoinsCount count={place.pricePlace} fontSize="small" place="parking" />
      ) : (
        <>
          <span className={iconLockClass} />
          {!place.isNoBuy
          && (
          <>
            <CoinsCount count={place.pricePlace} place="parking" />
            <button type="button" className={style.plus} onClick={onBuy}>
              {place.pricePlace && <img src={plusIcon} alt="add place" />}
            </button>
          </>
          )}
        </>
      )}
    </Place>
  );
});
