import { Grid } from '../../grid';
import { Coordinate, Point } from '../../types';
import { PlayerController } from '../manager/playerController';
import { Player } from '../player';

export class Intersection {
  grid: Grid;
  controller: PlayerController;

  constructor(grid: Grid, controller: PlayerController) {
    this.grid = grid;
    this.controller = controller;
  }

  checkWayPoints(player: Player, waypoints: Coordinate[]) {
    for (const waypoint of waypoints) {
      const elems = this.grid.getElems(waypoint);
      for (const elem of elems) {
        this.checkTail(player, elem);
        this.checkZone(player, elem, waypoint);
      }
    }
  }

  checkTail(player: Player, elem: Point) {
    if (elem.category === 'tail') {
      player.death();
    }
  }

  checkZone(player: Player, elem: Point, coord: Coordinate) {
    const onExit = () => {
      player.exitCoord = coord;
    };

    const onReturn = () => {
      player.returnCoord = coord;

      player.extendZone();
      if (!player.extendedZone) {
        console.error('No extended zone');
        return;
      }

      player.sendNewRotation(player.car.mesh.rotation.y);
      this.controller.zonesWithinZone(player);
      this.controller.subtractZones(player, player.extendedZone);
      player.tail.delete();
    };

    if (elem.category === 'zone') {
      const isOwner = elem.ownerName === player.id;
      if (!isOwner) {
        player.crossedZones.add(elem.ownerName);
        return;
      }

      const oldValue = player.inZone;
      player.checkInZone();
      if (oldValue === player.inZone) return;
      if (player.inZone) onReturn();
      else onExit();
    }
  }
}
