import { action, computed, makeObservable, observable } from 'mobx';
import backend from '../../services/backend';
import { convertCountdownToFullFormat, Countdown, Timer } from '../../services/timer';
import { League } from './League';
import { LEAGUE_LEVELS, LeagueLevel } from './LeagueLevel';
import { LeagueType } from './LeagueType';

export class LeaguesStore {
  public leagues: Record<LeagueType, League | null> = {
    revenue: null,
    zone: null,
  };

  get currentLevel() {
    const current: Record<LeagueType, LeagueLevel> = {} as Record<LeagueType, LeagueLevel>;

    Object.values(this.leagues).forEach((league) => {
      if (!league) return;
      current[league.type] = league.level;
    });

    return current;
  }

  get previousLevels() {
    const previous: Record<LeagueType, LeagueLevel[]> = {} as Record<LeagueType, LeagueLevel[]>;

    Object.values(this.leagues).forEach((league) => {
      if (!league) return;
      const idx = LEAGUE_LEVELS.findIndex((level) => level === league.level);
      previous[league.type] = idx === -1 ? [] : LEAGUE_LEVELS.slice(0, idx);
    });

    return previous;
  }

  get nextLevels() {
    const next: Record<LeagueType, LeagueLevel[]> = {} as Record<LeagueType, LeagueLevel[]>;

    Object.values(this.leagues).forEach((league) => {
      if (!league) return;
      const idx = LEAGUE_LEVELS.findIndex((level) => level === league.level);
      next[league.type] = idx === -1 ? [] : LEAGUE_LEVELS.slice(idx + 1);
    });

    return next;
  }

  constructor() {
    makeObservable(this, {
      leagues: observable,
      currentLevel: computed,
      previousLevels: computed,
      nextLevels: computed,
      loadLeaguesSuccess: action,
      updateCountDown: action,
    });
  }

  public async loadLeagues(userId: string) {
    backend.loadLeagues(userId).then(this.loadLeaguesSuccess);
  }

  loadLeaguesSuccess = (data: Record<LeagueType, League>) => {
    this.leagues = { ...data };

    Object.values(this.leagues).forEach((league) => {
      if (!league) return;

      const timer = new Timer(1);
      timer.onUpdate = (countdown) => this.updateCountDown(league, countdown);
      league.timer = timer;
      timer.start(league.deadline);
    });
  };

  updateCountDown(league: League, countdown: Countdown) {
    league.countdown = convertCountdownToFullFormat(countdown);
  }
}
