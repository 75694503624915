import { Color3, StandardMaterial } from '@babylonjs/core';

export const createColorMaterial = (red: number, green: number, blue: number, name = '') => {
  const material = new StandardMaterial(name);
  material.diffuseColor = new Color3(red, green, blue);
  material.specularColor = new Color3(0, 0, 0);
  material.emissiveColor = new Color3(0, 0, 0);
  material.ambientColor = new Color3(0, 0, 0);
  return material;
};
