import { observer } from 'mobx-react';
import React from 'react';
import { ConnectDropTarget } from 'react-dnd';

import { TutorialElementType } from '../../stores/TutorialStore';

import './style.scss';

export type PlaceStatusType = 'active' | 'inactive' | 'selected' | 'active charging' | 'selected charging';

export interface PlaceProps {
  id?: string;
  type: 'factory' | 'merging' | 'parking';
  size?: 'medium' | 'small';
  status?: PlaceStatusType;
  children?: React.ReactNode;
  topButtonIcon?: string | null;
  onClick?: () => void;
  drop?: ConnectDropTarget;
  dataTutorial?: TutorialElementType;
}

export const Place = observer((props: PlaceProps) => {
  const { id, type, size = 'medium', status = 'active', children, topButtonIcon, onClick, drop, dataTutorial } = props;

  const statuses = status?.split(' ');
  const isActive = statuses?.includes('active');
  const isInactive = statuses?.includes('inactive');
  const isSelected = statuses?.includes('selected');
  const isCharging = statuses?.includes('charging');
  let statusClass: string;
  if (isInactive) statusClass = '-inactive';
  else if (isSelected) statusClass = '-selected';
  else statusClass = '';

  const typeClass = `place-${type}${statusClass}`;
  const sizeClass = !size ? 'place-medium' : `place-${size}`;
  const chargingClass = isCharging ? 'place-parking-charging' : '';
  const jumpFlickerClass = isSelected ? 'jumpFlicker' : '';
  const placeClasses = `${typeClass} ${sizeClass} ${chargingClass} ${jumpFlickerClass}`;

  const onPlaceClick = () => {
    if (!onClick || topButtonIcon || !isActive) return;
    onClick();
  };

  const onTopButtonClick = () => {
    if (!onClick || !isActive) return;
    onClick();
  };

  return (
    <div
      id={id}
      role="button"
      className={placeClasses}
      onClick={onPlaceClick}
      ref={drop}
      tabIndex={0}
      data-tutorial={dataTutorial}
      onKeyDown={() => {}}
    >
      {children}
      {topButtonIcon && (
        <button type="button" className="topButton" onClick={onTopButtonClick}>
          <img src={topButtonIcon} alt="top button" />
        </button>
      )}
    </div>
  );
});
