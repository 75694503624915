import { Engine, MeshBuilder, Scene, ScenePerformancePriority, Vector3 } from '@babylonjs/core';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import { stateAnim } from '../../../animations';
import { DevGUI } from '../../../gui';
import { createBaseElements } from '../../../meshs';
import { gameStore } from '../../../stores/gameStore';

export const testAnimState = async (
  scene: Scene,
) => {
  if (Engine.audioEngine) {
    Engine.audioEngine.useCustomUnlockedButton = true;
  }

  gameStore.createGui();
  scene.performancePriority = ScenePerformancePriority.Intermediate;
  scene.autoClear = false;
  scene.autoClearDepthAndStencil = false;

  const allowedCar = ['banana', 'robot1'];

  await createBaseElements(scene, allowedCar, { attachControl: true });
  new DevGUI(scene);

  const box = MeshBuilder.CreateBox('box');

  stateAnim(box, { targetPosition: new Vector3(5, 5, 5), targetRotation: 5 }, 10000, scene);

  MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);
};
