import { Category } from '../../grid/grid';

export class Point {
  category: Category;
  ownerName: string;

  constructor(category: Category, owner: string) {
    this.category = category;
    this.ownerName = owner;
  }

  getFullName() {
    return this.category + this.ownerName;
  }
}
