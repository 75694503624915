import { CreatePlane, Mesh, Texture, Vector3 } from '@babylonjs/core';
import { getSize } from '../../helpers';
import { createAlphaMaterial } from '../../materials';
import { AnimatedGifTexture } from '../../textures/animatedGifTexture';

export function createDisposeEffect(fileName: string) {
  const plane = CreatePlane(`disposeEffect${fileName}`, { width: 2, height: 2 });
  plane.position = Vector3.Up();
  plane.position.y = getSize(plane).y / 2;
  plane.rotation.y = Math.PI;
  const texture = new AnimatedGifTexture(`textures/deadSprite/${fileName}`, plane.getScene().getEngine());
  plane.material = createAlphaMaterial(`${fileName}Material`, texture);
  plane.isPickable = false;

  plane.billboardMode = Mesh.BILLBOARDMODE_ALL;
  plane.setEnabled(false);
  plane.alwaysSelectAsActiveMesh = true;
  return plane;
}

export function createCrushSprite(urlTexture: string) {
  const texture = new Texture(`textures/crashSprite/${urlTexture}`);
  const material = createAlphaMaterial('crashSpriteMaterial', texture);
  const plane = CreatePlane(`crushSprite${urlTexture}`, { width: 0.65, height: 0.65, sideOrientation: 1 });
  plane.isPickable = false;
  plane.material = material;
  plane.position = Vector3.Up();
  plane.rotation.x = Math.PI / 2;

  plane.setEnabled(false);
  return plane;
}
