//
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
//
// GENERATED USING @colyseus/schema 2.0.6
//

import { Schema, type } from '@colyseus/schema';
import { EntityManager } from './EntityManager';

export class BaseRoomState extends Schema {
  @type(EntityManager) public manager: EntityManager = new EntityManager();
  @type('string') public map!: string;
}
