import axios from 'axios';
import type { Car } from '../../stores/CarsStore';
import type { League, LeagueLevel, LeagueType } from '../../stores/LeaguesStore';
import { IParking } from '../../stores/ParkingStore/types';
import type { Reward } from '../../stores/RewardsStore';
import type { Pack } from '../../stores/ShopStore';
import type { User } from '../../stores/UserStore';

const $api = axios.create({
  baseURL: process.env.SERVER_URL,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function loadUserData(userId: string): Promise<User | null> {
  const { data } = await $api.get<User | null>(`/user/${userId}`);
  return data;
}

async function createUser(userBody: { vkId: string; firstname: string; lastname: string; nickname: string }): Promise<User | null> {
  const { data } = await $api.post<User | null>('/user', userBody);
  return data;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function loadCarsByUser(userId: string): Promise<Car[]> {
  const { data } = await $api.get(`/user/${userId}/cars`);
  return data;
}

// async function createCar(carLvl: number): Promise<Car> {
//   return {
//     id: `car#${Math.random()}`,
//     photo: carPhoto,
//     icon: carIcon,
//     lvl: carLvl,
//     energy: 4,
//     maxEnergy: 4,
//   };
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function loadParkingData(userId: string): Promise<IParking> {
  const { data } = await $api.get(`/parking/${userId}`);
  return data;
}

async function buyPlaceForUser(
  userId: string,
  parkId: string,
  typePlace: 'factory' | 'merging' | 'park',
  position: number,
  line?: number,
): Promise<IParking> {
  const { data } = await $api.put(`/parking/${userId}/${parkId}/buyPlace`, {
    typePlace,
    position,
    line,
  });
  return data;
}

async function buyGasForUser(userId: string, parkId: string, line: number) {
  const { data } = await $api.put(`/parking/${userId}/${parkId}/buyGas`, { line });
  return data;
}

async function scoreForRewarded(userId: string, parkId: string, typePlace: 'factory' | 'merging', position: number): Promise<boolean> {
  const { data } = await $api.put(`/parking/${userId}/${parkId}/skipTimeForAd`, {
    type: typePlace,
    position,
  });
  return data;
}

async function scoreForHard(userId: string, parkId: string, typePlace: 'factory' | 'merging', position: number): Promise<boolean> {
  const { data } = await $api.put(`/parking/${userId}/${parkId}/skipTimeForHard`, {
    type: typePlace,
    position,
  });
  return data;
}

async function createCar(userId: string, parkId: string, position: number): Promise<IParking> {
  const { data } = await $api.post(`/user/${userId}/car/create`, {
    parkId,
    position,
  });
  return data;
}

async function spendFuel(userId: string, carId: string): Promise<boolean> {
  const { data } = await $api.get(`/user/${userId}/car/${carId}/spendFuel`);
  return data;
}

async function moveCar(
  userId: string,
  parkId: string,
  carId: string,
  typePlace: 'factory' | 'merging' | 'park',
  position: number,
  line: number,
): Promise<boolean> {
  const { data } = await $api.put(`/parking/${userId}/${parkId}`, {
    carId,
    typePlace,
    position,
    line,
  });
  if (!data.status) {
    console.error(data.err);
  }
  return data.status;
}

async function takeRevenue(userId: string, parkId: string, position: number, line: number): Promise<boolean> {
  const { data } = await $api.put(`/user/${userId}/takeRevenue`, {
    parkId,
    position,
    line,
  });
  return data;
}

async function getMaxTerritory(userId: string): Promise<number> {
  const { data } = await $api.get(`/user/${userId}/getMaxTerritory`);
  return data;
}

async function nextTutor(userId: string): Promise<{ status: boolean }> {
  const { data } = await $api.get(`/user/${userId}/nextTutor`);
  console.log({ event: 'nextTutor backend', status: data.status });
  return data;
}

async function setTutor(userId: string, step: number): Promise<{ status: boolean }> {
  const { data } = await $api.post(`/user/${userId}/setTutorStep`, { step });
  console.log({ event: `setTutor backend - ${step}`, status: data.status });
  return data;
}

/// ///////////////////////////////////////////////////////////
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function loadLeagues(userId: string): Promise<Record<LeagueType, League>> {
  const revenueLeague: League = {
    id: '1',
    name: 'Серебряная',
    type: 'revenue',
    level: 'silver',
    winners: [],
    deadline: 12 * 24 * 60 * 60,
  };

  for (let i = 1; i < 31; i++) {
    revenueLeague.winners.push({
      id: String(i),
      userId: i === 5 ? '1' : String(i),
      firstName: 'Francis',
      lastName: 'Xavier',
      nickname: '',
      score: 1000,
      rating: i,
    });
  }

  const zoneLeague: League = { ...revenueLeague, name: 'Бронзовая', type: 'zone', level: 'bronze' };

  return { revenue: revenueLeague, zone: zoneLeague };
}

async function loadRewards(): Promise<Reward[]> {
  const rewards: Reward[] = [];

  const levels: LeagueLevel[] = ['bronze', 'silver', 'gold', 'ruby', 'brilliant'];
  const min = [1, 2, 4, 10, 21];
  const max = [1, 3, 9, 20, 30];
  const titles = ['1-e место', '2-3 место', '4-9 место', '10-20 место', '21-30 место'];

  for (let i = 0; i < levels.length; i++) {
    for (let j = 0; j < min.length; j++) {
      rewards.push({ id: `${i}${j}`, leagueLevel: levels[i], min: min[j], max: max[j], title: titles[j], hard: 9999, up: true, down: false });
    }
  }

  rewards[min.length - 1].down = false;
  rewards[min.length - 1].up = false;
  rewards[min.length - 2].down = false;
  rewards[min.length - 2].up = false;
  for (let i = 1; i < 5; i++) {
    rewards[3 + i * 5].up = false;
    rewards[4 + i * 5].up = false;
    rewards[4 + i * 5].down = true;
  }
  for (let i = 1; i < 5; i++) {
    rewards[rewards.length - i - 1].up = false;
  }

  return rewards;
}

async function loadShop(): Promise<Pack[]> {
  const { data } = await $api.get('pay/getShop');
  return Object.values(data);
}

async function getUsersPurchaseToday(userId: string) {
  const { data } = await $api.get(`pay/purchase/user/${userId}`);
  return data || [];
}

/// ///////////////////////////////////////////////////////////
async function deleteCar(userId: string, carId: string) {
  const { data } = await $api.delete('/user/car', { data: { userId, carId } });
  return data;
}

/// ///////////////////////////////////////////////////////////
async function getAdForUser(userId: string) {
  const { data } = await $api.get(`advertisements/${userId}`);
  return data || [];
}

async function createAdForUser(userId: string, adType: 'addFuel' | 'createSkip' | 'mergeSkip'): Promise<{ status: boolean, idAd: string }> {
  const { data } = await $api.post(`advertisements/${userId}/view`, { adType });
  return data;
}

async function checkAdForUser(userId: string, adId: string, options: any): Promise<boolean> {
  const { data } = await $api.put(`advertisements/${userId}/check/${adId}`, options);
  return data;
}

/// ///////////////////////////////////////////////////////////

const backend = {
  takeRevenue,
  loadUserData,
  loadCarsByUser,
  createUser,
  createCar,
  moveCar,
  loadParkingData,
  loadLeagues,
  loadRewards,
  loadShop,
  buyPlaceForUser,
  buyGasForUser,
  scoreForRewarded,
  scoreForHard,
  getMaxTerritory,
  getUsersPurchaseToday,
  nextTutor,
  spendFuel,
  deleteCar,
  getAdForUser,
  createAdForUser,
  checkAdForUser,
  setTutor,
};

export default backend;
