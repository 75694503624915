import { makeAutoObservable } from 'mobx';
import { BaseAgent } from '../../multiplayer/agent';

export interface IEntityTop3 {
  name: string;
  area: number;
  isYou: boolean;
  photo: string;
  color: { r: number; g: number; b: number };
}

class Top3Store {
  entity: IEntityTop3[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  calculateTop3(entityArray: BaseAgent[]) {
    this.entity = [];
    for (const entity of entityArray) {
      const transformedEntity = this.transformEntity(entity);
      if (transformedEntity.area < 0.1) {
        continue;
      } else if (transformedEntity.area < 1) {
        transformedEntity.area = 1;
      }
      transformedEntity.area = Math.floor(transformedEntity.area);

      this.entity.push(transformedEntity);
    }
  }

  transformEntity(entity: BaseAgent) {
    const { name, photo } = entity;
    const area = entity.zone.getPercentageArea();
    const isYou = name === 'Ты';
    const color = { r: entity.color.r, g: entity.color.g, b: entity.color.b };
    return { area, isYou, name, color, photo };
  }
}

export const top3Store = new Top3Store();
