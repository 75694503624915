import * as amplitude from '@amplitude/analytics-browser';
import { Identify } from '@amplitude/analytics-browser';
import rootStore from '../../stores/RootStore/RootStore';

enum TutorialStage {
  'Первая игра',
  'Создание машинки',
  'Обкатывание новой машинки после создания',
  'Первая машинка для мерджинга',
  'Вторая машинка для мерджинга',
  'Обкатывание новой машинки после мерджинга',
  'Тутор пройден',
}

class Amplitude {
  private isInit = false;

  get properties() {
    return new amplitude.Identify();
  }

  /**
   * Данные для установки в свойства юзера
   * @param properties
   */
  identify(properties: Identify) {
    if (!this.isInit) return;
    amplitude.identify(properties);
  }

  /**
   * Начальный инит
   */
  init() {
    if (this.isInit) return;

    const { id } = rootStore.userStore.user;
    amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY as string, id, { defaultTracking: true, userId: id });
    this.isInit = true;
  }

  /**
   * Запуск приложения
   */
  appStart() {
    if (!this.isInit) return;

    const { user } = rootStore.userStore;
    const data = {
      platform: user.platform,
      tutorStep: user.tutorStep,
    };

    if (user.registrationDate) {
      const played = Math.floor((new Date().getTime() - user.registrationDate) / 1000 / 60 / 60 / 24);
      this.identify(this.properties.set('p_playing_days', played));
    }

    this.identify(this.properties.set('currencies', { hard: user.hard, soft: user.soft }));

    amplitude.track('app_started', data);
  }

  /**
   * Запуск игры
   */
  gameStart() {
    if (!this.isInit) return;

    const { userStore, carsStore } = rootStore;
    const { user } = userStore;
    if (!user.selectedCarId) return;

    const car = carsStore.getCar(user.selectedCarId);
    if (!car) return;

    amplitude.track('start_game', { user, car: car.car.name, fuel: car.fuel });
  }

  /**
   * Запуск мерджа
   */
  gameMergeStart() {
    if (!this.isInit) return;

    const { user } = rootStore.userStore;
    amplitude.track('game_merge', { user });
  }

  /**
   * Запуск создания машинки
   */
  gameFactoryStart() {
    if (!this.isInit) return;

    const { user } = rootStore.userStore;
    amplitude.track('game_start_factory', { user });
  }

  /**
   * Забрал награду на парковке
   */
  gameReward(numMoney: number) {
    if (!this.isInit) return;
    if (numMoney <= 0) return;

    const { user } = rootStore.userStore;
    amplitude.track('game_claim_reward', { user, numMoney });
  }

  /**
   * Открытие репоста
   */
  openShare(car: string, background: string) {
    if (!this.isInit) return;

    amplitude.track('open_share', { car, background });
  }

  closeShare(car: string, background: string) {
    if (!this.isInit) return;

    amplitude.track('close_share', { car, background });
  }

  /**
   * Репост машинки
   */
  shareCar(car: string, background: string) {
    if (!this.isInit) return;

    amplitude.track('share_car', { car, background });
  }

  /**
   * Трата харды
   * @param {number} price - сколько потратил
   * @param {string} type - на что потратил
   */
  hardSpent(price: number, type: string) {
    if (!this.isInit) return;

    const data = {
      price: Number(price),
      type,
    };

    amplitude.track('hard_spent', data);
  }

  /**
   * Пользовательский клик
   * @param {string} place
   */
  trackClick(place: string) {
    if (!this.isInit) return;

    this.identify(this.properties.add(`clicks_${place}`, 1));

    const data = {
      type: place,
    };

    amplitude.track('user_click', data);
  }

  /**
   * Показана реклама
   * @param {string} place - на какой странице смотрел
   */
  adsShowed(place: 'revive' | 'get2xReward' | 'refuel' | 'mergeSkip' | 'createSkip') {
    if (!this.isInit) return;

    amplitude.track('ad', { place });
  }

  /**
   * Машинка удалена
   * @param {string} carName - название машинки
   * @param {number} carLvl - тип машинки
   * @param {string} place - на каком месте была машинка
   */
  carRemoved(carName: string, carLvl: number, place: string) {
    if (!this.isInit) return;

    const data = {
      carName,
      carLvl,
      place,
    };

    amplitude.track('car_removed', data);
  }

  /**
   * Шаги тутора
   * @param {string} step - номер шага
   */
  stepTutorial(step: number) {
    if (!this.isInit) return;

    const data = {
      stage: TutorialStage[step],
    };

    this.identify(this.properties.set('tutorStep', step + 1));
    amplitude.track('tutorial', data);
  }

  /**
   * Пропуск шага тутора
   * @param {string} step - номер шага
   */
  skipStepTutorial(step: number) {
    if (!this.isInit) return;

    const data = {
      stage: TutorialStage[step],
    };

    this.identify(this.properties.set('tutorStep', step + 1));

    amplitude.track('skip_tutorial', data);
  }

  /**
   * Окончание арены
   * @param {string} reason -
   * @param {number} coin -
   * @param {number} bucks -
   * @param {number} liveTime -
   * @param {number} kills -
   * @param {number} cars -
   * @param {number} territories -
   */
  arenaResult(reason: string, coin: number, bucks: number, liveTime: number, kills: number, cars: number, territories: number) {
    if (!this.isInit) return;

    const data = {
      reason,
      coin,
      bucks,
      liveTime,
      kills,
      cars,
      territories,
    };

    amplitude.track('arena', data);
  }

  /**
   * Открытие магазина
   */
  openedStore() {
    if (!this.isInit) return;

    const { user } = rootStore.userStore;
    amplitude.track('opened_store', { currency: { hard: user.hard, soft: user.soft } });
  }

  /**
   * Покупка в магазине
   */
  buyInStore(type: 'soft' | 'hard', num: number, price: number) {
    if (!this.isInit) return;

    amplitude.track('buy_in_store', { type, num, price });
  }

  /**
   * Неудачная попытка проиграть в арене
   */
  failedTryPlay() {
    if (!this.isInit) return;
    amplitude.track('failed_try_play');
  }
}

const amp = new Amplitude();

export default amp;
