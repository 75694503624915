import { advancedSplice } from './advancedSplice';
import { Real } from '../../types/points/real';

export function getPointsClockwise(start: number, end: number, points: Real[], insertionPoints: Real[]) {
  const pointsClockwise = [...points];
  const { length } = pointsClockwise;

  if (start > end) {
    advancedSplice(pointsClockwise, start + 1, length + end - start - 1, ...insertionPoints);
  } else if (start < end) {
    advancedSplice(pointsClockwise, start, end - start + 1, ...insertionPoints);
  } else if (start === end) {
    pointsClockwise.splice(start, 0, ...insertionPoints);
  }

  return pointsClockwise;
}
