import { observer } from 'mobx-react';

// import { FactoryPlaceProps } from '../FactoryPlaceProps';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Place, PlaceStatusType } from '../../../../components/Place';
import { Timer } from '../../../../components/Timer';
import { useStore } from '../../../../hooks/useStore';
import { FactoryPlaceProps } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export const WaitingPlace = observer(({ place }: { place: FactoryPlaceProps }) => {
  const { carsStore, userStore, parkingStore, tutorialStore } = useStore();
  const endT = useRef(true);
  const navigate = useNavigate();

  const onScore = () => {
    navigate('score', {
      state: {
        title: 'Завод',
        placeIdx: place.idx,
        zIndex: tutorialStore.currentStepIdx === 2 && 'unset',
      },
    });

    if (tutorialStore.currentStepIdx === 2) {
      tutorialStore.nextStep();
    }
  };

  const endTimer = () => {
    if (endT.current) {
      endT.current = false;
      // if (tutorialStore.currentStepIdx === 1 || tutorialStore.currentStepIdx === 2 || tutorialStore.currentStepIdx === 3) {
      //   setTimeout(() => {
      //     console.log({ event: 'setStep for tutorial', userStep: userStore.user.tutorStep });
      //     tutorialStore.setStep(userStore.user.tutorStep as number);
      //     tutorialStore.setStep(4);
      //     backend.setTutor(userStore.user.id, 4);
      //     if (place.carId) carsStore.setSelectedCar(place.carId);
      //   }, 200);
      // }
      carsStore.loadCarsByUser(userStore.user.id);
      parkingStore.loadParking(userStore.user.id);

      if (window.location.pathname.includes('score')) {
        navigate('/');
      }
    }
  };

  const status: PlaceStatusType = carsStore.selectedCar?.id === place.carId ? 'selected' : 'active';

  return (
    <Place type="factory" status={status} dataTutorial="factory-creating-place">
      <button type="button" className={style.btnScore} onClick={onScore}>
        <span className={style.btnScore_icon} />
      </button>
      <Timer timer={place.timer} startTime={place.startTime} endTimer={endTimer} />
    </Place>
  );
});
