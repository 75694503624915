import React, { CSSProperties, useEffect, useRef } from 'react';
import { ReactComponent as PointerSvg } from '../../svg/pointer.svg';
import { ReactComponent as PointerKingSvg } from '../../svg/pointerKing.svg';

import styles from './styles.module.sass';

interface IPointerProps extends Omit<React.ScriptHTMLAttributes<SVGElement>, 'color'> {
  angle: number;
  color: { r: number; g: number; b: number };
  opacity: number;
  isKing: boolean;
}

function Pointer(props: IPointerProps): JSX.Element {
  const { angle, color, opacity, isKing, ...rest } = props;
  const svgRef = useRef<SVGSVGElement>(null); // Создаем ссылку на SVG элемент

  useEffect(() => {
    if (svgRef.current) {
      // Находим внутренний элемент по классу или id
      const colorElem = svgRef.current.querySelector('#color');

      if (colorElem) {
        // Меняем стили или атрибуты внутреннего элемента
        colorElem.setAttribute('fill', `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})`);
      }

      if (isKing) {
        const crownElem = svgRef.current.querySelector('#crown');
        if (crownElem){
          const minusAngle = angle * (180 / Math.PI) - 90;
          crownElem.setAttribute('transform', `rotate(${-minusAngle} 25.1 25)`);
        }
      }
    }
  }, [color]); // Зависимость от color, чтобы обновлять при изменении цвета

  const indent = window.innerWidth > 600 ? 150 : 100;
  const startY = window.innerWidth > 600 ? 0 : -10;
  const x = indent * Math.sin(angle); // расчет координаты X
  const y = startY + -indent * Math.cos(angle); // расчет координаты Y

  const style: CSSProperties = {
    transform: `rotate(${angle}rad)`,
    top: `calc(${y}px)`,
    left: `calc(${x}px)`,
    opacity: isKing ? 1 : opacity,
  };

  if (isKing) {
    return <PointerKingSvg ref={svgRef} className={styles.arrow} style={style} {...rest} />;
  }

  return <PointerSvg ref={svgRef} className={styles.arrow} style={style} {...rest} />;
}

export default Pointer;
