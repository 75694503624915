import { DynamicTexture, MeshBuilder, StandardMaterial } from '@babylonjs/core';
import { gameStore } from '../../stores/gameStore';

function createMaterial() {
  const { scene } = gameStore;
  const gradientTexture = new DynamicTexture('gradient', { width: 2, height: 4 }, scene, true);
  const context = gradientTexture.getContext();
  const gradient = context.createLinearGradient(0, 0, 0, gradientTexture.getSize().height);
  gradient.addColorStop(0, 'blue');
  gradient.addColorStop(1, 'red');
  context.fillStyle = gradient;
  context.fillRect(0, 0, gradientTexture.getSize().width, gradientTexture.getSize().height);
  gradientTexture.update();
  gradientTexture.vScale = 2; // Увеличиваем вертикальное масштабирование текстуры, чтобы она заполнила mesh

  // Создание материала с текстурой
  const gradientMaterial = new StandardMaterial('gradientMaterial', scene);
  gradientMaterial.diffuseTexture = gradientTexture;

  return gradientMaterial;
}

function createModel() {
  const capsule = MeshBuilder.CreateCapsule('superMagnetBase', { height: 0.4, radius: 0.1 });
  capsule.material = createMaterial();

  return capsule;
}
export async function createSuperMagnet() {
  const mesh = createModel();
  mesh.setEnabled(false);
  // mesh.scaling = (new Vector3().setAll(0.4));

  return mesh;
}
