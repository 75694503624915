import * as BABYLON from '@babylonjs/core';
import gameSettings from '../../../settings/gameSettings';
import { Real } from '../../types';
import { Convertors } from '../converters';
import { getArea } from './getAreaPolygon';

const totalAreaPoint = BABYLON.Polygon.Circle(gameSettings.game.GROUND_RADIUS - gameSettings.game.MARGIN_FROM_THE_EDGE_OF_THE_MAP, 0, 0, 28);
const totalArea = getArea(Convertors.vectors.toReal(totalAreaPoint));

export function getPercentageFromTotalArea(points: Real[]) {
  const zoneArea = getArea(points);
  return (zoneArea / totalArea) * 100;
}
