import { Scene } from '@babylonjs/core';
import { Button, Control, TextBlock } from '@babylonjs/gui/2D';
import { gameStore } from '../stores/gameStore';

interface IDevGUIOptions {
  createPingCounter: boolean;
}

export class DevGUI {
  private readonly scene: Scene;
  private buttons: Button[] = [];
  private leftMenuContainer: Control[] = [];

  constructor(
    scene: Scene,
    options?: IDevGUIOptions,
  ) {
    const { createPingCounter = false } = options ?? {};

    this.scene = scene;
    this.leftMenu(scene);
    this.createFPSCounter();
    if (createPingCounter) {
      for (const control of this.leftMenuContainer) {
        control.topInPixels += 12 + 5 + 5; // 12 - size ping counter, 5 - top margin, 5 - margin,
      }
      this.createPingCounter();
    }
  }

  private leftMenu(scene: Scene) {
    this.createButton('showDebugLayer', async () => {
      if (this.scene.debugLayer.isVisible()) {
        await this.scene.debugLayer.hide();
      } else {
        await scene.debugLayer.show({
          overlay: true,
        });
      }
    });
  }

  public createButton(text: string, action: () => void) {
    const button = Button.CreateSimpleButton(`${text}Button`, text);
    button.width = `${text.length * 10 + 8}px`;
    button.height = '30px';
    button.cornerRadius = 20;
    button.thickness = 4;
    button.children[0].color = '#DFF9FB';
    button.children[0].fontSize = 12;
    button.color = '#ed0707';
    button.background = '#991313';
    button.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    button.zIndex = 10;

    const padding = (button.heightInPixels + 5) * this.buttons.length + 28;
    button.top = `${padding}px`;
    button.leftInPixels += 2;

    button.onPointerClickObservable.add(action);
    gameStore.gui.addControl(button);
    this.buttons.push(button);
    this.leftMenuContainer.push(button);
  }

  public createFPSCounter() {
    const fpsLabel = new TextBlock('fpsLabel');

    fpsLabel.text = 'FPS: ';
    fpsLabel.color = 'white';
    fpsLabel.fontSize = 24;
    fpsLabel.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    fpsLabel.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    fpsLabel.leftInPixels += 2;

    const engine = this.scene.getEngine();

    this.scene.onBeforeRenderObservable.add(() => {
      if (this.scene.isDisposed) return;
      fpsLabel.text = `FPS: ${Math.round(engine.getFps())}`;
    });

    gameStore.gui.addControl(fpsLabel);
    this.leftMenuContainer.push(fpsLabel);
  }

  private createPingCounter() {
    const pingLabel = new TextBlock('pingLabel');
    pingLabel.text = 'FPS: ';
    pingLabel.color = 'white';
    pingLabel.fontSize = 12;
    pingLabel.topInPixels += 5;
    pingLabel.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    pingLabel.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    pingLabel.leftInPixels += 2;

    this.scene.onBeforeRenderObservable.add(() => {
      pingLabel.text = `Ping: ${gameStore.ping}ms`;
    });

    gameStore.gui.addControl(pingLabel);
  }
}
