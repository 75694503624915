// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas';
import { useState } from 'react';
import managerApi from '../../api/managerApi';
import amp from '../../services/amplitude/Amplitude';
import { Car } from '../../stores/CarsStore';
import { StrokeText } from '../StrokeText';

import style from './style.module.scss';
import './style.css';

export type ShareButtonType = 'wall' | 'story' | 'message';

export interface ShareButtonProps {
  type: ShareButtonType;
  car?: Car;
}

const stBg = ['/assets/stories-1.png', '/assets/stories-2.png', '/assets/stories-3.png', '/assets/stories-4.png'];

export function ShareButton({ type, car }: ShareButtonProps) {
  const [isDisable, setDisable] = useState(false);
  const background = stBg[Math.floor(Math.random() * 4)];

  const shareToStories = async () => {
    const div = document.getElementById('stories-gen');

    if (car) {
      amp.openShare(car.car.name, background);
    }

    if (div) {
      html2canvas(div, {
        windowWidth: div.scrollWidth,
        windowHeight: div.scrollHeight,
      }).then((canvas: HTMLCanvasElement) => {
        const myImage = canvas.toDataURL('image/png');
        managerApi.showStory(myImage).then((data) => {
          if (data.result) {
            setDisable(true);
            if (!car) return;
            amp.shareCar(car.car.name, background);
          }
        })
          .catch((error) => {
            console.log(error);
            if (!car) return;
            amp.closeShare(car.car.name, background);
          });
      });
    }
  };

  return (
    <>
      {type === 'wall' && (
        <button type="button" className={style.button}>
          <StrokeText style={style.button__label} text="На стену" />
          <span className={style.button__icon_wall} />
        </button>
      )}
      {type === 'story' && (
        <button disabled={isDisable} type="button" onClick={shareToStories} className={style.button}>
          <StrokeText style={style.button__label} text="В историю" />
          <span className={style.button__icon_story} />
        </button>
      )}
      {type === 'message' && (
        <button type="button" className={style.button}>
          <StrokeText style={style.button__label} text="В сообщение" />
          <span className={style.button__icon_message} />
        </button>
      )}
      <div id="stories-gen" className="stories">
        <img className="img" src={background} alt="stories" />
        <img className="car" src={`/models/cars/${car?.car.model}.png`} alt="stories-car" />
        <div className="text">
          Игра “Цветной обгон” не перестает удивлять -
          <br />
          взгляните на мой только что полученный автомобиль
          <br />
          {`${car?.car.lvl} уровня !`}
        </div>
      </div>
    </>
  );
}
