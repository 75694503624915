import type { Reward } from './Reward';
import type { LeagueRewards } from './LeagueRewards';
import { LeagueLevel, LEAGUE_LEVELS, LEAGUE_LEVELS_NAMES } from '../LeaguesStore';
import backend from '../../services/backend';

export class RewardsStore {
  public rewards: Reward[] = [];
  public leagues: LeagueRewards[] = [];

  public loadRewards() {
    backend.loadRewards().then((rewards) => {
      this.rewards = rewards.map((reward) => {
        if (reward.up) {
          reward.nextLevel = this.getNextLeague(reward.leagueLevel);
        } else if (reward.down) {
          reward.nextLevel = this.getPreviousLeague(reward.leagueLevel);
        } else {
          reward.nextLevel = 'Текущая лига';
        }
        return reward;
      });
      this.createLeagues(this.rewards);
    });
  }

  private createLeagues(rewards: Reward[]) {
    const leagues: LeagueRewards[] = [];
    LEAGUE_LEVELS.forEach((level) => {
      const leagueRewards = rewards.filter((reward) => reward.leagueLevel === level);
      if (leagueRewards.length === 0) return;

      leagues.push({
        name: this.getCurrentLeague(leagueRewards[0].leagueLevel),
        level: leagueRewards[0].leagueLevel,
        rewards: leagueRewards,
      });
    });

    this.leagues = leagues;
  }

  private getCurrentLeague(type: LeagueLevel) {
    const idx = LEAGUE_LEVELS.findIndex((item) => item === type);
    if (idx === -1) return '';

    return LEAGUE_LEVELS_NAMES[idx];
  }

  private getPreviousLeague(type: LeagueLevel) {
    const idx = LEAGUE_LEVELS.findIndex((item) => item === type);
    if (idx < 1) return '';

    return LEAGUE_LEVELS_NAMES[idx - 1];
  }

  private getNextLeague(type: LeagueLevel) {
    const idx = LEAGUE_LEVELS.findIndex((item) => item === type);
    if (idx === -1 || idx + 1 === LEAGUE_LEVELS.length) return '';

    return LEAGUE_LEVELS_NAMES[idx + 1];
  }
}
