// import { ShareButton } from '../../components/ShareButton';
import { motion, useMotionValue } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import managerApi from '../../api/managerApi';
import noFuelAd from '../../assets/images/garage/icon-noFuelAd.png';
import noFuel from '../../assets/images/garage/noFuel.png';
import { CloseButton } from '../../components/CloseButton/CloseButton';

import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import { useStore } from '../../hooks/useStore';
import amp from '../../services/amplitude/Amplitude';
import style from './style.module.scss';

export function NoFuel() {
  const [open, setOpen] = useState(false);
  const { userStore, carsStore, parkingStore } = useStore();
  const [buttonAd, setButtonAd] = useState(false);
  const navigate = useNavigate();
  const opacity = useMotionValue(0);
  opacity.on('animationComplete', () => {
    setOpen(true);
  });

  const onClickRewarded = async () => {
    const idAd = await userStore.createViewAd('addFuel');
    if (idAd) {
      managerApi.showAd().then(async (data) => {
        if (data.result && carsStore.selectedCar) {
          const res = await userStore.checkAd(idAd, {
            carId: carsStore.selectedCar.id,
          });
          if (res) {
            userStore.loadViewAd();
            carsStore.loadCarsByUser(userStore.user.id).then(() => {
              parkingStore.loadParking(userStore.user.id);
            });

            amp.adsShowed('refuel');
            navigate('/');
          }
        } else {
          console.log('Ошибка при показе');
        }
      })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    managerApi.prepareAd()
      .then((data) => {
        if (data.result) {
          setButtonAd(true);
        } else {
          console.log('Рекламные материалы не найдены.');
          setButtonAd(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setButtonAd(false);
      });
  }, []);

  return (
    <ModalWindow>
      <div className={style.container_top}>
        <motion.div
          className={style.container}
          style={{ opacity }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
        >
          {open && (
            <>
              <div className={style.close_container}>
                <CloseButton color="purple" path="/" />
              </div>
              <header className={style.header}>
                <StrokeText style={style.header__title} text="Недостаточно топлива!" />
                <StrokeText style={style.header__title} text="Дождитесь окончания" />
                <StrokeText style={style.header__title} text="заправки." />
              </header>
              <div className="fuel">
                <img src={noFuel} alt="noFuel" />
              </div>
              {userStore.viewAd.addFuel > 0 && (
                <div className={style.ad_container}>
                  <span>Или пополните топливо за рекламу</span>
                  <button disabled={!buttonAd} onClick={onClickRewarded} type="button" className={style.delete}>
                    Пополнить
                    <img src={noFuelAd} alt="noFuelAd" />
                  </button>
                </div>
              )}
            </>
          )}
        </motion.div>
      </div>
    </ModalWindow>
  );
}
