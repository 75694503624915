import { gameStore } from '../../stores/gameStore';
import { AppButton } from '../button';
import { ReactComponent as Finger } from '../svg/finger.svg';
import { ReactComponent as Stick } from '../svg/stick.svg';
import style from './style.module.sass';

export function Tutorial() {
  return (
    <div className={style.container}>
      <span className={style.text}>Зажми и двигай</span>
      <Finger className={style.imgFinger} />
      <Stick className={style.imgStick} />
      <AppButton text="ОК" color="green" onClick={() => { gameStore.setShowTutorial(false); }} />
    </div>
  );
}
