interface Direction {
  rotation: number;
  options?: { startPoint?: { x: number; y: number }; distance?: number };
}

export function getDirection({ rotation, options }: Direction) {
  const { startPoint = { x: 0, y: 0 }, distance = 0 } = options ?? {};
  const { x } = startPoint;
  const { y } = startPoint;
  const angle = rotation;
  const forward = { x: 0, y: 0 };
  forward.x = x - distance * Math.sin(angle);
  forward.y = y - distance * Math.cos(angle);
  return forward;
}
