import { Color3, Observable, Scene } from '@babylonjs/core';
import { ConvertorVector } from '../../helpers';
import { CrashSprite, DisposeEffect } from '../../meshs';
import { gameStore } from '../../stores/gameStore';
import { Real } from '../../types';
import { Car } from './car';
import { Tail } from './tail';
import { Zone } from './zone';

export interface IBaseAgent {
  name: string;
  photo: string;
  id: string;
  model: string;
  startPos: Real;
  color: Color3;
  zonePoints: Real[];
  tailPoints?: Real[];
}

export interface SpawnParams {
  startPos: Real;
  zonePoints: Real[];
  tailPoints?: Real[];
}

export class BaseAgent {
  id: string;
  name: string;
  photo: string;
  isMove = false;
  isAlive = true;
  inZone = true;

  color: Color3;
  car: Car;
  zone: Zone;
  tail: Tail;

  onDeathObservable = new Observable<BaseAgent>();
  onSpawnObservable = new Observable<BaseAgent>();

  isWriteTail = true;
  scene: Scene;

  constructor(param: IBaseAgent) {
    const {
      name,
      id,
      model,
      startPos,
      color,
      zonePoints,
      tailPoints,
      photo,
    } = param;

    this.id = id;
    this.name = name;
    this.color = color;
    this.photo = photo;

    this.scene = gameStore.scene;
    this.car = new Car({ ownerName: `${this.name}`, modelName: model });
    this.zone = new Zone({ name: `${this.name}Zone`, color, lift: 0.001 });
    this.tail = new Tail({ name: `${this.name}Tail`, color, lift: 0.002 });
    this.spawn({ startPos, tailPoints, zonePoints });
  }

  death() {
    const pos = this.getPos().toV2();
    new CrashSprite(`${this.name}CrashSprite`, 'skull.png', pos, this.getScene());
    new DisposeEffect(`${this.name}DisposeEffect`, 'steam.gif', pos, this.getScene());

    this.isAlive = false;
    this.isMove = false;
    this.isWriteTail = false;
    this.car.delete();
    this.zone.delete();
    this.tail.delete();
    this.onDeathObservable.notifyObservers(this);
  }

  spawn(params: SpawnParams) {
    this.isAlive = true;
    this.isMove = false;
    this.car.createCar(params.startPos);
    this.zone.createOrUpdate(params.zonePoints);
    this.tail.addPoints(params.tailPoints ?? []);
    this.onSpawnObservable.notifyObservers(this);
    this.isWriteTail = true;
  }

  getPos() {
    return ConvertorVector.toReal(this.car.mesh.position);
  }

  getScene() {
    return this.scene;
  }

  setPosition(position: Real) {
    const { y } = this.car.mesh.position;
    this.car.mesh.position = position.toV3(y);
  }

  createAdditionalTailPoints() {
    if (this.tail.additionalPoints.length) return;
    if (!this.isWriteTail) return;
    if (!this.tail.points.length) return;
    if (!this.zone.points.length) return;
    this.tail.additionalPoints = [this.zone.getNearPosition(this.tail.points[0])];
  }
}
