import { TutorialElementType } from '../../stores/TutorialStore';
import { Point } from './Point';
import { Arrow } from './Arrow';
import { Marker } from './Marker';

const DEFAULT_POINT: Point = {
  x: 0,
  y: 0,
};

const DEFAULT_ARROW: Arrow = {
  from: { ...DEFAULT_POINT },
  to: { ...DEFAULT_POINT },
  curve: 'right',
};

const DEFAULT_MARKER: Marker = {
  center: { ...DEFAULT_POINT },
  r: 0,
};

const MIN_SPACE_PX = 20;

/**
 * source: a point in the middle of a border
 * target: a point on the left of a border (a viewpoint inside the rect)
 */
type PositionType = 'top' | 'bottom' | 'left' | 'right';
type ConnectionType = 'source' | 'target';

export function getDOMRect(element: string) {
  const el = document.querySelector(`[data-tutorial="${element}"]`);

  if (!el) return null;

  return el;
  // return el.getBoundingClientRect();
}

// export function getDOMPoint(svg: SVGSVGElement, x: number, y: number) {
//   const pt = svg.createSVGPoint();
//   pt.x = x;
//   pt.y = y;

//   const domMatrix = svg.getScreenCTM();
//   if (!domMatrix) return null;

//   return pt.matrixTransform(domMatrix.inverse());
// }

// export function getPositionForSource(source: DOMRect, target: DOMRect): PositionType {
//   if (target.top - source.bottom > MIN_SPACE_PX) return 'bottom';
//   if (source.top - target.bottom > MIN_SPACE_PX) return 'top';

//   return target.left - source.right > MIN_SPACE_PX ? 'right' : 'left';
// }

// export function getPositionForTarget(source: DOMRect, target: DOMRect): PositionType {
//   if (target.top - source.bottom > MIN_SPACE_PX) return 'top';
//   if (source.top - target.bottom > MIN_SPACE_PX) return 'left';

//   return target.left - source.right > MIN_SPACE_PX ? 'left' : 'right';
// }

// export function getConnectionPoint(type: ConnectionType, position: PositionType, domRect: DOMRect): Point {
//   if (position === 'top') {
//     return {
//       x: domRect.left + (type === 'target' ? 0 : domRect.width / 2),
//       y: domRect.top,
//     };
//   }
//   if (position === 'bottom') {
//     return {
//       x: domRect.right - (type === 'target' ? 0 : domRect.width / 2),
//       y: domRect.bottom,
//     };
//   }
//   if (position === 'left') {
//     return {
//       x: domRect.left,
//       y: domRect.bottom - (type === 'target' ? 0 : domRect.height / 2),
//     };
//   }
//   if (position === 'right') {
//     return {
//       x: domRect.left,
//       y: domRect.top + (type === 'target' ? 0 : domRect.height / 2),
//     };
//   }

//   return { ...DEFAULT_POINT };
// }

// export function getDOMPositionForMarker(svg: SVGSVGElement, element: TutorialElementType): Marker {
//   const domRect = getDOMRect(element);
//   if (!domRect) return { ...DEFAULT_MARKER };

//   const { top, left, width, height } = domRect;

//   const centerDomPoint = getDOMPoint(svg, left + width / 2, top + height / 2);
//   const leftDomPoint = getDOMPoint(svg, left - 0.15 * width, top + height / 2);
//   if (!centerDomPoint || !leftDomPoint) return { ...DEFAULT_MARKER };

//   return { center: { x: centerDomPoint.x, y: centerDomPoint.y }, r: centerDomPoint.x - leftDomPoint.x };
// }

// export function getDOMPositionForArrow(svg: SVGSVGElement, source: TutorialElementType, target: TutorialElementType): Arrow {
//   const sourceDomRect = getDOMRect(source);
//   const targetDomRect = getDOMRect(target);
//   // console.log('sourceDomRect', sourceDomRect);
//   // console.log('targetDomRect', targetDomRect);
//   if (!sourceDomRect || !targetDomRect) return { ...DEFAULT_ARROW };

//   const sourcePosition = getPositionForSource(sourceDomRect, targetDomRect);
//   const targetPosition = getPositionForTarget(sourceDomRect, targetDomRect);

//   const sourcePoint = getConnectionPoint('source', sourcePosition, sourceDomRect);
//   const targetPoint = getConnectionPoint('target', targetPosition, targetDomRect);
//   // console.log('sourcePoint.x', sourcePoint.x);
//   // console.log('sourcePoint.y', sourcePoint.y);
//   // console.log('targetPoint.x', targetPoint.x);
//   // console.log('targetPoint.y', targetPoint.y);

//   const sourceDOMPoint = getDOMPoint(svg, sourcePoint.x, sourcePoint.y);
//   const targetDOMPoint = getDOMPoint(svg, targetPoint.x, targetPoint.y);
//   if (!sourceDOMPoint || !targetDOMPoint) return { ...DEFAULT_ARROW };

//   return { from: sourceDOMPoint, to: targetDOMPoint, curve: 'right' };
// }
