import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, ArcRotateCamera, Observable, Vector2, Vector3 } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import gameSettings from '../../../settings/gameSettings';
import { decay,
  getArea,
  getDirection,
  getDistance,
  getExtendedZone,
  getPercentageFromTotalArea,
  getPointsClockwise,
  getPointsCounterClockwise,
  getSize,
  isOutsideMap,
  isPointInPolygon,
  keyPressListener } from '../../helpers';
import { gameStore } from '../../stores/gameStore';
import { Coordinate, Real } from '../../types';
import { BaseAgent, IBaseAgent, SpawnParams } from '../agent';

export type IPlayerMP = Omit<IBaseAgent, 'name'>;
const deltaSpeed = +((gameSettings.entity.MOVE_SPEED * (1000 / gameSettings.entity.MOVEMENT_FREQUENCY)) / 60).toFixed(3);

export class Player extends BaseAgent {
  oldPosition?: Real;
  distanceTraveled = 0;
  angleRotation = 0;
  returnCoord?: Coordinate;
  exitCoord?: Coordinate;

  extendedZone?: Real[];
  crossedZones = new Set<string>();
  needUpdateZone = false;
  numberCommand = 0;
  inputQueue: { command: string; number: number; value: number; result: { x: number; y: number } }[] = [];
  offset = {
    dx: 0,
    dy: 0,
  };

  onKillObserver = new Observable<Player>();

  constructor(params: IPlayerMP) {
    const name = 'Ты';
    super({
      ...params,
      name,
    });

    this.tail.setLift(0.002);
    this.zone.setLift(0.003);

    this.attachCamera(this.car.mesh);

    this.zone.onChangeObserver.add(() => {
      this.cameraZoom();
    });

    this.zone.onExtendActionObserver.add((percentage) => {
      if (percentage < 10) {
        gameStore.setTextPraise('Супер!');
      } else if (percentage < 20) {
        gameStore.setTextPraise('Отлично!');
      } else if (percentage < 50) {
        gameStore.setTextPraise('Безупречно!');
      } else {
        gameStore.setTextPraise('Продолжай');
      }
      gameStore.setShowPraise(true);
    });

    const removeListenerSpace = keyPressListener('Space', () => {
      this.isMove = !this.isMove;
      if (!gameStore.room) return;
      gameStore.room.send('stopPlayer', { isMove: this.isMove });
    });

    this.getScene().onDisposeObservable.addOnce(() => {
      removeListenerSpace();
    });
  }

  moveWithOffset() {
    const scene = this.getScene();
    const distance = deltaSpeed * scene.getAnimationRatio();
    const modifierX = decay(Math.abs(this.offset.dx));
    const modifierY = decay(Math.abs(this.offset.dy));
    const offsetX = Math.min(distance * modifierX, Math.abs(this.offset.dx)) * Math.sign(this.offset.dx);
    const offsetY = Math.min(distance * modifierY, Math.abs(this.offset.dy)) * Math.sign(this.offset.dy);

    const angle = this.car.mesh.rotation.y;
    const { x, y } = this.getPos();
    const newX = x + distance * Math.cos(angle);
    const newY = y + distance * Math.sin(angle) * -1;
    const distanceFromCenter = getDistance({ x: newX, y: newY }, { x: 0, y: 0 });
    if (distanceFromCenter > gameSettings.game.GROUND_RADIUS) return;

    for (const item of this.inputQueue) {
      item.result.x += offsetX;
      item.result.y += offsetY;
    }

    this.car.mesh.position.x += offsetX;
    this.car.mesh.position.z += offsetY;
    this.car.mesh.movePOV(0, 0, distance);
    this.offset.dx = Math.max(Math.abs(this.offset.dx) - Math.abs(offsetX), 0) * Math.sign(this.offset.dx);
    this.offset.dy = Math.max(Math.abs(this.offset.dy) - Math.abs(offsetY), 0) * Math.sign(this.offset.dy);
  }

  calculateDistance() {
    if (!this.oldPosition) return;
    const { x, y } = this.getPos();
    const { x: oldX, y: oldY } = this.oldPosition;
    this.distanceTraveled += Math.sqrt((oldX - x) ** 2 + (oldY - y) ** 2);
  }

  applyRotation() {
    if (!this.isAlive) return;
    if (this.distanceTraveled >= gameSettings.entity.MOVE_SPEED / 4) {
      this.car.mesh.rotation.y = this.angleRotation;
      this.sendNewRotation(this.angleRotation);
      this.distanceTraveled = 0;
    }
  }

  checkDirection() {
    if (!this.isAlive) return;
    if (gameStore.joystickIsPressed) {
      const { x, y } = gameStore.joystickDirectional ?? { x: undefined, y: undefined };
      if (x && y) {
        this.isMove = true;
        const angle = Math.atan2(x, -y);
        if (angle === this.car.mesh.rotation.y) return;
        if (Math.abs(angle - this.car.mesh.rotation.y) < (Math.PI * 2) / 60) return;

        const scale = getSize(this.car.mesh);
        const forward = new Vector2(Math.sin(angle), Math.cos(angle)).scale(scale.x);
        const nextHoodNose = this.getPos().toV2().subtract(forward.clone());
        if (isOutsideMap(nextHoodNose)) return;

        this.angleRotation = angle;
      }
    }
  }

  sendNewRotation(angle: number) {
    const result = {
      x: this.car.mesh.position.x,
      y: this.car.mesh.position.z,
    };
    this.inputQueue.push({
      command: 'rotation',
      result,
      number: this.numberCommand,
      value: angle,
    });
    const message = {
      number: this.numberCommand,
      value: angle,
      id: this.id,
    };
    this.numberCommand += 1;

    if (!this.getScene()) return undefined;
    if (this.getScene().isDisposed) return undefined;
    if (!gameStore.room) return undefined;
    gameStore.room.send('updatePlayerRotation', message);
    return this.numberCommand - 1;
  }

  turnAway() {
    const sphereCenter = BABYLON.Vector3.Zero();
    const directionBox = this.car.mesh.forward.clone();
    const touchPoint = sphereCenter.add(this.car.mesh.position.subtract(sphereCenter).normalize().scale(gameSettings.game.GROUND_RADIUS));
    const normal = touchPoint.subtract(sphereCenter).normalize();
    let angle = Math.acos(BABYLON.Vector3.Dot(normal.scale(0.5), directionBox));

    const cross = BABYLON.Vector3.Cross(directionBox, normal);
    if (cross.y < 0) {
      angle *= -1;
    }

    this.angleRotation += angle / 50;
    this.car.mesh.rotation.y += angle / 50;
    this.sendNewRotation(this.car.mesh.rotation.y);
  }

  attachCamera(target: Vector3 | AbstractMesh, options?: { camera?: ArcRotateCamera }) {
    const { camera } = options ?? {};
    const cameraCurrent = camera ?? (this.getScene().getCameraByName('camera') as Nullable<ArcRotateCamera>);
    if (!cameraCurrent) return;
    cameraCurrent.radius = gameSettings.game.CAMERA_RANGE;

    if (target instanceof Vector3) {
      cameraCurrent.setTarget(target);
    } else {
      cameraCurrent.lockedTarget = target;
    }
  }

  spawn(params: SpawnParams) {
    super.spawn(params);
    if (this.zone.mesh && !this.zone.mesh.isDisposed()) {
      this.zone.mesh.position.y = 0.003;
    }

    this.inputQueue = [];
    this.offset = { dx: 0, dy: 0 };
    this.numberCommand = 0;
    this.attachCamera(this.car.mesh);
    this.inZone = true;
    this.oldPosition = undefined;
    this.cameraZoom();

    if (gameStore.curMaxTerritories < gameStore.territoriesCaptured) {
      gameStore.setMaxCurTerritories(gameStore.territoriesCaptured);
    }
  }

  death() {
    super.death();
    this.returnCoord = undefined;
    this.exitCoord = undefined;
  }

  writeTail() {
    if (this.inZone) return;
    if (!this.isAlive) return;
    if (!this.oldPosition) return;
    if (!this.isWriteTail) return;

    this.createAdditionalTailPoints();
    this.tail.addPoints([this.car.getPos()]);
  }

  checkInZone() {
    const { mesh } = this.car;
    const startPoint = this.getPos();
    const nose = getDirection({
      rotation: mesh.rotation.y,
      options: {
        startPoint,
        distance: 0.2,
      },
    });
    this.inZone = isPointInPolygon(new Real(nose.x, nose.y), this.zone.points);
  }

  extendZone() {
    const tailPoints = this.tail.points;
    if (tailPoints.length < 2) return;

    const newPoints = tailPoints.filter((tailPoint) => !isPointInPolygon(tailPoint, this.zone.points));
    if (newPoints.length < 2) return;

    const firstTailPoint = newPoints[0];
    const lastTailPoint = newPoints[newPoints.length - 1];
    const start = this.zone.getNearPoint(firstTailPoint).index;
    const end = this.zone.getNearPoint(lastTailPoint).index;
    const clockwisePoint = getPointsClockwise(start, end, this.zone.points, newPoints);
    const counterClockwisePoint = getPointsCounterClockwise(start, end, this.zone.points, newPoints);

    if (getArea(clockwisePoint) > getArea(counterClockwisePoint)) {
      this.extendedZone = counterClockwisePoint;
    } else {
      this.extendedZone = clockwisePoint;
    }

    if (!this.returnCoord) return;
    if (!this.exitCoord) return;
    const tailPoint = this.tail.points;
    tailPoint.push(this.getPos());
    tailPoint.unshift(this.exitCoord.toReal());
    const oldPoints = this.zone.points;

    try {
      const result = getExtendedZone(oldPoints, tailPoint);
      if (!result) return;
      this.zone.createOrUpdate(result, true);
    } catch (e) {
      console.error(e);
    }
  }

  cameraZoom() {
    if (gameStore.artificialZoom) return;

    const camera = this.car.mesh.getScene().getCameraByName('camera') as ArcRotateCamera;
    const percent = Math.floor(getPercentageFromTotalArea(this.zone.points));
    let targetRadius = gameSettings.game.CAMERA_RANGE;
    if (!gameStore.isMobile) {
      targetRadius *= 0.7;
    }

    if (percent >= 5 && percent < 10) {
      targetRadius *= 1.1;
    } else if (percent >= 10 && percent < 20) {
      targetRadius *= 1.2;
    } else if (percent >= 20 && percent < 40) {
      targetRadius *= 1.5;
    } else if (percent >= 40) {
      targetRadius *= 1.8;
    }

    BABYLON.Animation.CreateAndStartAnimation(
      'cameraAnim',
      camera,
      'radius',
      30,
      60,
      camera.radius,
      targetRadius,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
    );
  }

  setRotation(rotation: number) {
    this.car.mesh.rotation.y = rotation;
  }
}
