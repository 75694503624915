import { usePreview } from 'react-dnd-preview';
import { useStore } from '../../hooks/useStore';
import { Car } from '../../stores/CarsStore';
import { FactoryPlaceProps, MergingPlaceProps, ParkPlacesPlace } from '../../stores/ParkingStore/types';

export function IconPreview() {
  const { carsStore } = useStore();
  const preview = usePreview<FactoryPlaceProps | ParkPlacesPlace | MergingPlaceProps>();
  if (!preview.display) return null;
  const { item, style } = preview;
  let car: Car | undefined;
  if (item.carId) {
    car = carsStore.cars.find((c) => c.id === item.carId);
  } else if ('carIdOne' in item) {
    car = carsStore.cars.find((c) => c.id === item.carIdOne);
  }

  let imgSrc = '';

  if (car) {
    imgSrc = `/models/cars/${car.car.model}Icon.png`;
  } else {
    return null;
  }

  // if (itemType === 'ParkingPlace') {
  //   imgSrc = (item as ParkingPlace)?.car?.car.model ? `/models/cars/${(item as ParkingPlace)?.car?.car.model}.png` : '';
  // } else if (itemType === 'RefuelingPlace') {
  //   imgSrc = refuelingIcon;
  // } else if (itemType === 'MergingPlace') {
  //   const place = item as MergingPlace;
  //   imgSrc = place.firstCar ? place.firstCar.icon : place.resultCar?.icon || '';
  // } else if (itemType === 'FactoryPlace') {
  //   imgSrc = (item as FactoryPlace)?.car?.icon || '';
  // } else {
  //   return null;
  // }

  return (
    <div style={{ ...style, zIndex: 128 }}>
      <img style={{ width: 47, height: 47, transform: 'scale(2)' }} src={imgSrc} alt="preview-icon" />
    </div>
  );
}
