import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { Position } from './position';
import style from './style.module.sass';
import { top3Store } from './top3store';

export const Top3 = observer(() => {
  const top3Component: ReactElement[] = [];

  const length = Math.min(3, top3Store.entity.length);
  for (let i = 0; i < length; i++) {
    const entity = top3Store.entity[i];
    const { name, area, photo } = entity;
    top3Component.push(<Position key={name} color={entity.color} name={name} area={area} photo={photo} position={i + 1} />);
  }

  if (!top3Component.some((item) => item.key === 'Ты')) {
    const index = top3Store.entity.findIndex((item) => item.isYou);
    const you = top3Store.entity[index];
    if (you) {
      const { color, area, photo, name } = you;
      top3Component.push(<Position position={index + 1} key={name} color={color} name={name} area={area} photo={photo} />);
    }
  }

  return <div className={style.container}>{top3Component}</div>;
});
