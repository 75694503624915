import * as BABYLON from '@babylonjs/core';
import { Mesh, Scene, Vector3 } from '@babylonjs/core';
import { importModel } from '../../../helpers';

export async function loadCar(modelName: string, category: string, scene: Scene) {
  const findCar = scene.getMeshByName(`${modelName}Base`) as Mesh;
  if (findCar) return findCar;

  const [mesh] = await Promise.all([importModel(modelName, `models/cars/${category}/`, `${modelName}Base`)]);
  const car = mesh.getChildren()[0] as Mesh;
  car.setParent(null);
  mesh.dispose();
  car.name = `${modelName}Base`;
  car.scaling = new Vector3(0.2, 0.2, 0.2);
  car.position.y = 10;
  car.setEnabled(false);

  car.checkCollisions = false;
  car.isPickable = false;
  car.material?.freeze();
  car.alwaysSelectAsActiveMesh = true;
  car.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;

  return car;
}
