import { useEffect, useMemo, useRef } from 'react';

import { splitLabel } from './wordSplitter';

export interface StrokeTextProps {
  text: string | number;
  style?: string;
  wrap?: boolean;
  maxWidth?: number;
}

export function StrokeText({ text, style, wrap, maxWidth }: StrokeTextProps) {
  const svgRef = useRef<SVGSVGElement>(null);
  const label = useMemo(() => (wrap && maxWidth ? splitLabel(String(text), maxWidth) : [String(text)]), [text, maxWidth]);
  const wrappedText = label.map((word, idx) => (
    <tspan key={word} x="50%" dy={idx === 0 ? 0 : 18} textAnchor="middle">
      {word}
    </tspan>
  ));

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    const bbox = svg.getBBox();
    svg.setAttribute('width', String(Math.ceil(bbox.width) + 2));

    if (label.length > 1) {
      svg.setAttribute('height', String(bbox.height + 2));
      return;
    }

    const tspanEl = svg.getElementsByTagName('tspan')[0];
    const { height } = tspanEl.getBoundingClientRect();
    svg.setAttribute('height', String(height));
  }, [text, maxWidth]);

  return (
    <svg ref={svgRef}>
      <text
        style={{ paintOrder: 'stroke fill' }}
        className={style}
        // alignmentBaseline="middle" // In Firefox is not supported, so the 'y' is set 70% (instead of 50%)
        y={label.length > 1 ? '15' : '70%'}
      >
        {wrappedText}
      </text>
    </svg>
  );
}

StrokeText.textString = ({ text, wrap, maxWidth }: StrokeTextProps) => {
  const label = wrap && maxWidth ? splitLabel(String(text), maxWidth) : [String(text)];
  const wrappedText = label.reduce((acc, word, idx) => {
    acc += `<tspan height="19.33" x="0" dy=${idx === 0 ? '"0"' : '"12"'} textAnchor="middle">
      ${word}
    </tspan>`;

    return acc;
  }, '');
  return `<svg 
  width="${String(text).length * 7.43}"
  height="19.33"
  xmlns="http://www.w3.org/2000/svg" version="1.2">
  <style>
    text {
      fill: #FFF;
      stroke: #0011AC;
      font-family: 'DR Agu', sans-serif;
    }
  </style>
  <text
    y=${label.length > 1 ? '"10"' : '"70%"'}
  >
    ${wrappedText}
  </text>
</svg>`;
};

StrokeText.defaultProps = {
  style: '',
  wrap: false,
  maxWidth: 0,
};
