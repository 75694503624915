export function keyPressListener(keyCode: string, callback: () => void) {
  function onKeyPress(event: KeyboardEvent) {
    const { code } = event;
    if (keyCode.toLowerCase().includes(code.toLowerCase())) {
      callback();
    }
  }

  // Добавляем слушателя события 'keydown' для всей страницы.
  window.addEventListener('keydown', onKeyPress);

  return () => {
    window.removeEventListener('keydown', onKeyPress);
  };
}
