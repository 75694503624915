import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, Color3, Mesh, Observable, PolygonMeshBuilder, StandardMaterial, Tags } from '@babylonjs/core';
import { nearestPointOnLine, polygonToLineString } from '@turf/turf';
import gameSettings from '../../../settings/gameSettings';
import { Convertors, ConvertorTurf, getArea, getNearPoint, getPointsBetweenStrict } from '../../helpers';
import { createMark } from '../../meshs';
import { Coordinate, Real } from '../../types';

interface Params {
  name: string;
  color: Color3;
  lift?: number;
}

const totalAreaPoint = BABYLON.Polygon.Circle(gameSettings.game.GROUND_RADIUS - gameSettings.game.MARGIN_FROM_THE_EDGE_OF_THE_MAP, 0, 0, 28);
const totalArea = getArea(Convertors.vectors.toReal(totalAreaPoint));

export class Zone {
  name: string;
  points: Real[] = [];
  isActive = true;
  isVisible = true;
  color: Color3;
  fastPoints: Real[] = [];
  area = 0;
  percentageArea = 0;
  maxPercentageArea = 0;

  isMarkVisible = false;
  mark: Mesh[] = [];
  isDetailMarkVisible = false;
  detailMark: Mesh[] = [];

  onDeleteObserver = new Observable<Zone>();
  onExpandObserver = new Observable<number>();
  onReduceObserver = new Observable<Zone>();
  onChangeObserver = new Observable<Zone>();
  onExtendActionObserver = new Observable<number>();

  material: StandardMaterial;
  mesh?: Mesh;
  lift = 0;

  constructor({ name, color, lift }: Params) {
    this.name = name;
    this.color = color;
    this.lift = lift ?? 0;

    this.material = new StandardMaterial('zoneMat');
    this.material.diffuseColor = color;
    this.material.specularColor = Color3.Black();
    this.material.freeze();
  }

  createOrUpdate(points: Real[], notifyAction = false) {
    if (points.length === 0) {
      this.delete();
      return;
    }

    const oldArea = getArea(this.points);
    const newArea = getArea(points);

    if (this.mesh && !this.mesh.isDisposed()) this.mesh.dispose();

    const polygon = new PolygonMeshBuilder(this.name, points).build();
    polygon.isVisible = this.isVisible;
    polygon.doNotSyncBoundingInfo = true;
    polygon.position.y = this.lift;
    polygon.material = this.material;
    Tags.AddTagsTo(polygon, 'zone');

    polygon.checkCollisions = false;
    polygon.isPickable = false;
    polygon.freezeWorldMatrix();
    polygon.doNotSyncBoundingInfo = true;
    polygon.alwaysSelectAsActiveMesh = true;
    polygon.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY;

    this.mesh = polygon;

    this.points = points;
    this.isActive = points.length > 0;

    this.area = newArea;
    this.percentageArea = (this.area / totalArea) * 100;
    this.maxPercentageArea = Math.max(this.maxPercentageArea, this.percentageArea);
    if (this.area >= oldArea) {
      if (oldArea !== 0) {
        this.onExpandObserver.notifyObservers(newArea - oldArea);
        if (notifyAction) this.onExtendActionObserver.notifyObservers(newArea - oldArea);
      }
    } else {
      this.onReduceObserver.notifyObservers(this);
    }

    this.onChangeObserver.notifyObservers(this);

    if (this.isMarkVisible) {
      this.hideMark();
      this.showMark();
    }

    if (this.isDetailMarkVisible) {
      this.hideDetailMark();
      this.showDetailMark();
    }
  }

  getArea() {
    return this.area;
  }

  delete() {
    this.points = [];
    this.fastPoints = [];
    this.area = 0;
    this.isActive = false;
    this.isMarkVisible = false;
    this.mark = [];
    this.isDetailMarkVisible = false;
    this.detailMark = [];
    this.onDeleteObserver.notifyObservers(this);
    this.mesh?.dispose();
  }

  getDetailPoint() {
    const points = Convertors.points.toCoord(this.points);

    if (points.length < 2) return points;

    if (points.length === 2) {
      const first = points[0];
      const last = points.at(-1);
      if (!last) throw Error('Не удалось найти последний элемент');
      return getPointsBetweenStrict({ start: first, end: last });
    }

    const detailPoints: Coordinate[] = [];
    const length = points.length - 1;
    for (let i = 0; i < length; i++) {
      const cur = points[i];
      const next = points[i + 1];
      const pointsBetween = getPointsBetweenStrict({ start: cur, end: next });

      if (i === 0) {
        detailPoints.push(...pointsBetween);
      } else {
        detailPoints.splice(detailPoints.length - 1, 1, ...pointsBetween);
      }
    }

    const first = points[0];
    const last = points.at(-1);
    if (!last) throw Error('Не удалось найти последний элемент');
    const pointsBetween = getPointsBetweenStrict({ start: first, end: last });
    pointsBetween.splice(0, 1);
    pointsBetween.splice(detailPoints.length - 1, 1);
    detailPoints.push(...pointsBetween);

    return detailPoints;
  }

  getNearPosition(point: Real) {
    const polygon = ConvertorTurf.fromReal(this.points);
    const polygonLine = polygonToLineString(polygon);
    const addPoint = point.toArray();
    if (polygonLine.type === 'Feature') {
      const nearPoint = nearestPointOnLine(polygonLine, addPoint);
      return new Real(nearPoint.geometry.coordinates[0], nearPoint.geometry.coordinates[1]);
    }

    const nearPoint = nearestPointOnLine(polygonLine.features[0], addPoint);
    return new Real(nearPoint.geometry.coordinates[0], nearPoint.geometry.coordinates[1]);
  }

  getNearPoint(point: Real) {
    return getNearPoint(point, this.points);
  }

  showMark() {
    this.isMarkVisible = true;
    for (const point of this.points) {
      this.mark.push(createMark(point.toV2(), { delay: 10000, color: Color3.White() }));
    }
  }

  hideMark() {
    this.isMarkVisible = false;
    for (const mark of this.mark) {
      mark.dispose();
    }
  }

  showDetailMark() {
    this.isDetailMarkVisible = true;
    for (const point of this.getDetailPoint()) {
      this.detailMark.push(createMark(point.toReal().toV2(), { delay: 10000, color: Color3.Black() }));
    }
  }

  hideDetailMark() {
    this.isDetailMarkVisible = false;
    for (const mark of this.detailMark) {
      mark.dispose();
    }
  }

  setLift(lift: number) {
    this.lift = lift;
    if (!this.mesh) return;
    this.mesh.position.y = lift;
  }

  getPercentageArea(roundUp = false) {
    if (roundUp) return Math.floor(this.percentageArea);
    return this.percentageArea;
  }
}
