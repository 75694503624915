import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, IAnimationKey } from '@babylonjs/core';

interface IOptions {
  speed?: number;
  callback?: () => void;
  startOpacity?: number;
  endOpacity?: number;
}

export function changeOpacityAnim(mesh: AbstractMesh, options?: IOptions) {
  const { speed = 1, callback, endOpacity = 1, startOpacity = 0 } = options || {};
  const scene = mesh.getScene();
  const frames = 60;

  const animation = new BABYLON.Animation(
    'changeOpacity',
    'visibility',
    frames / 2,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  const keys: IAnimationKey[] = [];
  keys.push(
    {
      frame: 0,
      value: startOpacity,
    },
    {
      frame: frames,
      value: endOpacity,
    },
  );

  animation.setKeys(keys);
  mesh.animations = [animation];
  return scene.beginAnimation(mesh, 0, frames, false, speed, callback);
}
