import { Vector3 } from '@babylonjs/core';
import { importModel } from '../../helpers';

export async function createCrown() {
  const crown = await importModel('crown', 'models/elems/', 'CrownBase');

  const size = 0.2;
  crown.scaling = new Vector3(size, size, size);
  crown.setEnabled(false);

  return crown;
}
