import { TutorialElementType } from '../../stores/TutorialStore';

import './style.scss';

export interface PanelProps {
  type: 'factory' | 'merging' | 'parking';
  header: React.ReactNode;
  content: React.ReactNode;
  dataTutorial?: TutorialElementType;
}

export function Panel({ type, header, content, dataTutorial }: PanelProps) {
  return (
    <div className={type} data-tutorial={dataTutorial}>
      <header>{header}</header>
      <div className="content">{content}</div>
    </div>
  );
}

Panel.defaultProps = {
  dataTutorial: null,
};
