import { Color3, Scene, Vector2, Vector3 } from '@babylonjs/core';
import { Client, Room } from 'colyseus.js';
import { stateAnim } from '../animations';
import { pointersStore } from '../components/pointers/pointersStore';
import { top3Store } from '../components/top3/top3store';
import { Convertors, getArea, getDistance, keyPressListener } from '../helpers';
import { EntityColors } from '../materials';
import { createLocation, createMark, syncLoadCars } from '../meshs';
import { deleteAllMarks } from '../meshs/dev/deleteAllMarks';
import { SuperMagnet } from '../meshs/environment/bonuses/superMagnet';
import { Coin } from '../multiplayer/agent';
import { ManagerMP } from '../multiplayer/manager/manager';
import { Player, TrackedEntity } from '../multiplayer/player';
import { gameStore } from '../stores/gameStore';
import { Real } from '../types';
import { SchemeBaseRoom, SchemeBonus, SchemeCoin, SchemePlayer } from './schemes';

export class ColyseusClient {
  manager: ManagerMP;
  readonly client: Client;
  readonly scene: Scene;
  room!: Room<SchemeBaseRoom>;
  pintCounter!: NodeJS.Timeout;
  nameRoom: string;
  private stress = false;
  private removeListeners: { focus: Array<() => void>; blur: Array<() => void> } = { focus: [], blur: [] };
  private isBlur = false;
  private unsubscribeKeyEvents: Array<() => void> = [];

  constructor(scene: Scene, nameRoom: string) {
    this.scene = scene;
    this.manager = new ManagerMP(this.scene);
    this.nameRoom = nameRoom;
    this.client = new Client(process.env.COLYSEUS_URL);
  }

  async initialization() {
    await new Promise((resolveMain, reject) => {
      const message = {
        model: gameStore.model,
        level: gameStore.level,
        name: gameStore.name,
        photo: gameStore.photo,
        uuid: gameStore.id,
        carId: gameStore.carId,
      };
      this.client
        .joinOrCreate(this.nameRoom, message, SchemeBaseRoom)
        .then(async (room: Room) => {
          this.room = room;
          this.bucksListeners(room);
          gameStore.setRoom(this.room);

          // На сервере не успевает создаться поле players
          await new Promise((resolve) => {
            setTimeout(async () => {
              this.room.state.manager.players.onAdd(this.createPlayers(this.room));
              this.room.state.manager.players.onRemove(this.onDisconnect());
              this.room.state.manager.coins.onAdd(this.createCoin());
              this.room.state.manager.bonuses.onAdd(this.createBonus());
              console.log('Создания окружения');
              await createLocation(this.room.state.map);
              resolve(true);
            }, 1000);
          });
          this.room.onLeave(() => {
            clearInterval(this.pintCounter);
            this.scene.dispose();
            pointersStore.clearEntityArray();
            for (const focus of this.removeListeners.focus) {
              window.removeEventListener('focus', focus);
            }
            for (const blur of this.removeListeners.blur) {
              window.removeEventListener('blur', blur);
            }
            if (process.env.NODE_ENV === 'development') console.log('Клиент вышел из комнаты');
            gameStore.onGoHome();
          });
          gameStore.setAdditionalGoHome(() => {
            top3Store.calculateTop3([]);
            this.unsubscribeKeyEvents.forEach((event) => event());
            if (this.room.connection.isOpen) {
              this.room.leave(true);
            }
          });

          this.countPing(room);
          this.trackCoins();
          this.trackBonuses();
          await this.stressTest();
          this.subscribeKeyboardEvents();
          this.drawRevivedTails();

          resolveMain(true);
        })
        .catch((e) => {
          gameStore.setErrorCode(e.code ?? 4000);
          clearInterval(this.pintCounter);
          this.scene.dispose();
          for (const focus of this.removeListeners.focus) {
            window.removeEventListener('focus', focus);
          }
          for (const blur of this.removeListeners.blur) {
            window.removeEventListener('blur', blur);
          }
          gameStore.onGoHome();
          reject(e);
        });
    });
  }

  private subscribeKeyboardEvents() {
    const onKeyPressShowAllTails = keyPressListener('KeyM', () => {
      deleteAllMarks();
      this.showAllTails();
    });

    const onKeyPressShowServerTails = keyPressListener('KeyN', () => {
      deleteAllMarks();
      this.getAllPointFromServer();
    });

    this.unsubscribeKeyEvents.push(onKeyPressShowAllTails, onKeyPressShowServerTails);
  }

  private countPing(room: Room<SchemeBaseRoom>) {
    const firstPing = Date.now();
    room.send('ping', { ping: firstPing });

    this.pintCounter = setInterval(() => {
      if (this.scene.isDisposed) return;
      const ping = Date.now();
      room.send('ping', { ping });
    }, 5000);

    room.onMessage('pong', (data) => {
      const pong = Date.now();
      const ping = pong - data.pong;
      console.log(`Ping: ${ping}ms`);
      gameStore.setPing(ping);
    });
  }

  private bucksListeners(room: Room<SchemeBaseRoom>) {
    room.onMessage('bucksCreate', (data: { busks: { position: { x: number; y: number }, id: string }[] }) => {
      if (this.scene.isDisposed) return;
      for (const busk of data.busks) {
        const { id, position: serverCoord } = busk;
        const realPos = new Real(serverCoord.x, serverCoord.y);
        this.manager.createBucks(id, realPos as Real);
      }
    });

    room.onMessage('bucksPickup', (data: { id: string }) => {
      if (this.scene.isDisposed) return;
      const busks = this.manager.getBucks(data.id);
      if (!busks) return;

      const currentPlayer = this.manager.getCurrentPlayer();
      if (!currentPlayer) return;

      gameStore.setBucksPickUp(gameStore.bucksPickUp + 1);
      busks.runMagnetism(currentPlayer.car.mesh);
    });

    room.onMessage('bucksDelete', (data: { id: string }) => {
      if (this.scene.isDisposed) return;
      const busks = this.manager.getBucks(data.id);
      if (!busks) return;

      busks.delete();
    });
  }

  onDisconnect() {
    return (_: SchemePlayer, sessionId: string) => {
      const player = this.manager.get(sessionId);
      player?.death();
      // TODO Если player явлеяется классом TrackedEntity
      if (!(player instanceof TrackedEntity)) return;
      player.avatar.delete();
    };
  }

  createPlayers(room: Room) {
    return async (playerScheme: SchemePlayer, sessionId: any) => {
      const isCurrentPlayer = sessionId === room.sessionId;
      if (isCurrentPlayer) {
        const { color, startPos, zonePoints, tailPoints } = this.extractProps(playerScheme);
        const param = {
          model: gameStore.model,
          id: sessionId,
          startPos,
          color,
          tailPoints,
          zonePoints,
          photo: gameStore.photo,
        };
        const curPlayer = this.manager.createPlayer(param);
        this.trackPlayer(playerScheme, curPlayer);
      } else {
        const { color, startPos, zonePoints, tailPoints, model: extractModel, name, photo } = this.extractProps(playerScheme);
        const mesh = await syncLoadCars([extractModel], this.scene);

        let model: string;
        if (!mesh.length) {
          console.error(`Не удалось загрузить модель ${extractModel}`);
          await syncLoadCars(['retro1'], this.scene);
          model = 'retro1';
        } else {
          console.log(`Модель ${extractModel} загружена`);
          model = extractModel;
        }

        const params = { model, id: sessionId, startPos, color, tailPoints, zonePoints, name, photo };
        const connectedPlayer = this.manager.createConnectedPlayer(params);
        this.trackConnectPlayer(playerScheme, connectedPlayer);
      }
    };
  }

  createCoin() {
    return (serverCoin: SchemeCoin, id: string) => {
      if (this.scene.isDisposed) return;
      const startPos = Convertors.server.fromReal(serverCoin.startPos);
      const realPos = Convertors.server.fromReal(serverCoin.realPos);
      const coin = new Coin({
        id,
        scene: this.scene,
        startPoint: startPos,
        endPoint: realPos,
      });
      this.manager.coins.set(id, coin);

      coin.onDelete.add(() => {
        this.manager.coins.delete(id);
      });
    };
  }

  getAllPointFromServer() {
    if (process.env.NODE_ENV !== 'development') return;
    if (this.scene.isDisposed) return;
    this.room.send('getAllTails');
  }

  drawRevivedTails() {
    if (process.env.NODE_ENV !== 'development') return;
    if (this.scene.isDisposed) return;
    this.room.onMessage('allTails', (data: { allTails: { tail: { x: number, y: number }[], name: string }[] }) => {
      if (this.scene.isDisposed) return;
      for (const { tail } of data.allTails) {
        for (const point of tail) {
          const v2 = new Vector2(point.x, point.y);
          createMark(v2, { color: new Color3(1, 0, 0), delay: 20 });
        }
      }
    });
  }

  createBonus() {
    return (serverBonus: SchemeBonus, id: string) => {
      if (this.scene.isDisposed) return;
      const startPos = Convertors.server.fromReal(serverBonus.realPos);
      const bonus = new SuperMagnet({ position: startPos, id: serverBonus.id });
      this.manager.bonuses.set(id, bonus);

      bonus.onDelete.add(() => {
        this.manager.coins.delete(id);
      });
    };
  }

  trackCoins() {
    this.room.onMessage('coinPick', ({ coinId, owner }: { coinId: string; owner: string }) => {
      const coin = this.manager.getCoin(coinId);
      const player = this.manager.get(owner);
      if (!coin) return;
      if (!player) return;
      if (!player.car.mesh || player.car.mesh.isDisposed()) {
        coin.delete();
        return;
      }
      coin.runMagnetism(player.car.mesh);
      if (player.name === 'Ты') {
        coin.onDelete.add(() => {
          this.manager.coinPickUpSound.play();
        });
      }
    });
  }

  trackBonuses() {
    this.room.onMessage('bonusPickup', ({ bonusId, owner }: { bonusId: string; owner: string }) => {
      const bonus = this.manager.getBonus(bonusId);
      const player = this.manager.get(owner);
      if (!bonus) return;
      if (!player) return;
      if (!player.car.mesh || player.car.mesh.isDisposed()) {
        bonus.delete();
        return;
      }
      bonus.runMagnetism(player.car.mesh);
      if (player.name === 'Ты') {
        bonus.onDelete.add(() => {
          this.manager.coinPickUpSound.play();
        });
      }
    });
  }

  private extractProps(serverPlayer: SchemePlayer) {
    const { pos, colorName, tail, zone, model } = serverPlayer;
    const colorObj = EntityColors.getColorFromName(colorName, true);
    if (!colorObj) throw Error('Не удалось определить цвет');
    const { r, g, b } = colorObj.color;
    const color = new Color3(r, g, b);
    const startPos = Convertors.server.fromCoord(pos).toReal();
    const zonePoints = Convertors.server.fromReals(zone.points);
    const tailPoints = Convertors.server.fromReals(tail);
    const { name, photo } = serverPlayer;
    return {
      color,
      startPos,
      zonePoints,
      tailPoints,
      model,
      name,
      photo,
    };
  }

  trackPlayer(serverPlayer: SchemePlayer, player: Player) {
    this.manager.calculateTop();

    player.zone.onChangeObserver.add(() => {
      if (this.scene.isDisposed) return;
      this.manager.calculateTop();
    });

    const onFocus = () => this.onFocusCurPlayer(player, serverPlayer);
    const onBlur = () => this.onBlurCurPlayer(player);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    this.removeListeners.focus.push(onFocus);
    this.removeListeners.blur.push(onBlur);

    serverPlayer.listen('killCounter', (killCounter) => {
      if (this.scene.isDisposed) return;
      gameStore.setKills(killCounter);
      player.onKillObserver.notifyObservers(player);
    });

    serverPlayer.listen('isLive', (isLive) => {
      if (this.scene.isDisposed) return;
      if (!isLive) {
        if (!player.isAlive) return;
        const killer = this.manager.get(serverPlayer.killerId);
        if (killer) {
          const isKillSelf = killer.id === player.id;
          gameStore.setKillSelf(isKillSelf);
          gameStore.setCauseOfDeath(serverPlayer.causeOfDeath, killer.name);
        }
        player.death();
        gameStore.setMoneyPickUp(serverPlayer.countPickUpCoins);
      } else {
        if (player.isAlive) return;
        const params = this.extractProps(serverPlayer);
        player.spawn(params);
      }
    });

    serverPlayer.listen('countPickUpCoins', (countPickUpCoins) => {
      if (this.scene.isDisposed) return;
      gameStore.setMoneyPickUp(countPickUpCoins);
    });

    serverPlayer.zone.onChange(() => {
      if (this.scene.isDisposed) return;
      const newPoints = Convertors.server.fromReals(serverPlayer.zone.points);

      if (player.needUpdateZone) {
        const points = Convertors.server.fromReals(serverPlayer.zone.points);
        player.zone.createOrUpdate(points);
        player.needUpdateZone = false;
        return;
      }

      const curArea = player.zone.getArea();
      const newArea = getArea(newPoints);
      const diff = Math.abs(newArea - curArea);
      if (diff < 0.5) return;
      const points = Convertors.server.fromReals(serverPlayer.zone.points);
      player.zone.createOrUpdate(points);
    });

    serverPlayer.onChange(() => {
      if (this.scene.isDisposed) return;

      if (this.isBlur) {
        const pos = Convertors.server.fromReal(serverPlayer.realPos);
        const { rotation } = serverPlayer;
        player.setPosition(pos);
        player.setRotation(rotation);
        return;
      }

      const index = player.inputQueue.findIndex((item) => item.number === serverPlayer.lastCommandTick);
      if (index === -1) return;
      const pos = player.inputQueue[index];
      const dist = getDistance(pos.result, serverPlayer.realPos);
      if (dist <= 0.1) {
        player.inputQueue.splice(0, index + 1);
        if (!player.inputQueue.length) player.numberCommand = 0;
        return;
      }

      // Пересчет следующих движений
      const dx = serverPlayer.realPos.x - player.inputQueue[index].result.x;
      const dy = serverPlayer.realPos.y - player.inputQueue[index].result.y;

      player.inputQueue[index].result = serverPlayer.realPos;
      player.offset.dx = dx;
      player.offset.dy = dy;

      player.inputQueue.splice(0, index + 1);
      if (!player.inputQueue.length) player.numberCommand = 0;
    });

    serverPlayer.checkPoint.onChange(() => {
      if (this.scene.isDisposed) return;
      console.log({ checkPoint: serverPlayer.checkPoint });
      const points = Convertors.server.fromReals(serverPlayer.checkPoint);
      for (const point of points) {
        createMark(point.toV2(), { delay: 5, color: Color3.Blue(), diameterTop: 2 });
      }
    });
  }

  trackConnectPlayer(serverPlayer: SchemePlayer, agent: TrackedEntity) {
    this.manager.calculateTop();

    const onFocus = () => this.onFocusAgent(serverPlayer, agent);
    const onBlur = () => this.onBlurAgent(agent);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    this.removeListeners.focus.push(onFocus);
    this.removeListeners.blur.push(onBlur);

    serverPlayer.listen('isLive', (isLive) => {
      if (this.scene.isDisposed) return;
      if (!serverPlayer) return;
      if (!isLive) {
        if (!agent.isAlive) return;
        agent.death();
      } else {
        if (agent.isAlive) return;
        const params = this.extractProps(serverPlayer);
        agent.spawn(params);
        if (!agent.isVisible) {
          agent.car.hide();
        }
      }
      this.manager.calculateTop();
    });

    serverPlayer.tail.onChange(() => {
      if (!serverPlayer) return;
      if (this.scene.isDisposed) return;
      const tailPoint = serverPlayer.tail;

      if (agent.qtyLastReceivedPoints === tailPoint.length) return;
      agent.qtyLastReceivedPoints = tailPoint.length;

      if (!tailPoint.length) {
        if (!agent.tail.points.length) return;
        agent.tail.delete();
      } else {
        const newPoints = Convertors.server.fromReals(tailPoint);

        try {
          if (!agent.tail.additionalPoints.length) {
            agent.tail.additionalPoints = [agent.zone.getNearPosition(newPoints[0])];
          }
        } catch (e) {
          console.log(e);
        }

        const lastPointNowTail = agent.tail.points.at(-1);
        if (lastPointNowTail) {
          const lastPointServer = newPoints.at(-1);
          if (!lastPointServer) return;
          const distance = getDistance(lastPointNowTail, lastPointServer);
          if (distance < 1) return;
          agent.tail.points = newPoints;
          agent.tail.draw();
        } else {
          agent.tail.points = newPoints;
          agent.tail.draw();
        }
      }
    });

    serverPlayer.zone.onChange(() => {
      if (this.scene.isDisposed) return;
      if (!serverPlayer) return;
      const points = Convertors.server.fromReals(serverPlayer.zone.points);
      agent.zone.createOrUpdate(points);
      this.manager.calculateTop();
    });

    serverPlayer.onChange(() => {
      if (this.scene.isDisposed) return;
      if (!serverPlayer) return;
      const pos = Convertors.server.fromReal(serverPlayer.realPos);
      const targetPosition = new Vector3(pos.x, agent.car.mesh.position.y, pos.y);
      const targetRotation = serverPlayer.rotation ?? agent.car.mesh.rotation.y;
      const targetState = { targetRotation, targetPosition };
      stateAnim(agent.car.mesh, targetState, 130, agent.car.mesh.getScene(), 1, 1000);

      if (agent.avatar?.mesh && agent.avatar?.mesh?.isEnabled()) {
        const targetPositionAvatar = new Vector3(pos.x, agent.avatar.mesh.position.y, pos.y);
        stateAnim(agent.avatar.mesh, { targetPosition: targetPositionAvatar }, 130, agent.car.mesh.getScene(), 1, 1000);
      }

      const currentPlayer = this.manager.getCurrentPlayer();
      if (!currentPlayer) return;
      pointersStore.calculateNear(this.manager.getAllPlayer(), currentPlayer.getPos());
    });

    serverPlayer.listen('inZone', (inZone) => {
      if (this.scene.isDisposed) return;
      if (!serverPlayer) return;
      agent.inZone = inZone;
    });
  }

  private onBlurCurPlayer(player: Player) {
    player.isWriteTail = false;
    player.isMove = false;
    this.isBlur = true;
  }

  private onFocusCurPlayer(player: Player, serverPlayer: SchemePlayer) {
    this.isBlur = false;
    if (this.scene.isDisposed) return;
    const isAlive = serverPlayer.isLive;
    if (player.isAlive && !isAlive) {
      player.death();
      return;
    }

    player.angleRotation = player.car.mesh.rotation.y;

    const serverZone = Convertors.server.fromReals(serverPlayer.zone.points);
    const serverTail = Convertors.server.fromReals(serverPlayer.tail);
    const serverPos = Convertors.server.fromReal(serverPlayer.realPos);
    player.zone.createOrUpdate(serverZone);
    player.tail.delete();
    player.tail.addPoints(serverTail);
    player.setPosition(serverPos);
    player.checkInZone();

    setTimeout(() => {
      player.isWriteTail = true;
      player.isMove = true;
    }, 100);
  }

  private onFocusAgent(serverPlayer: SchemePlayer, agent: TrackedEntity) {
    if (this.scene.isDisposed) return;
    if (!serverPlayer.isLive) return;
    agent.isWriteTail = false;
    agent.setPosition(Convertors.server.fromReal(serverPlayer.realPos));
    const newPoints = Convertors.server.fromReals(serverPlayer.tail);
    agent.tail.points = newPoints;
    if (newPoints.length) {
      agent.tail.additionalPoints = [agent.zone.getNearPosition(newPoints[0])];
    }
    agent.tail.draw();
    setTimeout(() => {
      agent.isWriteTail = true;
    }, 1000);
  }

  private onBlurAgent(agent: TrackedEntity) {
    agent.isWriteTail = false;
  }

  private async stressTest() {
    if (this.scene.isDisposed) return;
    if (!this.stress) return;
    this.stress = false;
    const numConnects = 1000;
    const delay = 1000 * 2;
    let counterConnects = 0;

    const message = {
      model: gameStore.model,
      level: gameStore.level,
      name: gameStore.name,
      photo: gameStore.photo,
      uuid: gameStore.id,
    };

    const connectFunc = async () => {
      await this.client.joinOrCreate(this.nameRoom, message);
      console.log(`Создано подключение #${counterConnects}`);
      counterConnects += 1;
      if (counterConnects >= numConnects) return;
      setTimeout(async () => {
        await connectFunc();
      }, delay);
    };

    setTimeout(async () => {
      await connectFunc();
    }, delay);
  }

  showAllTails() {
    const currentPlayer = this.manager.getCurrentPlayer();
    const allEntities = this.manager.getAllPlayer().filter((player) => player.id !== currentPlayer?.id);
    for (const entity of allEntities) {
      entity.tail.points.forEach((point) => {
        createMark(point.toV2(), { color: new Color3(1, 0, 0), delay: 20 });
      });
    }
  }
}
