import { AbstractMesh, Mesh, Observable, Scene } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import { Convertors, getDistance, getSize } from '../../../helpers';
import { gameStore } from '../../../stores/gameStore';
import { Real } from '../../../types';

export function calculateSpeed(distance: number): number {
  const maxDistance = 10;
  const minDistance = 2;
  const maxSpeed = 1;
  const minSpeed = 0.5;

  if (distance >= maxDistance) {
    return minSpeed;
  }

  if (distance <= minDistance) {
    return maxSpeed;
  }

  // Для расстояний между minDistance и maxDistance, скорость уменьшается линейно от maxSpeed до minSpeed
  const speedRange = maxSpeed - minSpeed; // Разница между максимальной и минимальной скоростями
  const distanceRange = maxDistance - minDistance; // Диапазон расстояний для уменьшения скорости
  const speedFactor = (distance - minDistance) / distanceRange; // Фактор, который показывает, где находится текущее расстояние в диапазоне
  return +(1 - speedFactor * speedRange).toFixed(2); // Вычисляем скорость, учитывая фактор
}

export interface IPickupItemCreate {
  id: string;
  position: Real;
  modelName: string
  name: string
  height?: number
  copyMode: 'clone' | 'instance'
}

export abstract class PickupItem {
  mesh: AbstractMesh;
  shadowMesh?: AbstractMesh;
  owner?: AbstractMesh;

  speed = 0.03;
  functionRunMagnet = () => {};
  scene: Scene;
  canPick = false;

  onDelete = new Observable<PickupItem>();
  onRunMagnetism = new Observable<PickupItem>();
  onMagnetism = new Observable<PickupItem>();
  onReady = new Observable<PickupItem>();
  onPickup = new Observable<PickupItem>();

  protected constructor({ id, position, modelName, name, copyMode, height: paramHeight }: IPickupItemCreate) {
    const { scene } = gameStore;
    const original = scene.getMeshByName(modelName) as Nullable<Mesh>;
    if (original === null) {
      throw new Error(`Base mesh ${modelName} not found`);
    }

    if (copyMode === 'clone') {
      this.mesh = original.clone(name + id);
    } else {
      this.mesh = original.createInstance(name + id);
    }

    this.mesh.setEnabled(true);
    const height = getSize(this.mesh).y;

    this.mesh.rotationQuaternion = null;

    this.mesh.position = position.toV3(paramHeight ?? height / 2);
    this.mesh.id = id ?? this.mesh.name;

    this.scene = scene;
    // optimizationMesh(this.mesh, AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY);
  }

  runMagnetism(owner: Mesh) {
    this.onRunMagnetism.notifyObservers(this);
    this.owner = owner;
    this.functionRunMagnet = this.magnetism.bind(this);
    this.mesh.getScene().registerBeforeRender(this.functionRunMagnet);
  }

  magnetism() {
    if (!this.canPick) return;
    const { owner } = this;
    if (!owner || owner.isDisposed()) {
      this.delete();
      this.mesh.getScene().unregisterBeforeRender(this.functionRunMagnet);
      return;
    }

    const targetPosition = Convertors.vector.toV2(owner.position);
    const curPosition = Convertors.vector.toV2(this.mesh.position);
    const distance = getDistance(curPosition, targetPosition);
    if (distance <= 0.1) {
      this.onPickup.notifyObservers(this);
      this.delete();
      this.mesh.getScene().unregisterBeforeRender(this.functionRunMagnet);
      return;
    }
    this.speed += 0.005;
    const direction = owner.position.subtract(this.mesh.position).normalize();
    this.mesh.position = this.mesh.position.add(direction.scale(this.speed * this.mesh.getScene().getAnimationRatio()));
    this.onMagnetism.notifyObservers(this);
  }

  delete() {
    this.mesh.dispose();
    this.shadowMesh?.dispose();
    this.onDelete.notifyObservers(this);
  }
}
