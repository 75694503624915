import { polygon, Position } from '@turf/turf';
import { Real } from '../../types/points/real';

export class ConvertorTurf {
  static fromReal(coordinates: Real[]) {
    const polyline = coordinates.map((point) => [point.x, point.y]);
    // Создаем полигон
    polyline.push([coordinates[0].x, coordinates[0].y]);
    return polygon([polyline]);
  }

  static toReal(polygonPoints: Position[][]): Real[] {
    const coordinates = polygonPoints[0];
    return coordinates.map((point) => new Real(point[0], point[1]));
  }

  static toLineString(reals: Real[]): Position[] {
    return reals.map((real) => [real.x, real.y]);
  }
}
