import { observer } from 'mobx-react';

// import { MergingPlaceProps } from '../MergingPlaceProps';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Place } from '../../../../components/Place';
import { Timer } from '../../../../components/Timer';
import { useStore } from '../../../../hooks/useStore';
import { MergingPlaceProps } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export const WaitingPlace = observer(({ place }: { place: MergingPlaceProps }) => {
  const { carsStore, userStore, parkingStore, tutorialStore } = useStore();
  const navigate = useNavigate();
  const endT = useRef(true);

  const car = carsStore.cars.find((c) => c.id === place.carIdOne);

  const onScore = () => {
    navigate('score', { state: { title: 'Слияние', placeIdx: place.idx, zIndex: tutorialStore.currentStepIdx === 7 && 'unset' } });
    if (tutorialStore.currentStepIdx === 7) {
      tutorialStore.nextStep();
    }
  };

  const endTimer = () => {
    if (endT.current) {
      endT.current = false;
      carsStore.loadCarsByUser(userStore.user.id);
      parkingStore.loadParking(userStore.user.id);

      // if (tutorialStore.currentStepIdx && tutorialStore.currentStepIdx >= 6 && tutorialStore.currentStepIdx <= 8) {
      //   setTimeout(async () => {
      //     console.log({ event: 'Timer merging end', curTutorStep: tutorialStore.currentStepIdx, place });
      //     tutorialStore.setStep(9);
      //     backend.setTutor(userStore.user.id, 9);
      //     if (place.carId) carsStore.setSelectedCar(place.carId);
      //   }, 200);
      // }
    }
  };

  return (
    <button type="button" onClick={onScore} data-tutorial="merging-waiting-place">
      <Place type="merging" status="inactive">
        <span className={style.score} />
        {car && <img className={style.car} src={`/models/cars/${car.car.model}Icon.png`} alt="car" />}
        {place.timer && <Timer timer={place.timer} startTime={place.startTime} endTimer={endTimer} />}
      </Place>
    </button>
  );
});
