//
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
//
// GENERATED USING @colyseus/schema 2.0.6
//

import { MapSchema, Schema, type } from '@colyseus/schema';
import { Bonus } from './Bonus';
import { Coin } from './Coin';
import { Player } from './Player';

export class EntityManager extends Schema {
  @type({ map: Player }) public players: MapSchema<Player> = new MapSchema<Player>();
  @type({ map: Coin }) public coins: MapSchema<Coin> = new MapSchema<Coin>();
  @type({ map: Bonus }) public bonuses: MapSchema<Bonus> = new MapSchema<Bonus>();
}
