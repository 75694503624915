import React from 'react';
import style from './style.module.scss';
import logo from '../../assets/images/garage/logo.png';

export function Loading() {
  return (
    <div className={style.container}>
      <div className={style.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={style.text}>
        Загрузка ...
      </div>
    </div>
  );
}
