import { observer } from 'mobx-react';
import { cloneElement, CSSProperties, ReactElement } from 'react';
import { gameStore } from '../../stores/gameStore';
import style from './style.module.sass';

interface IProps {
  value?: string | number;
  img?: ReactElement;
  size?: number;
}

export const Counter = observer((props: IProps) => {
  const { value = 0, img, size } = props ?? {};

  const dynamicStyles: CSSProperties = {
    fontSize: gameStore.moneyPickUp > 99 ? '18px' : '',
  };

  const imgStyle: CSSProperties = {
    height: size ? `${size}px` : 'auto',
  };

  return (
    <div className={style.container}>
      {img && cloneElement(img, { className: style.img, style: imgStyle })}
      <span className={style.text} style={dynamicStyles}>{`x${value}`}</span>
    </div>
  );
});
