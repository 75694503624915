import React from 'react';
import styles from './styles.module.sass';

interface IProps {
  text: string;
}

export function Praise({ text }: IProps) {
  return <span className={`${styles.text} ${styles.hide}`}>{text}</span>;
}
