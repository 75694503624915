export type CarList = {
  additionalSet: string[];
  buggy: string[];
  dragon: string[];
  food: string[];
  lego: string[];
  racingCars: string[];
  retro: string[];
  robot: string[];
  sea: string[];
  space: string[];
  sportCar: string[];
  [key: string]: string[];
};
export const carList: CarList = {
  additionalSet: ['batmobile', 'capybara', 'catmobile', 'duck', 'flintstones', 'roller'],
  buggy: ['buggy1', 'buggy2', 'buggy3', 'buggy4'],
  dragon: ['blueDragon', 'chineseDragon', 'greenDragon', 'strongDragon', 'twoHeadedDragon'],
  food: ['banana', 'burger', 'donut', 'hotDog', 'iceCream'],
  lego: ['legoCar', 'legoDinosaur', 'legoJeep', 'legoPlane', 'legoUnicorn'],
  racingCars: ['car1', 'car2', 'car3', 'car4', 'car5'],
  retro: ['retro1', 'retro2', 'retro3', 'retro4'],
  robot: ['robot1', 'robot2', 'robot3', 'robot4', 'robot5'],
  sea: ['crab', 'octopus', 'shark', 'ship', 'submarine'],
  space: ['fighter', 'fighterOp', 'planet', 'rocket', 'ufo'],
  sportCar: ['sport1', 'sport2', 'sport3', 'sport4', 'sport5', 'sport6'],
};
