import { CoordinateScheme } from '../../colyseus/schemes/CoordinateScheme';
import { Coordinate } from '../../types/points/coordinate';
import { Real } from '../../types/points/real';

export class ConvertorServer {
  static fromCoord(point: CoordinateScheme) {
    return new Coordinate(point.x, point.y);
  }

  static fromCoords(points: CoordinateScheme[]) {
    const coords: Coordinate[] = [];
    for (const point of points) {
      coords.push(new Coordinate(point.x, point.y));
    }
    return coords;
  }

  static fromReal(points: CoordinateScheme) {
    return new Real(points.x, points.y);
  }

  static fromReals(points: CoordinateScheme[]) {
    const reals: Real[] = [];
    for (const point of points) {
      reals.push(new Real(point.x, point.y));
    }
    return reals;
  }
}
