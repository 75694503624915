import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { CarIcon } from '../../../../../components/CarIcon';
import { Place, PlaceStatusType } from '../../../../../components/Place';
import { formatTime } from '../../../../../components/Timer';
import { useStore } from '../../../../../hooks/useStore';
import { ParkPlacesPlace } from '../../../../../stores/ParkingStore/types';
import { rootStore } from '../../../../../stores/RootStore';

import style from './style.module.scss';

export interface DropResult {
  place: ParkPlacesPlace;
}

export const BusyPlace = observer(({ place }: DropResult) => {
  const { carsStore, userStore, parkingStore, tutorialStore, getChargingCar } = useStore();
  const car = carsStore.cars.find((cycleCar) => cycleCar.id === place.carId);
  const refIndicatorFill = useRef<HTMLSpanElement>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [timeFuel, setTimeFuel] = useState(0);
  const noFull = car ? car.fuel < car.car.maxFuel : false;
  let status: PlaceStatusType = carsStore.selectedCar?.id === place.carId ? 'selected' : 'active';
  const currentLineChargeCar = getChargingCar.bind(rootStore)(place.idxLine);
  const isCharging = currentLineChargeCar?.carId === car?.id;
  status = (isCharging ? `${status} charging` : status) as PlaceStatusType;

  const indicatorClass = classNames(style.indicator, style.indicator_idle);

  const onClickPlace = () => {
    if (place.carId) carsStore.setSelectedCar(place.carId);
  };

  const [, drag, preview] = useDrag(() => ({
    type: 'ParkingPlace',
    item: place,
    // canDrag: () => !place.charging,
    canDrag: () => true,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (!dropResult || !item.carId) return;
      parkingStore
        .moveCar(
          userStore.user.id,
          item.carId,
          dropResult.place.type,
          dropResult.place.idx,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dropResult.place.idxLine ? dropResult.place.idxLine : 0,
        )
        .then(() => {
          if (tutorialStore.currentStepIdx === 6) {
            tutorialStore.nextStep();
          }
        });
    },
  }));
  preview(getEmptyImage());

  const [, drop] = useDrop(() => ({
    accept: 'RefuelingPlace',
    canDrop: () => !!car && car.fuel < car.car.maxFuel,
    drop: () => ({ place }),
  }));

  useEffect(() => {
    if (!car || !refIndicatorFill.current) return;
    const fill = Math.floor((car.fuel / car.car.maxFuel) * 100);
    const fillEl = refIndicatorFill.current;
    fillEl.style.width = `${fill}%`;
  }, [car, car?.fuel]);

  useEffect(() => {
    const { timer } = parkingStore.park[place.idxLine];

    if (!isCharging) return;
    if (!timer) return;

    if (interval.current) {
      clearInterval(interval.current);
    }
    setTimeFuel(Math.floor(timer / 1000) + 1);

    interval.current = setInterval(() => {
      setTimeFuel((prevSeconds) => {
        if (prevSeconds <= 1) {
          if (interval.current) {
            clearInterval(interval.current);
            interval.current = null;
          }
        }
        return prevSeconds - 1;
      });
    }, 1000);
  }, [place, parkingStore.park[place.idxLine].timer, carsStore.cars]);

  useEffect(() => () => {
    if (!interval.current) return;
    clearInterval(interval.current);
    interval.current = null;
  }, [place, parkingStore.park[place.idxLine].timer]);

  return (
    <Place
      id={`busyPlace-${place.idxLine}-${place.idx}`}
      type="parking"
      status={status}
      onClick={onClickPlace}
      drop={drop}
      dataTutorial={place.idxLine === 0 && place.idx === 0 ? 'park-busy-place' : ''}
    >
      {car && (
        <>
          <CarIcon type="parking" lvl={car.car.lvl} drag={drag} preview={preview} src={`/models/cars/${car.car.model}Icon.png`} />
          {noFull && (
            <div className={indicatorClass}>
              <span ref={refIndicatorFill} className={style.indicator_fill} />
              {/* <span className={style.indicator_text}>{car.fuel}</span> */}
              {isCharging && <span className={style.indicator_text}>{formatTime(timeFuel)}</span>}
            </div>
          )}
          {/* {place.carId && noFull && <span className={style.icon_charging} />} */}
        </>
      )}
    </Place>
  );
});
