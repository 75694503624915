import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CoinsCount } from '../../../../components/CoinsCount';
import { Place } from '../../../../components/Place';
import { useStore } from '../../../../hooks/useStore';
import amp from '../../../../services/amplitude/Amplitude';
import { FactoryPlaceProps } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export const OrderPlace = observer(({ place }: { place: FactoryPlaceProps }) => {
  const { userStore, parkingStore, tutorialStore } = useStore();
  const { user } = userStore;
  const navigate = useNavigate();

  const iconClass = classNames(style.icon, {
    [style.icon_inactive]: !(user.soft - place.priceCar >= 0),
  });

  const onCreate = () => {
    if (user.soft - place.priceCar < 0) {
      navigate('/shop');
      return;
    }

    parkingStore.createCar(userStore.user.id, place.idx).then(() => {
      if (tutorialStore.currentStepIdx === 1) {
        tutorialStore.nextStep();
      }
    });
    amp.gameFactoryStart();
  };

  return (
    <Place type="factory" onClick={onCreate} dataTutorial="factory-waiting-place">
      <span className={iconClass} />
      {place.priceCar && <CoinsCount count={place.priceCar} place="factory" />}
    </Place>
  );
});
