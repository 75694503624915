import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getSettings } from './api/getSettings';
import { AuthAdmin } from './blocks/gameSettings/AuthAdmin';
import { GameSettings } from './blocks/gameSettings/GameSettings';
import { storeSettings } from './blocks/gameSettings/storeSettings';
import { Congrats } from './features/Congrats';
import { DeleteCar } from './features/DeleteCar';
import { FAQ } from './features/FAQ';

import { Garage } from './features/Garage';
import { Loading } from './features/Loading';
import { NoFuel } from './features/NoFuel';
// import { Leagues } from './features/Leagues';
import { Rewards } from './features/Rewards';
import { Score } from './features/Score';
import { Settings } from './features/Settings';
import { Shop } from './features/Shop';
import { Tip } from './features/Tip';
import { Tutorial } from './features/Tutorial';
import { useStore } from './hooks/useStore';
import Game from './panels/Game';
import amp from './services/amplitude/Amplitude';
import gameSettings from './settings/gameSettings';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Garage />,
    children: [
      {
        path: 'score',
        element: <Score />,
      },
      // {
      //   path: 'leagues',
      //   element: <Leagues />,
      // },
      {
        path: 'rewards',
        element: <Rewards />,
      },
      {
        path: 'shop',
        element: <Shop />,
      },
      {
        path: 'congrats',
        element: <Congrats />,
      },
      {
        path: 'nofuel',
        element: <NoFuel />,
      },
      {
        path: 'tutorial',
        element: <Tutorial />,
      },
      {
        path: 'deleteCar',
        element: <DeleteCar />,
      },
      {
        path: 'faq',
        element: <FAQ />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'tip',
        element: <Tip />,
      },
    ],
  },
  {
    path: '/game',
    element: <Game id="game" />,
  },
  {
    path: '/authForAdminPanel',
    element: <AuthAdmin />,
  },
  {
    path: '/adminPanel',
    element: <GameSettings />,
  },
]);

const App = observer(() => {
  const [loaded, setLoad] = useState(false);
  const { userStore, carsStore, parkingStore, tutorialStore } = useStore();
  const { isLoading: user } = userStore;
  const { isLoading: car } = carsStore;
  const { isLoading: park } = parkingStore;

  useEffect(() => {
    setLoad(!user && !car && !park);
  }, [user, car, park]);

  useEffect(() => {
    amp.init();
    amp.appStart();
  }, []);

  useEffect(() => {
    console.log({ tutorStep: userStore.user.tutorStep });
    if (typeof userStore.user.tutorStep === 'number' && userStore.user.tutorStep < tutorialStore.steps.length) {
      tutorialStore.setStep(userStore.user.tutorStep);
    }
  }, [user]);

  getSettings()
    .then((settings) => {
      if (!settings) return;

      storeSettings.applyJson(settings);

      const isProd = process.env.NODE_ENV === 'production';
      const useLocalSetting = gameSettings.dev.USE_LOCAL_SETTING || settings.useLocalSetting;
      if (useLocalSetting && !isProd) {
        console.log('Используются локальные настройки');
        return;
      }

      gameSettings.START_SCENE = settings.startScene;

      gameSettings.grid.SIZE = settings.gridSize;

      gameSettings.entity.DEFAULT_MODEL = settings.defaultModel;
      gameSettings.entity.MOVEMENT_FREQUENCY = settings.movementFrequency;
      gameSettings.entity.PICKUP_RADIUS = settings.pickupRadius;
      gameSettings.entity.MOVE_SPEED = settings.moveSpeed;
      gameSettings.entity.bot.NUMBER = settings.botNumber;
      gameSettings.entity.player.NUMBER = settings.playerNumber;
      gameSettings.entity.player.POINT_TO_COIN = settings.pointToCoin;
      gameSettings.entity.player.POINT_TO_KILL = settings.pointToKill;

      gameSettings.game.CAMERA_RANGE = settings.cameraRange;
      gameSettings.game.GROUND_RADIUS = settings.groundRadius;
      gameSettings.game.MARGIN_FROM_THE_EDGE_OF_THE_MAP = settings.marginFromTheEdgeOfTheMap;
      gameSettings.game.SOUND = settings.volume;
      gameSettings.game.tail.THICKNESS = settings.thickness;
      gameSettings.game.zone.POLYGONS = settings.zonePolygons;
      gameSettings.game.zone.FREE_SPACE_MULTIPLIER = settings.zoneFreeSpaceMultiplier;
      gameSettings.game.zone.SIZE = settings.zoneSize;

      gameSettings.dev.DEV_MODE = settings.devMode;
      gameSettings.dev.BOT_MOVE = settings.botMove;
      console.log('Настройки с сервера успешно применены');
    })
    .catch((error) => {
      console.error(error);
    });

  return <div className="app">{loaded ? <RouterProvider router={router} /> : <Loading />}</div>;
});

export default App;
