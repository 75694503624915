import classNames from 'classnames';
import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Place } from '../../../../components/Place';

import { useStore } from '../../../../hooks/useStore';
import amp from '../../../../services/amplitude/Amplitude';
import { MergingPlaceProps, ParkPlacesPlace } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export interface DropResult {
  place: MergingPlaceProps | ParkPlacesPlace;
}

export function MergePlace({ place }: { place: MergingPlaceProps }) {
  const { parkingStore, carsStore, userStore, tutorialStore } = useStore();

  const carOne = carsStore.cars.find((c) => c.id === place.carIdOne);

  const [, drag, preview] = useDrag(() => ({
    type: 'MergingPlace',
    item: place,
    canDrag: () => true,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      // eslint-disable-next-line no-useless-return
      if (!dropResult || !item.carIdOne) return;

      parkingStore.moveCar(
        userStore.user.id,
        item.carIdOne,
        dropResult.place.type,
        dropResult.place.idx,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dropResult.place.idxLine ? dropResult.place.idxLine : 0,
      ).then((result) => {
        if (!result) return;
        console.log({ event: 'moveCar - mergePlace', result });
        tutorialStore.nextStep();
      });

      amp.gameMergeStart();
    },
  }));

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const [, drop] = useDrop(() => ({
    accept: ['FactoryPlace', 'MergingPlace', 'ParkingPlace'],
    // canDrop: (_, monitor) => {
    //   const dragPlace = monitor.getItem<FactoryPlace | MergingPlace>();
    //   return dragPlace.type !== place.type || dragPlace.idx !== place.idx;
    // },
    drop: () => ({ place }),
  }));

  const firstCarClasses = classNames(style.car, style.car_first);
  const secondCarClasses = classNames(style.car, style.car_second);

  return (
    <Place type="merging" dataTutorial="merging-merge-place">
      {carOne && (
        <div ref={(node) => drag(drop(node))}>
          <img className={firstCarClasses} src={`/models/cars/${carOne.car.model}Icon.png`} alt="car #1" />
          <img className={secondCarClasses} src={`/models/cars/${carOne.car.model}Icon.png`} alt="car #2" />
        </div>
      )}
    </Place>
  );
}
