import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import plusIcon from '../../../../../assets/images/factory/icon-plus.png';
import { CoinsCount } from '../../../../../components/CoinsCount';
// import { ParkingPlaceProps } from '../../ParkingPlaceProps';
import { Place } from '../../../../../components/Place';

import { useStore } from '../../../../../hooks/useStore';
import { ParkPlacesPlace } from '../../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export interface BuyPlaceProps {
  place: ParkPlacesPlace;
  topButtonIcon?: string | null;
}

export const BuyPlace = observer(({ place, topButtonIcon }: BuyPlaceProps) => {
  const { parkingStore, userStore } = useStore();
  const { user } = userStore;
  const navigate = useNavigate();
  const status = !place.isNoBuy && user.soft >= place.pricePlace ? 'active' : 'inactive';
  const size = place.statusPlace === 'waiting' ? 'small' : 'medium';
  const iconLockClass = classNames(style.icon, {
    [style.icon_noBuy]: status === 'inactive',
    [style.icon_small]: place.statusPlace === 'sale' && !place.isNoBuy,
    [style.icon_lock]: place.isNoBuy,
    [style.icon_small_noBuy]: !place.isNoBuy,
    [style.icon_big]: place.statusPlace === 'busy' && !place.isNoBuy,
    [style.icon_big_noBuy]: place.statusPlace === 'busy' && place.isNoBuy,
  });

  const onBuy = () => {
    if (user.soft - place.pricePlace < 0) {
      navigate('/shop');
      return;
    }

    parkingStore.buyPlace(userStore.user.id, place.type, place.idx, place.idxLine || 0);
  };

  const hundred = Math.floor(place.pricePlace % 1000);
  const isBig = place.pricePlace >= 1000;
  const smallOrXSmall = hundred !== 0 ? 'xSmall' : 'small';
  const iconSize = isBig ? smallOrXSmall : undefined;
  const fontSize = isBig && hundred !== 0 ? 'small' : undefined;

  return (
    <Place type="parking" status={status} size={size} topButtonIcon={topButtonIcon}>
      {place.statusPlace === 'waiting' ? (
        <CoinsCount count={place.pricePlace} fontSize="small" place="parking" />
      ) : (
        <>
          <span className={iconLockClass} />
          {!place.isNoBuy && (
            <>
              <CoinsCount count={place.pricePlace} fontSize={fontSize} place="parking" iconSize={iconSize} />
              <button type="button" className={style.plus} onClick={onBuy}>
                {place.pricePlace && <img src={plusIcon} alt="add place" />}
              </button>
            </>
          )}
        </>
      )}
    </Place>
  );
});
