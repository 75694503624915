import { observer } from 'mobx-react';
import React from 'react';
import Pointer from './pointer/pointer';
import { pointersStore } from './pointersStore';
import styles from './styles.module.sass';

const maxDistance = 30;

function calculateOpacity(distance: number): number {
  const minDistance = 10;
  const minOpacity = 0;
  const maxOpacity = 1;

  // Если расстояние меньше минимального значения, возвращаем 0.75
  if (distance < minDistance) {
    return maxOpacity;
  }

  // Если расстояние больше максимального значения, возвращаем 0
  if (distance > maxDistance) {
    return minOpacity;
  }

  // Ограничиваем расстояние допустимым диапазоном (от 5 до 15)
  const clampedDistance = Math.max(Math.min(distance, maxDistance), minDistance);
  // Вычисляем нормализованное расстояние в диапазоне (от 5 до 15)
  const normalizedDistance = (clampedDistance - minDistance) / (maxDistance - minDistance);
  // Возвращаем интерполированное значение от 0 до 0,75
  return +(maxOpacity - normalizedDistance * maxOpacity).toFixed(2);
}

export const Pointers = observer(() => {
  const arrows: React.JSX.Element[] = [];

  const entity = Array.from(pointersStore.entity.values());
  for (const entityElem of entity) {
    if (!entityElem.isAlive) continue;
    if (entityElem.distance > maxDistance && !entityElem.isKing) continue;
    const opacity = calculateOpacity(entityElem.distance);
    const colorName = `r:${entityElem.color.r} g:${entityElem.color.g} b:${entityElem.color.b}`;
    arrows.push(<Pointer key={colorName} color={entityElem.color} angle={entityElem.angle} opacity={opacity} isKing={entityElem.isKing} />);
  }

  return <div className={styles.main}>{arrows}</div>;
});
