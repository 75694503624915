import { Color3, Mesh, PolygonMeshBuilder, StandardMaterial, Texture, Vector2, Vector3 } from '@babylonjs/core';
import { createAlphaMaterial } from '../../materials';
import { BaseAgent } from './base';
import { ObjectToFollow } from './objectToFollow';

type PointsRoundedRectangle = { numPoint?: number; cornerSize?: number; straightLength?: number; startPoint?: Vector3 };
type RoundedRectangle = { url: string } & PointsRoundedRectangle;

export class Avatar extends ObjectToFollow {
  frame?: Mesh;

  create(owner: BaseAgent, url: string) {
    if (this.scene.isDisposed) return;

    const options: RoundedRectangle = {
      url,
      numPoint: 40,
      cornerSize: 0.01,
      straightLength: 0.3,
    };

    const plane = this.createRoundedRectangle(options);
    const car = owner.car.mesh;

    plane.setEnabled(true);
    plane.rotationQuaternion = null;
    plane.rotation.y = 0;
    plane.position = car.position.clone();
    plane.position.y += this.height;
    plane.billboardMode = Mesh.BILLBOARDMODE_ALL;

    this.mesh = plane;
    this.owner = owner;
  }

  getPointsRoundedRectangle(options: PointsRoundedRectangle) {
    const myPointsV2 = [];

    const {
      numPoint = 40,
      cornerSize = 0.05,
      straightLength = 1,
      startPoint = new Vector3(-(straightLength + cornerSize) / 2, 0, -(straightLength + cornerSize) / 2),
    } = options;

    const currentPosition = startPoint;

    // corner 0
    for (let i = 0; i <= numPoint; i++) {
      const angle = (i / numPoint) * Math.PI * 2;
      if (i % (numPoint / 4) === 0) {
        currentPosition.addInPlaceFromFloats(Math.cos(angle) * straightLength, 0, Math.sin(angle) * straightLength);
      } else {
        currentPosition.addInPlaceFromFloats(Math.cos(angle) * cornerSize, 0, Math.sin(angle) * cornerSize);
      }
      const clone = currentPosition.clone();
      const vector2 = new Vector2(clone.x, clone.z);
      myPointsV2.push(vector2);
    }

    return myPointsV2;
  }

  createRoundedRectangle(options: RoundedRectangle) {
    const { url = '' } = options ?? {};
    const myPointsV2 = this.getPointsRoundedRectangle(options);
    const plate = new PolygonMeshBuilder(`avatar${this.owner?.name}`, myPointsV2).build();
    plate.rotation.x = (Math.PI / 2) * 3;
    plate.rotation.y = (Math.PI / 2) * 3;
    const texture = new Texture(url);
    plate.material = createAlphaMaterial('avatarMaterial', texture);

    const frame = this.createFrame(options);
    frame.setParent(plate);
    frame.position.y -= 0.001;
    frame.rotation = Vector3.Zero();

    return plate;
  }

  createFrame(options: RoundedRectangle) {
    const { straightLength = 1 } = options ?? {};
    options.straightLength = straightLength * 1.05;
    const myPointsV2 = this.getPointsRoundedRectangle(options);
    const frame = new PolygonMeshBuilder('frame', myPointsV2).build();
    const material = new StandardMaterial('frame_mat');
    material.disableLighting = true;
    material.emissiveColor = Color3.Purple();
    frame.material = material;
    return frame;
  }
}
