type FontStyleType = {
  family: string;
  size: string;
  weight: number;
};

const fontStyleDefault: FontStyleType = {
  family: 'Arial',
  size: '12px',
  weight: 900,
};

let canvas: HTMLCanvasElement | null = null;

function getTextWidth(text: string, fontStyle = fontStyleDefault) {
  canvas = canvas || document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return 0;

  const { family, size, weight } = fontStyle;
  context.font = `${weight} ${size} ${family}`;

  return context.measureText(text).width;
}

function splitLabel(label: string, maxWidth: number) {
  const words = label.split(' ');
  const completedLines: string[] = [];
  let nextLine = '';

  words.forEach((word, idx) => {
    const wordLength = getTextWidth(`${word} `);
    const nextLineLength = getTextWidth(nextLine);
    if (nextLineLength + wordLength >= maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = [nextLine, word].filter(Boolean).join(' ');
    }
    const currentWord = idx + 1;
    const isLastWord = currentWord === words.length;
    if (isLastWord) {
      completedLines.push(nextLine);
    }
  });

  return completedLines.filter((line) => line !== '');
}

export { splitLabel };
