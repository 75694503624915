import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, Scene, Vector3 } from '@babylonjs/core';

export function rotationAnim(object: AbstractMesh, axis: 'y' | 'x' | 'z', speed: number, scene: Scene, callback?: () => void) {
  // Создаем анимацию вращения объекта вокруг оси
  const rotationAnimation = new BABYLON.Animation(
    'rotationAnimation',
    'rotation',
    30,
    BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
  );

  const keys = [];
  keys.push({
    frame: 0,
    value: object.rotation,
  });

  keys.push({
    frame: 100,
    value: new Vector3(object.rotation.x, 2 * Math.PI + object.rotation.y, object.rotation.z),
  });

  rotationAnimation.setKeys(keys);
  object.animations = [rotationAnimation];
  return scene.beginAnimation(object, 0, 100, true, speed, callback);
}
