// import { useStore } from '../../hooks/useStore';
import { CloseButton } from '../../components/CloseButton';
import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import BlockContent from './contents/blockContent';
// import { SpecialOffer } from './SpecialOffer';
import style from './style.module.scss';

// import { SpecialPack } from './SpecialPack';

export function FAQ() {
  // const { shopStore } = useStore();
  // const specialPacks = shopStore.specialPacks.map((pack) => <SpecialPack key={pack.id} pack={pack} />);
  const blocksList = [
    <BlockContent
      index={0}
      header="Как играть на Арене?"
      text="Выберите свой лучший автомобиль и нажмите на кнопку «Играть», чтобы начать гонку. Обойдите соперников, захватите максимум территории и станьте лидером на арене!"
    />,
    <BlockContent
      index={1}
      header="Можно ли играть с друзьями?"
      text="Да, можно! Поделитесь ссылкой на игру с друзьями или покажите свои достижения в истории, чтобы они тоже присоединились. Играйте вместе и получайте удовольствие от совместных заездов."
    />,
    <BlockContent
      index={2}
      header="Как получить новую машинку?"
      text="Чтобы получить новый автомобиль, активируйте завод в гараже, используя монеты. После завершения процесса производства вы получите новый автомобиль."
    />,
    <BlockContent
      index={3}
      header="Как поднять уровень машинки?"
      text="Чтобы повысить уровень автомобиля, соедините два одинаковых автомобиля в разделе Тюнинг. Это увеличит их характеристики и поможет зарабатывать больше монет."
    />,
    <BlockContent
      index={4}
      header="Закончилась энергия у машинки?"
      text="Если у вашего автомобиля закончилась энергия, оставьте его в гараже для восстановления или восполните энергию мгновенно, просмотрев рекламу."
    />,
    <BlockContent
      index={5}
      header="Как зарабатывать монеты?"
      text="Зарабатывайте монеты, захватывая территорию, побеждая соперников и собирая монеты на трассе. Также вы можете получать доход с парковочных мест в гараже."
    />,
    <BlockContent
      index={6}
      header="Как пополнить купюры?"
      text="Купюры — это ценная валюта в 'Цветном обгоне', которую можно приобрести в магазине игры. Используйте их для быстрого доступа к улучшениям"
    />,
    <BlockContent
      index={7}
      header="Предложения для игры"
      text="Если у вас есть идеи или предложения, как улучшить 'Цветной обгон', поделитесь ими в официальном сообществе игры. Мы ценим ваш вклад!"
    />,
  ];

  return (
    <ModalWindow>
      <div className={style.container}>
        <div className={style.close_container}>
          <CloseButton color="blue" />
        </div>

        <div className={style.header}>
          <StrokeText text="Часто задаваемые вопросы" maxWidth={800} style={style.text} />
        </div>

        <div className={style.content}>
          <div className={style.content_container}>{blocksList}</div>
        </div>
      </div>
    </ModalWindow>
  );
}
