import React from 'react';
import { gameStore } from '../../stores/gameStore';
import { DeathReasons } from '../../types/enums/deathReasons';
import style from './style.module.sass';

export function CauseDeath() {
  const { isKillSelf, causeOfDeath: cause, whoKilled: killer } = gameStore;

  switch (cause) {
    case DeathReasons.occupiedZone:
      return (
        <span className={style.text}>
          {'Ты погиб в зоне '}
          <span className={style.killerName}>{killer}</span>
        </span>
      );

    case DeathReasons.crossedTail:
      if (isKillSelf) {
        return <span className={style.text}>Ты пересек собственный шлейф</span>;
      }
      return (
        <span className={style.text}>
          {'Твой шлейф пересек '}
          <span className={style.killerName}>{killer}</span>
        </span>
      );

    case DeathReasons.noZone:
      if (isKillSelf) {
        return <span className={style.text}>От твоей зоны ничего не осталось</span>;
      }
      return (
        <span className={style.text}>
          {'Твою зону полностью захватил '}
          <span className={style.killerName}>{killer}</span>
        </span>
      );

    default:
      return <span className={style.text}>Ты погиб</span>;
  }
}
