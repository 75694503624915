import { Vector2, Vector3 } from '@babylonjs/core';
import { Real } from '../../types/points/real';
import { ConvertorVector } from './ConvertorVector';

export class ConvertorVectors {
  static toReal(vectors: Vector2[] | Vector3[]) {
    const reals: Real[] = [];
    for (const vector of vectors) {
      reals.push(ConvertorVector.toReal(vector));
    }
    return reals;
  }

  static toV2(vectors: Vector2[] | Vector3[]) {
    const vectors2: Vector2[] = [];
    for (const vector of vectors) {
      vectors2.push(ConvertorVector.toV2(vector));
    }
    return vectors2;
  }

  static toV3(vectors: Vector2[] | Vector3[], y = 0) {
    const vectors3: Vector3[] = [];
    for (const vector of vectors) {
      vectors3.push(ConvertorVector.toV3(vector, y));
    }
    return vectors3;
  }
}
