import { Vector2, Vector3 } from '@babylonjs/core';
import { Coordinate } from '../../types/points/coordinate';
import { Real } from '../../types/points/real';
import { BasePoint } from '../../types/points/basePoint';

export class ConvertorPoints {
  static toCoord(points: Real[]) {
    const coords: Coordinate[] = [];
    for (const point of points) {
      coords.push(point.toCoordinate());
    }
    return coords;
  }

  static toReal(points: Coordinate[]) {
    const reals: Real[] = [];
    for (const point of points) {
      reals.push(point.toReal());
    }
    return reals;
  }

  static toV2(points: BasePoint[]) {
    const vectors: Vector2[] = [];
    for (const point of points) {
      vectors.push(point.toV2());
    }
    return vectors;
  }

  static toV3(points: BasePoint[], y = 0) {
    const vectors: Vector3[] = [];
    for (const point of points) {
      vectors.push(point.toV3(y));
    }
    return vectors;
  }
}
