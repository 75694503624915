import { action, computed, makeObservable, observable } from 'mobx';
import backend from '../../services/backend';

import type { RootStore } from '../RootStore';

export class ScoreStore {
  private FACTORY_REWARDED_TIME_IN_SEC = 1800;
  private MERGING_REWARDED_TIME_IN_SEC = 1800;
  private FACTORY_HARD_PRICE = 0.17;
  private MERGING_HARD_PRICE = 0.17;

  public title = '';
  public placeIdx = -1;

  get sourceType() {
    if (this.title === 'Завод') return 'factory';
    if (this.title === 'Слияние') return 'merging';
    return null;
  }

  get adType() {
    if (this.title === 'Завод') return 'createSkip';
    if (this.title === 'Слияние') return 'mergeSkip';
    return null;
  }

  get isEnabledRewarded() {
    return !!this.rewardedTime && this.hardTime;
  }

  get isEnabledHard() {
    const { userStore } = this.rootStore;
    return this.hardTime && this.hardPrice && userStore.user.hard >= this.hardPrice;
  }

  get rewardedTime() {
    if (this.sourceType === 'factory') {
      return this.FACTORY_REWARDED_TIME_IN_SEC;
    }
    if (this.sourceType === 'merging') {
      return this.MERGING_REWARDED_TIME_IN_SEC;
    }

    return null;
  }

  get rewardedTimeFormat() {
    if (!this.rewardedTime) return '';
    return `-${this.timeToString(this.rewardedTime)}`;
  }

  get hardTime() {
    const nowTime = new Date().getTime();

    if (this.sourceType === 'factory') {
      const place = this.rootStore.parkingStore.factory[this.placeIdx];
      return place.timer - (nowTime - place.startTime) || null;
    }
    if (this.sourceType === 'merging') {
      const place = this.rootStore.parkingStore.merging[this.placeIdx];
      return place.timer - (nowTime - place.startTime) || null;
    }

    return null;
  }

  get hardTimeFormat() {
    if (!this.hardTime) return '';
    return `-${this.timeToString(this.hardTime / 1000)}`;
  }

  get hardPrice() {
    const minute = 1000 * 60;

    if (this.sourceType === 'factory') {
      return Math.ceil(((this.hardTime || 1) / minute) * this.FACTORY_HARD_PRICE);
    }

    if (this.sourceType === 'merging') {
      return Math.ceil(((this.hardTime || 1) / minute) * this.MERGING_HARD_PRICE);
    }

    return null;
  }

  constructor(private rootStore: RootStore) {
    makeObservable(this, {
      title: observable,
      placeIdx: observable,
      setTitle: action,
      setPlaceIdx: action,
      sourceType: computed,
      isEnabledRewarded: computed,
      isEnabledHard: computed,
      rewardedTime: computed,
      rewardedTimeFormat: computed,
      hardTime: computed,
      hardTimeFormat: computed,
      hardPrice: computed,
      decreaseCreatingTime: action,
    });
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public setPlaceIdx(placeIdx: number) {
    this.placeIdx = placeIdx;
  }

  public async scoreForHard() {
    const res = await backend.scoreForHard(
      this.rootStore.userStore.user.id,
      this.rootStore.parkingStore.parkId || '',
      this.sourceType || 'factory',
      this.placeIdx,
    );
    if (res) {
      this.rootStore.userStore.loadUserData();
      this.rootStore.parkingStore.loadParking(this.rootStore.userStore.user.id);
    }
    return res;
    // const { userStore } = this.rootStore;
    // userStore
    //   .payHard(this.hardPrice)
    //   .then(() => this.scoreForHardSuccess())
    //   .catch((e) => console.log(e.message));
  }

  scoreForHardSuccess() {
    // if (!this.hardTime) return;
    // this.decreaseCreatingTime(this.placeIdx, this.hardTime);
  }

  public async scoreForRewarded() {
    const res = await backend.scoreForRewarded(
      this.rootStore.userStore.user.id,
      this.rootStore.parkingStore.parkId || '',
      this.sourceType || 'factory',
      this.placeIdx,
    );
    if (res) {
      this.rootStore.parkingStore.loadParking(this.rootStore.userStore.user.id);
    }
    return res;
  }

  decreaseCreatingTime() {
    // const place = this.getPlace(placeIdx);
    // if (!place) return;
    // if (place.type !== 'Waiting') return;
    // if (this.sourceType === 'factory') {
    //   const { factoryStore } = this.rootStore;
    //   factoryStore.updateTimer(placeIdx, seconds);
    // }
    // if (this.sourceType === 'merging') {
    //   const { mergingStore } = this.rootStore;
    //   mergingStore.updateTimer(placeIdx, seconds);
    // }
  }

  private timeToString(timeInSec: number) {
    let hours = Math.floor(timeInSec / 3600);
    let minutes = Math.ceil((timeInSec - hours * 3600) / 60);

    if (minutes === 60) {
      hours += 1;
      minutes = 0;
    }

    if (hours > 0) {
      return `${hours}ч. ${minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 })}м.`;
    }
    return `${minutes}м.`;
  }

  // private getPlace(placeIdx: number) {
  //   if (this.sourceType === 'factory') {
  //     return this.rootStore.factoryStore.places[placeIdx];
  //   }
  //   if (this.sourceType === 'merging') {
  //     return this.rootStore.mergingStore.places[placeIdx];
  //   }
  //   return null;
  // }

  private calcHardPrice(lastTimeInSec: number) {
    const lastMinutes = Math.ceil(lastTimeInSec / 60);
    let price = 1;

    if (lastMinutes < 11) price = 1;
    else if (lastMinutes < 31) price = 0.9;
    else if (lastMinutes < 121) price = 0.8;
    else if (lastMinutes < 301) price = 0.7;
    else if (lastMinutes < 501) price = 0.6;
    else if (lastMinutes < 601) price = 0.55;

    return Math.ceil(lastMinutes * price);
  }
}
