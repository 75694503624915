import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import { SimplePack } from './SimplePack/SimplePack';

import style from './style.module.scss';

export const SimplePacks = observer(() => {
  const { shopStore } = useStore();

  return (
    <div className={style.container}>
      {shopStore.packs.map((pack) => (
        <SimplePack key={pack.item_id} pack={pack} />
      ))}
    </div>
  );
});
