import { Engine, MeshBuilder, Scene, ScenePerformancePriority, Vector3 } from '@babylonjs/core';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import { DevGUI } from '../../../gui';
import { createBaseElements } from '../../../meshs';
import { Bucks } from '../../../meshs/environment/bonuses/bucks';
import { gameStore } from '../../../stores/gameStore';
import { Real } from '../../../types';

export const BucksTest = async (
  scene: Scene,
) => {
  if (Engine.audioEngine) {
    Engine.audioEngine.useCustomUnlockedButton = true;
  }

  gameStore.createGui();
  scene.performancePriority = ScenePerformancePriority.Intermediate;
  scene.autoClear = false;
  scene.autoClearDepthAndStencil = false;

  const allowedCar = ['banana', 'robot1'];

  await createBaseElements(scene, allowedCar, { attachControl: true });
  new DevGUI(scene);

  const cash = new Bucks({ id: '1', position: new Real(0, 0) });

  const box = MeshBuilder.CreateBox('asd');
  box.position = new Vector3(5, 5, 10);
  cash.runMagnetism(box);

  MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);
};
