import React from 'react';
import style from './style.module.sass';

interface IProps {
  name: string;
  children: React.ReactNode;
}

export function Block(props: IProps) {
  const { name, children } = props;

  // Создать редактируемое поле

  return (
    <div className={style.main}>
      <span className={style.title}>{name}</span>
      <div className={style.childrenContainer}>{children}</div>
    </div>
  );
}
