import { AbstractMesh, Mesh, Scene, Vector2, Vector3 } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import { resizeAnim } from '../../animations';
import { Convertors, getSize } from '../../helpers';

export class DisposeEffect {
  public mesh: AbstractMesh | undefined;

  constructor(name: string, fileName: string, position: Vector2 | Vector3, scene: Scene) {
    const planeOriginal = scene.getMeshByName(`disposeEffect${fileName}`) as Nullable<Mesh>;
    if (!planeOriginal) return;

    const planeInstance = planeOriginal.clone(name);
    planeInstance.setEnabled(true);
    planeInstance.position = Convertors.vector.toV3(position);
    planeInstance.position.y = getSize(planeInstance).y / 2;
    planeInstance.isPickable = false;
    this.mesh = planeInstance;

    resizeAnim(planeInstance, 0, 1.5, 6, scene, () => {
      planeInstance.dispose(true, false);
    });
  }
}
