import { AbstractMesh, InstancedMesh, Mesh, MeshBuilder, Quaternion, Tags, Vector3 } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import { Convertors, getSize } from '../../helpers';
import { charactersList } from '../../meshs';
import { Real } from '../../types';

interface IParams {
  modelName: string;
  ownerName: string;
}

export class Car {
  mesh!: Mesh;
  modelMesh!: InstancedMesh;
  characterMesh!: Mesh;
  shadowMesh!: InstancedMesh;
  ownerName: string;
  modelName: string;

  size = 0.5;
  depth = 1;

  constructor({ modelName, ownerName }: IParams) {
    this.modelName = modelName;
    this.ownerName = ownerName;
  }

  createCar(startPos: Real) {
    this.createBasicMesh(startPos);
    this.createModel();
    this.createShadow();

    for (const mesh of [this.mesh, this.modelMesh, this.characterMesh, this.shadowMesh]) {
      mesh.alwaysSelectAsActiveMesh = true;
      mesh.isPickable = false;
      mesh.doNotSyncBoundingInfo = true;
      mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    }
  }

  createBasicMesh(startPos: Real) {
    this.mesh = MeshBuilder.CreateBox(this.ownerName, { size: this.size, depth: this.depth });
    this.mesh.position = startPos.toV3();
    this.mesh.position.y = getSize(this.mesh).y / 2;

    this.mesh.isPickable = false;
    this.mesh.checkCollisions = false;
    this.mesh.alwaysSelectAsActiveMesh = true;
  }

  createModel() {
    const originalCar = this.mesh.getScene().getMeshByName(`${this.modelName}Base`) as Nullable<Mesh>;
    if (!originalCar) {
      console.error(`Невозможно найти модель машины - ${this.modelName}`);
      return;
    }

    const carModel = originalCar.createInstance(`carModel${this.ownerName}`);
    carModel.setEnabled(true);
    carModel.position = Vector3.Zero();
    const carModelSize = getSize(this.mesh);
    carModel.position.y -= carModelSize.y / 2;
    carModel.parent = this.mesh;
    carModel.name = `${this.ownerName}model`;

    const characterFileName = charactersList[0];
    const originalCharacter = this.mesh.getScene().getMeshByName(`${characterFileName}Base`) as Nullable<Mesh>;
    if (!originalCharacter) {
      console.error('Невозможно найти такого персонажа');
      return;
    }

    const characterModel = originalCharacter.clone(`characterModel${this.ownerName}`);
    characterModel.setEnabled(true);
    characterModel.position = Vector3.Zero();
    characterModel.position.y = carModel.position.y;
    characterModel.parent = this.mesh;
    characterModel.name = `${this.ownerName}CharacterModel`;

    const axis = new Vector3(0, 1, 0);
    const angle = 0;
    // Применение кватерниона к объекту
    carModel.rotationQuaternion = Quaternion.RotationAxis(axis, angle);
    characterModel.rotationQuaternion = Quaternion.RotationAxis(axis, angle);

    Tags.AddTagsTo(carModel, 'carModel');
    Tags.AddTagsTo(characterModel, 'characterModel');
    this.mesh.isVisible = false;

    this.modelMesh = carModel;
    this.characterMesh = (characterModel.getChildren()[0] as Mesh).getChildren().find((node) => node instanceof Mesh) as Mesh;
  }

  delete() {
    this.mesh.dispose();
  }

  getPos() {
    return Convertors.vector.toReal(this.mesh.position);
  }

  createShadow() {
    const originShadow = this.mesh.getScene().getMeshByName('baseCarShadow') as Nullable<Mesh>;
    if (!originShadow) {
      throw Error('Невозможно найти тень');
    }

    const shadow = originShadow.createInstance(`shadow${this.ownerName}`);
    shadow.setEnabled(true);
    shadow.parent = this.mesh;
    shadow.position.y = -getSize(this.mesh).y / 4;
    this.shadowMesh = shadow;
  }

  hide() {
    this.characterMesh.isVisible = false;
    this.modelMesh.isVisible = false;
    this.shadowMesh.isVisible = false;
  }

  show() {
    this.characterMesh.isVisible = true;
    this.modelMesh.isVisible = true;
    this.shadowMesh.isVisible = true;
  }
}
