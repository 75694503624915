import { useEffect, useState, useRef } from 'react';
import style from './style.module.scss';

export interface TimerProps {
  startTime: number;
  timer: number;
  endTimer?: () => void;
}

export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  if (seconds >= 3600) {
    return `${hours}ч ${minutes}м`;
  }
  if (seconds >= 60) {
    return `${minutes}м ${remainingSeconds}c`;
  }
  return `${remainingSeconds} сек`;
}

export function Timer({ startTime, timer, endTimer }: TimerProps) {
  const [seconds, setSeconds] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    let time = 0;
    const start = () => {
      setSeconds(Math.floor((timer - (new Date().getTime() - startTime)) / 1000) + 1);
      interval.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds <= 1) {
            if (endTimer) {
              endTimer();
            }
            clearInterval(interval.current || 0);
            interval.current = null;
          }
          return prevSeconds - 1;
        });
      }, 1000);
      time = timer;
    };

    if (!interval) {
      start();
    } else if (time !== timer) {
      clearInterval(interval.current || 0);
      interval.current = null;
      start();
    }

    return () => {
      clearInterval(interval.current || 0);
      interval.current = null;
    };
  }, [timer, startTime]);

  return (
    <div className={style.timer}>
      <span className={style.timer_text}>{formatTime(seconds)}</span>
    </div>
  );
}
