import React from 'react';
import Bucks from '../../../../../assets/images/common/icon-cash.png';
import gameSettings from '../../../../../settings/gameSettings';
import { gameStore } from '../../../../stores/gameStore';

import { ReactComponent as Coin } from '../../../svg/coin.svg';
import { ReactComponent as Flag } from '../../../svg/flag.svg';
import { ReactComponent as Car } from '../../../svg/greenCar.svg';
import { ReactComponent as MountainCoins } from '../../../svg/mountainСoins.svg';
import { ReactComponent as PickedUp } from '../../../svg/pickUpCoin.svg';
import { ReactComponent as Skull } from '../../../svg/skull.svg';

import style from './style.module.sass';

export function CountCoins() {
  const moneyForZone = Math.floor(gameStore.territoriesCaptured / 10) * 10;
  const moneyForCoin = gameStore.moneyPickUp * gameSettings.entity.player.POINT_TO_COIN;
  const moneyForKll = gameStore.killCounter * gameSettings.entity.player.POINT_TO_KILL;
  const sumMoney = moneyForZone + moneyForCoin + moneyForKll;
  const totalMoney = Math.round(sumMoney + sumMoney * (gameStore.level / 100));

  return (
    <>
      <div className={style.countCoins}>
        <div className={style.point}>
          <PickedUp className={style.icon} />
          <span className={style.text}>{`подобрано : ${moneyForCoin}`}</span>
          <Coin className={style.coin} />
        </div>
        <div className={style.point}>
          <Flag className={style.icon} />
          <span className={style.text}>{`окрашено ${gameStore.territoriesCaptured}% : ${moneyForZone}`}</span>
          <Coin className={style.coin} />
        </div>
        <div className={style.point}>
          <Skull className={style.icon} />
          <span className={style.text}>{`x${gameStore.killCounter} : ${moneyForKll}`}</span>
          <Coin className={style.coin} />
        </div>
        <div className={style.point}>
          <Car className={style.icon} />
          <span className={style.text}>{`бонус от машинки : ${gameStore.level} %`}</span>
        </div>
        <div className={style.point}>
          <img src={Bucks} alt="bucks" className={style.icon} />
          <span className={style.text}>{`баксов подобрано : ${gameStore.bucksPickUp}`}</span>
        </div>
      </div>
      <div className={style.total}>
        <span className={style.text}>Итого заработанно:</span>
        <div className={style.num}>
          <span>{totalMoney}</span>
          <MountainCoins className={style.img} />
        </div>
      </div>
    </>
  );
}
