import { Mesh, Scene, Vector2, Vector3 } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import { smackAnim } from '../../animations';
import { Convertors } from '../../helpers';

export class CrashSprite {
  constructor(name: string, urlTexture: string, position: Vector2 | Vector3, scene: Scene) {
    const planeOriginal = scene.getMeshByName(`crushSprite${urlTexture}`) as Nullable<Mesh>;
    if (!planeOriginal) return;

    const plane = planeOriginal.createInstance(name);
    plane.position = Convertors.vector.toV3(position);
    plane.position.y = 0.005;
    plane.isPickable = false;

    smackAnim(plane, 10 * scene.getAnimationRatio(), scene, () => {
      setTimeout(() => {
        plane.dispose();
      }, 3000);
    });
  }
}
