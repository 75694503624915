import { motion, useMotionValue } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { ShareButton } from '../../components/ShareButton';
import carWithTrashBin from '../../assets/images/garage/carWithTrashbin.png';
import { CloseButton } from '../../components/CloseButton';

import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import { useStore } from '../../hooks/useStore';
import amp from '../../services/amplitude/Amplitude';
import backend from '../../services/backend';
import style from './style.module.scss';

export function DeleteCar() {
  const [open, setOpen] = useState(false);
  const opacity = useMotionValue(0);
  const navigate = useNavigate();
  const { carsStore, userStore, parkingStore } = useStore();
  opacity.on('animationComplete', () => {
    setOpen(true);
  });

  const onCancel = () => {
    navigate('/');
  };

  const onDelete = async () => {
    const { selectedCar } = carsStore;
    const { user } = userStore;
    if (!selectedCar) return;
    await backend.deleteCar(user.id, selectedCar?.id);

    const place = parkingStore.getPlaceByIdCar(selectedCar.id);
    if (place) amp.carRemoved(selectedCar.car.name, selectedCar.car.lvl, place);

    carsStore.setSelectedCar(null);
    await parkingStore.loadParking(user.id);
    navigate('/');
  };

  return (
    <ModalWindow>
      <div className={style.container_top}>
        <motion.div
          className={style.container}
          style={{ opacity }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
        >
          {open && (
            <>
              <div className={style.close_container}>
                <CloseButton color="purple" path="/" />
              </div>
              <header className={style.header}>
                <StrokeText style={style.header__title} maxWidth={200} text="Удалить машинку?" />
              </header>
              <div className="fuel">
                <img src={carWithTrashBin} alt="Delete car?" />
              </div>
              <div className={style.buttons}>
                <button type="button" onClick={onDelete} className={style.delete}><StrokeText style={style.text} maxWidth={200} text="Удалить" /></button>
                <button type="button" onClick={onCancel} className={style.cancel}><StrokeText style={style.text} maxWidth={200} text="Отмена" /></button>
              </div>
            </>
          )}
        </motion.div>
      </div>
    </ModalWindow>
  );
}
