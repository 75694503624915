import { makeAutoObservable } from 'mobx';
import backend from '../../services/backend';
import { RootStore } from '../RootStore';

import type { Car } from './Car';

export class CarsStore {
  public cars: Car[] = [];
  public selectedCar?: Car;
  public isLoading = true;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      getMaxTerritory: false,
      getCar: false,
    });
  }

  public async loadCarsByUser(userId: string): Promise<void> {
    return backend.loadCarsByUser(userId).then(this.loadCarsByUserSuccess);
  }

  loadCarsByUserSuccess = (cars: Car[]) => {
    this.cars = cars;
    this.isLoading = false;
    return Promise.resolve();
  };

  public setSelectedCar(carId: string | 'anything' | null) {
    if (carId === null) {
      this.selectedCar = undefined;
      return;
    }

    if (carId === 'anything') {
      const findPlaceInFactory = this.rootStore.parkingStore.factory.find((place) => place.carId && place.statusPlace === 'created');
      if (findPlaceInFactory) {
        this.selectedCar = this.cars.find((car) => car.id === findPlaceInFactory.carId);
        return;
      }

      const findPlaceInMerging = this.rootStore.parkingStore.merging.find((place) => place.carId && place.statusPlace === 'merged');
      if (findPlaceInMerging) {
        this.selectedCar = this.cars.find((car) => car.id === findPlaceInMerging.carId);
        return;
      }

      for (const line of this.rootStore.parkingStore.park) {
        const findPlaceInPark = line.places.find((place) => place.carId);
        if (findPlaceInPark) {
          this.selectedCar = this.cars.find((car) => car.id === findPlaceInPark.carId);
          return;
        }
      }

      return;
    }

    this.selectedCar = this.cars.find((car) => car.id === carId);
  }

  public async getMaxTerritory() {
    const result = { fuel: 0, territory: 0 };
    if (!this.selectedCar) return result;

    result.territory = await backend.getMaxTerritory(this.rootStore.userStore.user.id);
    result.fuel = this.cars.find((findCar) => findCar.id === this.selectedCar?.id)?.fuel ?? 0;
    return result;
  }

  public getCar(id: string) {
    return this.cars.find((car) => car.id === id);
  }
}
