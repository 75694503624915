import { Color3, Mesh, MeshBuilder, StandardMaterial } from '@babylonjs/core';
import gameSettings from '../../../settings/gameSettings';
import { ConvertorPoints, getDistance } from '../../helpers';
import { Real } from '../../types/points/real';

interface INewTail {
  name: string;
  color: Color3;
  points?: Real[];
  lift?: number;
}

export class Tail {
  points: Real[] = [];
  additionalPoints: Real[] = [];
  readonly name: string;
  readonly color: Color3;
  isActive = false;
  isCanDraw = true;

  mesh?: Mesh;
  material: StandardMaterial;
  lift = 0;
  thickness = gameSettings.game.tail.THICKNESS;

  constructor({ name, points, color, lift }: INewTail) {
    this.name = name;
    this.color = color;
    this.lift = lift ?? 0;

    this.material = new StandardMaterial('tailMat');
    this.material.diffuseColor = color;
    this.material.specularColor = color;
    this.material.emissiveColor = color;
    this.material.disableLighting = true;
    this.material.freeze();

    if (points) {
      this.isActive = true;
      this.addPoints(points);
    }
  }

  public addPoints(newPoints: Real[]) {
    if (!newPoints.length) return;
    this.isActive = true;

    const { length } = this.points;
    if (length === 0) {
      this.points = newPoints;
    } else {
      if (length < 2) {
        this.points.push(newPoints[0]);
        newPoints.unshift();
      }

      for (const point of newPoints) {
        if (this.isPointTooClose(point, this.points[this.points.length - 2], 0.25)) {
          this.points[length - 1] = point;
        } else {
          this.points.push(point);
        }
      }
    }

    if (this.points.length > 1) {
      this.draw();
    }
  }

  public draw() {
    if (this.points.length < 2) return;
    const convertPoints = ConvertorPoints.toV3([...this.additionalPoints, ...this.points]);
    if (this.mesh && !this.mesh.isDisposed()) this.mesh.dispose();
    this.mesh = MeshBuilder.CreateTube(this.name, { path: convertPoints, radius: this.thickness, sideOrientation: 1, tessellation: 2 });

    this.mesh.material = this.material;
    this.mesh.position.y = this.lift;
  }

  public delete() {
    this.points = [];
    this.additionalPoints = [];
    this.isActive = false;
    this.mesh?.dispose();
  }

  private isPointTooClose(point: Real, lastPoint: Real, minDistance: number) {
    const distance = getDistance(lastPoint, point);
    return distance < minDistance;
  }

  hide() {
    this.isCanDraw = false;
    this.mesh?.dispose();
  }

  show() {
    this.isCanDraw = true;
    this.draw();
  }

  setLift(lift: number) {
    this.lift = lift;
    if (!this.mesh) return;
    this.mesh.position.y = lift;
  }
}
