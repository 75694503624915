import { action, makeObservable, observable } from 'mobx';
import managerApi, { IManagerUser } from '../../api/managerApi';
import backend from '../../services/backend';
import { RootStore } from '../RootStore';

import type { User } from './User';

export class UserStore {
  public user: User = {
    id: '',
    nickname: '',
    hard: 0,
    soft: 0,
    tutorStep: undefined,
  };

  public userPlatformData: IManagerUser | null = null;
  public isLoading = true;
  public viewAd: { [key: string]: number } = {
    addFuel: 3,
    createSkip: 3,
    mergeSkip: 3,
  };

  constructor(private rootStore: RootStore) {
    makeObservable(this, {
      user: observable,
      isLoading: observable,
      loadUserData: action,
      loadUserDataSuccess: action,
      payHard: action,
      paySoft: action,
    });
  }

  public async loadUserData(): Promise<void> {
    this.userPlatformData = await managerApi.getUserData();
    return backend.loadUserData(this.userPlatformData.id).then(this.loadUserDataSuccess);
  }

  loadUserDataSuccess = (user: User | null) => {
    if (!user) return Promise.reject();

    this.user = user;
    this.user.fullname = `${user.firstName} ${user.lastName}`;
    this.isLoading = false;
    this.loadViewAd();
    // this.rootStore.tutorialStore.setStep(user.tutorStep ?? this.rootStore.tutorialStore.currentStepIdx);
    return Promise.resolve();
  };

  public loadViewAd() {
    backend.getAdForUser(this.user.id).then((res: { type: string; views: number }[]) => {
      const temp: { [key: string]: number } = { addFuel: 3, createSkip: 3, mergeSkip: 3 };
      Object.keys(this.viewAd).forEach((adType) => {
        const view = res.find((r) => r.type === adType);
        if (view) {
          temp[adType] -= view.views;
        }
      });
      this.viewAd = temp;
    });
  }

  createUser = async () => {
    if (this.userPlatformData) {
      await backend
        .createUser({
          vkId: this.userPlatformData.id,
          firstname: this.userPlatformData.firstName,
          lastname: this.userPlatformData.lastName,
          nickname: `${this.userPlatformData.firstName} ${this.userPlatformData.lastName}`,
        })
        .then(this.loadUserDataSuccess);
    } else {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  public async createViewAd(typeAd: 'addFuel' | 'createSkip' | 'mergeSkip'): Promise<string | null> {
    const res = await backend.createAdForUser(this.user.id, typeAd);
    if (res.status) {
      return res.idAd;
    }
    return null;
  }

  public async checkAd(adId: string, options: any): Promise<boolean> {
    const res = await backend.checkAdForUser(this.user.id, adId, options);
    return res;
  }

  public async payHard(count: number): Promise<void> {
    if (this.user.hard > count) {
      this.user.hard -= count;
      return;
    }
    throw Error('The user has not enough hard!');
  }

  public async paySoft(count: number): Promise<void> {
    if (this.user.soft > count) {
      this.user.soft -= count;
      return;
    }
    throw Error('The user has not enough soft!');
  }
}
