import * as BABYLON from '@babylonjs/core';
import { Mesh } from '@babylonjs/core';

export async function importModel(nameFile: string, rootUrl: string, meshName: string) {
  const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', rootUrl, `${nameFile}.glb`);

  meshes[0].name = meshName;
  meshes[0].id = meshName;
  BABYLON.Tags.AddTagsTo(meshes[0], meshName);
  return meshes[0] as Mesh;
}
