import bridge, { EAdsFormats } from '@vkontakte/vk-bridge';

class VkManager {
  init() {
    return bridge.send('VKWebAppInit');
  }

  getUserInfo() {
    return bridge.send('VKWebAppGetUserInfo').then((data: any) => ({
      firstName: data.first_name,
      lastName: data.last_name,
      avatar: data.photo_200,
      id: String(data.id),
    }));
  }

  prepareAd() {
    return bridge.send('VKWebAppCheckNativeAds', { ad_format: EAdsFormats.REWARD });
  }

  showAd() {
    return bridge.send('VKWebAppShowNativeAds', { ad_format: EAdsFormats.REWARD });
  }

  showStory(image: string) {
    return bridge.send('VKWebAppShowStoryBox', {
      background_type: 'image',
      blob: image,
      locked: true,
      attachment: {
        type: 'url',
        text: 'game',
        url: 'https://vk.com/app51820975_3922194',
      },
    });
  }

  async showShoppingWindow(id: string) {
    const result = await bridge.send('VKWebAppShowOrderBox', { type: 'item', item: id });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { result: result.success };
  }
}
const vkManager = new VkManager();

export default vkManager;
