//
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
//
// GENERATED USING @colyseus/schema 2.0.6
//

import { ArraySchema, Schema, type } from '@colyseus/schema';
import { CoordinateScheme } from './CoordinateScheme';

export class Zone extends Schema {
  @type([CoordinateScheme]) public points: ArraySchema<CoordinateScheme> = new ArraySchema<CoordinateScheme>();
}
