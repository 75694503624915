export function advancedSplice(array: any[], start: number, deleteCount: number, ...items: any[]): any[] {
  let allDeleteElements: any[];

  if (deleteCount >= 0) {
    const deleteElements = array.splice(start, deleteCount, ...items.flat());
    allDeleteElements = deleteElements;
    allDeleteElements.push(...array.splice(0, deleteCount - deleteElements.length));
  } else {
    allDeleteElements = advancedSplice(array, start + deleteCount, Math.abs(deleteCount), ...items);
    allDeleteElements = allDeleteElements.flat();
  }

  return allDeleteElements;
}
