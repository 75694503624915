import { Mesh } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import { BaseAgent } from './base';
import { ObjectToFollow } from './objectToFollow';

export class Crown extends ObjectToFollow {
  create(owner: BaseAgent) {
    if (this.scene.isDisposed) return;
    const crownOriginal = this.scene.getMeshByName('CrownBase') as Nullable<Mesh>;
    if (!crownOriginal) return;

    const crown = crownOriginal.clone(`Crown${owner.name}`);
    const car = owner.car.mesh;

    crown.setEnabled(true);
    crown.rotationQuaternion = null;
    crown.rotation.y = 0;
    crown.position = car.position.clone();
    crown.position.y += this.height;

    this.mesh = crown;
    this.owner = owner;
  }
}
