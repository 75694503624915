import { Engine, Scene } from '@babylonjs/core';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import amp from '../../../services/amplitude/Amplitude';
import gameSettings from '../../../settings/gameSettings';
import { ColyseusClient } from '../../colyseus';
import { CustomLoadingScreen } from '../../components';
import { DevGUI } from '../../gui';
import { AdController, keyPressListener } from '../../helpers';
import { createBaseElements } from '../../meshs';
import { gameStore } from '../../stores/gameStore';

export const GameScene = async (scene: Scene) => {
  const ad = new AdController();
  ad.load();

  gameStore.setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

  const engine = scene.getEngine();
  gameStore.setShowDeadMenu(false);
  if (Engine.audioEngine) {
    Engine.audioEngine.useCustomUnlockedButton = true;
  }
  engine.loadingScreen = new CustomLoadingScreen();
  engine.displayLoadingUI();

  gameStore.createGui();
  scene.skipPointerMovePicking = true;

  const allowedCar = ['retro1', gameStore.model];

  const { camera } = await createBaseElements(scene, allowedCar);
  if (gameSettings.dev.DEV_MODE) {
    new DevGUI(scene, { createPingCounter: true });
  }

  const colyseus = new ColyseusClient(scene, 'my_room');

  colyseus.manager.onCreateCurPlayerObservable.addOnce((player) => {
    gameStore.setRespawnCallback(() => {
      ad.show(() => {
        amp.adsShowed('revive');
        gameStore.room.send('respawnPlayer');
        gameStore.setShowDeadMenu(false);
        gameStore.setCanUseJoystick(true);
        gameStore.setPreloadAd(false);
        window.focus();
      });
    });

    player.onDeathObservable.add(() => {
      gameStore.setShowDeadMenu(true);
      gameStore.setCanUseJoystick(false);
      gameStore.setPressedJoystick(false);
      gameStore.setTerritoriesCaptured(player.zone.getPercentageArea(true));
    });
  });

  try {
    await colyseus.initialization();
  } catch (e) {
    console.warn(`Инициализировать сервер не удалось: ${e}`);
  }

  if (process.env.NODE_ENV === 'development') {
    const removeListenerA = keyPressListener('KeyA', () => {
      camera.radius -= 1;
      gameStore.artificialZoom = true;
    });

    const removeListenerS = keyPressListener('KeyS', () => {
      camera.radius += 1;
      gameStore.artificialZoom = true;
    });

    const removeListenerR = keyPressListener('KeyR', () => {
      camera.radius = 10;
      gameStore.artificialZoom = false;
    });

    scene.onDisposeObservable.addOnce(() => {
      removeListenerA();
      removeListenerS();
      removeListenerR();
      window.onblur = () => undefined;
      window.onfocus = () => undefined;
    });
  }

  engine.hideLoadingUI();
};
