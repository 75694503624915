import { Color3, Engine, MeshBuilder, Scene, ScenePerformancePriority } from '@babylonjs/core';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import { DevGUI } from '../../../gui';
import { createBaseElements } from '../../../meshs';
import { ManagerMP } from '../../../multiplayer/manager/manager';
import { gameStore } from '../../../stores/gameStore';
import { Real } from '../../../types';

export const GapsFix = async (
  scene: Scene,
) => {
  if (Engine.audioEngine) {
    Engine.audioEngine.useCustomUnlockedButton = true;
  }

  gameStore.createGui();
  scene.performancePriority = ScenePerformancePriority.Intermediate;
  scene.autoClear = false;
  scene.autoClearDepthAndStencil = false;

  await createBaseElements(scene, [], { attachControl: true });
  new DevGUI(scene);

  const manager = new ManagerMP(scene);

  const startPos = new Real(0, 0);
  const zonePoints = [
    new Real(2, 2),
    new Real(2, -2),
    new Real(-2, -2),
    new Real(-2, 2),
  ];

  const params = {
    color: Color3.Red(),
    id: '1',
    model: 'robot1',
    photo: '',
    startPos,
    tailPoints: [],
    zonePoints,
  };

  manager.createPlayer(params);

  MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene);
};
