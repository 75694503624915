import { AbstractMesh, InstancedMesh, Mesh, Observable, Scene, Tags } from '@babylonjs/core';
import { Nullable } from '@babylonjs/core/types';
import { onCurveAnim, rotationAnim, shadowMoveAnim } from '../../animations';
import { Convertors, getDistance, getRandomFloat, getSize } from '../../helpers';
import { calculateSpeed } from '../../meshs/environment/bonuses/pickupItem';
import { Real } from '../../types';

interface ICoinCreate {
  id: string;
  scene: Scene;
  startPoint: Real;
  endPoint: Real;
}

export class Coin {
  mesh: InstancedMesh;
  shadowMesh: InstancedMesh;
  owner?: Mesh;
  speed = 0.03;
  function = () => {};

  onDelete = new Observable<Coin>();
  onRunMagnetism = new Observable<Coin>();
  onReadyPickable = new Observable<Coin>();

  constructor({ id, scene, startPoint, endPoint }: ICoinCreate) {
    const originalCoin = scene.getMeshByName('coinBase') as Nullable<Mesh>;
    if (originalCoin === null) {
      throw new Error('Coin base not found');
    }
    this.mesh = originalCoin.createInstance(`coin${id}`);
    this.mesh.setEnabled(true);

    this.mesh.rotationQuaternion = null;
    this.mesh.rotation.x = 0;
    this.mesh.rotation.y = getRandomFloat(0, Math.PI * 2, 6);
    const height = getSize(this.mesh).y;
    this.mesh.position.y = height / 2;

    Tags.AddTagsTo(this.mesh, 'coin');
    this.mesh.id = id ?? this.mesh.name;

    this.mesh.isPickable = false;
    this.mesh.checkCollisions = false;
    this.mesh.alwaysSelectAsActiveMesh = true;
    this.mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    this.mesh.material?.freeze();

    const originalShadow = scene.getMeshByName('baseCoinShadow') as Nullable<Mesh>;
    if (originalShadow === null) {
      throw new Error('base coin shadow not found');
    }
    this.shadowMesh = originalShadow.createInstance(`coin${id}Shadow`);
    this.shadowMesh.position = startPoint.toV3();
    this.shadowMesh.position.y = 0.01;

    const speed = calculateSpeed(getDistance(startPoint, endPoint));
    const curvePoints = [startPoint.toV3(height / 2), endPoint.toV3(height / 2)];
    shadowMoveAnim(scene, this.shadowMesh, endPoint.toV3(0.01), undefined, speed);
    onCurveAnim(scene, this.mesh, curvePoints, () => {
      this.shadowMesh.setParent(this.mesh);
      this.shadowMesh.position.y = -0.183;
      this.runRotation();
    }, speed);

    setTimeout(() => {
      if (this.mesh.isDisposed()) return;
      if (this.owner) return;
      if (this.mesh.getScene().isDisposed) return;
      this.delete();
    }, 2 * 1000 * 60);
  }

  runRotation() {
    rotationAnim(this.mesh, 'y', 0.5, this.mesh.getScene());
    this.onReadyPickable.notifyObservers(this);
  }

  public runMagnetism(owner: Mesh) {
    this.onRunMagnetism.notifyObservers(this);
    this.owner = owner;
    this.function = this.magnetism.bind(this);
    this.mesh.getScene().registerBeforeRender(this.function);
  }

  magnetism() {
    const { owner } = this;
    if (!owner || owner.isDisposed()) {
      this.delete();
      this.mesh.getScene().unregisterBeforeRender(this.function);
      return;
    }

    const targetPosition = Convertors.vector.toV2(owner.position);
    const curPosition = Convertors.vector.toV2(this.mesh.position);
    const distance = getDistance(curPosition, targetPosition);
    if (distance <= 0.1) {
      this.delete();
      this.mesh.getScene().unregisterBeforeRender(this.function);
      return;
    }
    this.speed += 0.005;
    const direction = owner.position.subtract(this.mesh.position).normalize();
    this.mesh.position = this.mesh.position.add(direction.scale(this.speed * this.mesh.getScene().getAnimationRatio()));
  }

  public delete() {
    this.mesh.dispose();
    this.onDelete.notifyObservers(this);
  }
}
