import * as BABYLON from '@babylonjs/core';
import { Color3 } from '@babylonjs/core';
import { createAlphaMaterial } from '../../materials';

type Option = { size: number } | { height: number, width: number };

export function createShadow(name: string, options: Option) {
  const shadow = BABYLON.MeshBuilder.CreatePlane(name, options);
  shadow.rotation.x = Math.PI / 2;
  shadow.alwaysSelectAsActiveMesh = true;
  shadow.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
  shadow.doNotSyncBoundingInfo = true;
  shadow.checkCollisions = false;
  shadow.isPickable = false;
  shadow.setEnabled(false);

  const findMaterial = shadow.getScene().getMaterialByName('shadowMaterial');
  if (findMaterial) {
    shadow.material = findMaterial;
  } else {
    const texture = new BABYLON.Texture('textures/shadows/4.png');
    texture.name = 'shadow4';

    const material = createAlphaMaterial('shadowMaterial', texture);
    material.diffuseColor = Color3.Black();
    material.alpha = 0.7;
    material.freeze();

    shadow.material = material;
  }

  return shadow;
}
