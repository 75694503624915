import { Coordinate } from '../../types/points/coordinate';

/**
 * Функция getPointsBetweenStrict вычисляет и возвращает все точки пересечения
 * на прямой линии между двумя заданными координатами.
 * Strict - обозначает, что следующая точка будет смещена только по одной оси.
 *
 * @param start - Точка от которой будут браться промежуточные точки.
 * @param end - Точка до которой будут браться промежуточные точки.
 *
 * @returns {Coordinate[]} - массив координат, представляющих все точки пересечения на прямой линии между начальной и конечной координатами
 * (включая начальную и конечную координату).
 * @throws {Error} - выбрасывает ошибку, если цикл зацикливается или превышает допустимое количество попыток (500).
 *
 * @example
 * // возвращает [{x: 0, y: 0}, {x: 0, y: 1}, {x: 1, y: 1}, {x: 1, y: 2}, {x: 2, y: 2}]
 * getLineIntersectionPoints({x: 0, y: 0}, {x: 2, y: 2})
 */
export function getPointsBetweenStrict({ start, end }: { start: Coordinate, end: Coordinate }): Coordinate[] {
  const tryCount = 0;

  const dx = Math.abs(end.x - start.x);
  const dy = Math.abs(end.y - start.y);

  const sx = start.x < end.x ? 1 : -1;
  const sy = start.y < end.y ? 1 : -1;

  let { x } = start;
  let { y } = start;

  let err = dx - dy;

  const points: Coordinate[] = [];

  while (tryCount < 500) {
    const last = points[points.length - 1];
    if (!last) {
      points.push(new Coordinate(x, y));
    } else if (last.x !== x || last.y !== y) {
      points.push(new Coordinate(x, y));
    }

    if (x === end.x && y === end.y) {
      break;
    }

    const e2 = 2 * err;

    // Horizontal step
    if (e2 > -dy) {
      err -= dy;
      x += sx;
    }

    // If we haven't reached the end point, add this point again
    if (x !== end.x || y !== end.y) {
      const last2 = points[points.length - 1];
      if (!last2) {
        points.push(new Coordinate(x, y));
      } else if (last2.x !== x || last2.y !== y) {
        points.push(new Coordinate(x, y));
      }
    }

    // Vertical step
    if (e2 < dx) {
      err += dx;
      y += sy;
    }
  }

  if (tryCount >= 500) {
    // console.error(`Начальные точки: \nx1: ${x1}, y1: ${y1}\nx2: ${x2}, y2: ${y2}`)
    // console.error(`Расстояние между точками - ${getDistance({x: x1, y: y1}, {x: x2, y: y2})}`)
    // console.error(...result)
    throw Error('Цикл зациклился или превышает допустимое кол-во попыток');
  }

  return points;
}
