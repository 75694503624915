import gameSettings from '../../../settings/gameSettings';
import { BasePoint } from './basePoint';
import { Coordinate } from './coordinate';

export class Real extends BasePoint {
  toCoordinate() {
    const newX = Math.round(this.halfSize - this.x * this.step);
    const newY = Math.round(this.halfSize + this.y * this.step);
    return new Coordinate(newX, newY);
  }

  static Random(maxRadius: number = gameSettings.game.GROUND_RADIUS, offset = 0) {
    // Случайная точка в окружности с радиусом gameSettings.game.GROUND_RADIUS
    const angle = Math.random() * 2 * Math.PI;
    const radius = Math.sqrt(Math.random()) * maxRadius + offset;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    return new Real(x, y);
  }
}
