import { Real } from '../../types/points/real';
import { getDistance } from '../index';

export function getNearPoint(point: Real, polygon: Real[]) {
  let index = 0;
  let distance = getDistance(point, polygon[0]);
  for (let i = 1; i < polygon.length; i++) {
    const curDistance = getDistance(point, polygon[i]);
    if (curDistance < distance) {
      distance = curDistance;
      index = i;
    }
  }

  return { point: polygon[index], index, distance };
}
