import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import { useStore } from '../../../../hooks/useStore';

import { ParkPlaceProps } from '../../../../stores/ParkingStore/types';
import { BusyPlace } from './BusyPlace';
import { BuyPlace } from './BuyPlace';
import { EmptyPlace } from './EmptyPlace';
import { RefuelingPlace } from './RefuelingPlace';
import style from './style.module.scss';

export const MainSection = observer(() => {
  const { parkingStore, carsStore } = useStore();
  const [park, setPark] = useState<ParkPlaceProps[]>([]);

  useEffect(() => {
    const temp = parkingStore.park.filter((line) => line.line === 0 || parkingStore.park[line.line - 1].places[3].statusPlace !== 'sale');
    const carsId: string[] = [];
    temp.forEach((line) => {
      line.places.forEach((places) => {
        if (places.carId) carsId.push(places.carId);
      });
    });

    for (const place of parkingStore.factory) {
      if (!place.carId) continue;
      if (place.statusPlace !== 'created') continue;
      carsId.push(place.carId);
    }

    for (const place of parkingStore.merging) {
      if (!place.carId) continue;
      if (place.statusPlace !== 'merged') continue;
      carsId.push(place.carId);
    }

    if (!carsStore.selectedCar || (carsStore.selectedCar && !carsId.includes(carsStore.selectedCar.id))) {
      carsStore.setSelectedCar(null);
    }

    setPark(temp);
  }, [parkingStore.park]);

  return (
    <>
      {park.map((line) => (
        <div key={line.line}>
          <div className={style.group}>
            <RefuelingPlace key={line.line} line={line} />
            {line.places.map((place) => {
              if (place.statusPlace === 'busy') return <BusyPlace key={place.carId} place={place} />;
              if (place.statusPlace === 'sale') return <BuyPlace key={place.idx} place={place} />;
              return <EmptyPlace key={place.idx} place={place} />;
            })}
          </div>
        </div>
      ))}
    </>
  );
});
