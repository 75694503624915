import gameSettings from '../../../settings/gameSettings';
import { Grid } from '../../grid/grid';
import { Convertors, getDifference, getNearPoint, getPointsBetweenStrict, isPointInPolygon } from '../../helpers';
import { Real } from '../../types/points/real';
import { BaseAgent } from '../agent';
import { Intersection } from '../hendlers/intersection';
import { isOutsideMap, Player } from '../player';
import { ManagerMP } from './manager';

export class PlayerController {
  grid = new Grid(gameSettings.grid.SIZE);
  intersection = new Intersection(this.grid, this);
  manager: ManagerMP;

  constructor(manager: ManagerMP) {
    this.manager = manager;
  }

  commitPlayer(player: BaseAgent) {
    this.grid.createOwner(player.id);
    this.grid.overwriteZonePoints(player.zone.getDetailPoint(), player.id);

    player.zone.onChangeObserver.add(() => {
      this.grid.overwriteZonePoints(player.zone.getDetailPoint(), player.id);
    });
  }

  checkIntersections(player: Player) {
    const newPos = player.car.getPos().toCoordinate();
    const start = player.oldPosition?.toCoordinate();
    if (!start) return;
    const waypoints = getPointsBetweenStrict({ start, end: newPos });
    waypoints.splice(0, 1);
    try {
      this.intersection.checkWayPoints(player, waypoints);
    } catch (error) {
      console.error(error);
    }
  }

  movePlayer(player: Player) {
    if (!player.isMove) return;
    if (!player.isAlive) return;

    const forward = player.car.mesh.forward.clone();
    const hoodNose = Convertors.vector.toV2(player.car.mesh.position.subtract(forward.clone().scale(0.5)));
    if (isOutsideMap(hoodNose)) {
      player.turnAway();
    } else {
      player.oldPosition = Convertors.vector.toReal(player.car.mesh.position);
      player.moveWithOffset();
      player.calculateDistance();
      player.applyRotation();
      this.checkIntersections(player);
    }
  }

  subtractZones(player: Player, checkZone: Real[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const idPlayers = Array(...player.crossedZones);
    const { points } = player.zone;
    for (const id of idPlayers) {
      const enemy = this.manager.get(id);
      if (!enemy) continue;
      if (!enemy?.isAlive) continue;

      if (this.checkingOccupiedZone(enemy, checkZone)) {
        continue;
      }

      const enemyZonePoints = enemy.zone.points;
      const difference = getDifference(points, enemyZonePoints);
      if (difference.length === 1) {
        enemy.zone.createOrUpdate(difference[0]);
        continue;
      }

      const hasTail = enemy.tail.points.length;
      if (hasTail) {
        // TODO: Оптимизировать
        const firstTailPoint = enemy.tail.points[0];
        let indexNearRegion = 0;
        let minDistance = getNearPoint(firstTailPoint, difference[indexNearRegion]).distance;
        for (let i = 1; i < difference.length; i++) {
          const { distance } = getNearPoint(firstTailPoint, difference[i]);
          if (distance < minDistance) {
            minDistance = distance;
            indexNearRegion = i;
          }
        }

        enemy.zone.createOrUpdate(difference[indexNearRegion]);
      } else {
        const region = difference.find((regionLoop) => isPointInPolygon(enemy.getPos(), regionLoop));
        if (region) {
          enemy.zone.createOrUpdate(region);
        } else {
          enemy.zone.delete();
        }
      }
    }

    if (player.crossedZones.size !== 0) {
      player.needUpdateZone = true;
    }

    player.crossedZones.clear();
  }

  zonesWithinZone(player: Player) {
    const zone = player.zone.points;
    const otherPlayers = this.manager.getAllPlayer();
    for (const enemy of otherPlayers) {
      if (enemy.id === player.id) continue;
      if (player.crossedZones.has(enemy.id)) continue;
      if (!enemy.zone.points.length) continue;

      // TODO Оптимизировать
      const enemyZone = enemy.zone.points;
      if (isPointInPolygon(enemyZone[0], zone)) {
        enemy.zone.delete();
      }
    }
  }

  checkingOccupiedZone(player: BaseAgent, occupiedZone: Real[]) {
    const hasTail = player.tail.points.length;
    if (!hasTail) {
      return isPointInPolygon(player.getPos(), occupiedZone);
    }

    const enemyExitPoint = player.tail.points[0];
    return isPointInPolygon(enemyExitPoint, occupiedZone);
  }
}
