import React from 'react';
import style from './style.module.sass';

interface IProps {
  percent: number;
}

export function NewRecord(props: IProps) {
  const { percent } = props;

  return (
    <div className={style.newRecord}>
      <span>Новый рекорд!</span>
      <span className={style.text}>{`Окрашено ${percent} %`}</span>
    </div>
  );
}
