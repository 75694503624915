import { observer } from 'mobx-react';

// import { useStore } from '../../../../hooks/useStore';
// import { RefuelingPlace } from './RefuelingPlace';
// import { CarPlace } from './CarPlace';
// import { BuyPlace } from './BuyPlace';

// import style from './style.module.scss';

export const UpgradeSection = observer(() => (
  // const { parkingStore } = useStore();
  // const groups = parkingStore.upgradeGroups;

  <>
    {/* {groups.map((group) => (
        <div key={group[0].idx} className={style.group}>
          {group.map((place) => {
            if (!place.paid) {
              return <BuyPlace key={place.idx} place={place} />;
            }
            if (place.type === 'Refueling') {
              return <RefuelingPlace key={place.idx} place={place} />;
            }
            return <CarPlace key={place.idx} place={place} />;
          })}
        </div>
      ))} */}
  </>
));
