class OkManager {
  FAPI: any;
  isInit = false;

  userId?: string;

  /**
   * Делает запрос в ОК через их API
   * @param {{method}} params - объект с данными для запроса
   */
  callFAPI(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.isInit) {
        const E = new Error();
        E.name = 'Error FAPI';
        E.message = 'FAPI not found';
        reject(E);
        return;
      }

      this.FAPI.Client.call(params, (result: string, data: any, error: any) => {
        if (result === 'ok') return resolve(data);

        const E = new Error();
        E.name = `Error FAPI (${result})`;
        E.message = `Description: ${JSON.stringify(error)}`;
        return reject(E);
      });
    });
  }

  globalCallback(method: string, result: string, data: any) {
    switch (method) {
      case 'loadAd':
        window.dispatchEvent(new CustomEvent('MyLoadAd', { bubbles: true, detail: result === 'ok' }));
        break;
      case 'showLoadedAd':
        window.dispatchEvent(new CustomEvent('MyShowAd', { bubbles: true, detail: result === 'ok' }));
        break;
      default:
        console.warn({ event: 'globalCallback not found', method, result, data });
    }
  }

  processError(error: any) {
    console.error(error);
    throw error;
  }

  loadScript() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://api.ok.ru/js/fapi5.js');
    document.head.appendChild(script);

    return new Promise((resolve, reject) => {
      script.onload = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.FAPI = window.FAPI;
        resolve(true);
      };

      script.onerror = () => {
        const E = new Error();
        E.name = 'Error load script';
        E.message = 'script cant load';
        reject(E);
      };
    });
  }

  async init() {
    await this.loadScript().catch(this.processError);

    return new Promise((resolve, reject) => {
      const rParams = this.FAPI.Util.getRequestParameters();
      this.FAPI.init(
        rParams.api_server,
        rParams.apiconnection,
        () => {
          this.userId = this.FAPI.Util.getRequestParameters().logged_user_id;
          this.isInit = true;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.API_callback = this.globalCallback.bind(this);
          resolve(true);
        },
        (error: any) => {
          reject(error);
          this.processError(error);
        },
      );
    });
  }

  async getUserInfo() {
    const user = await this.callFAPI({ fields: 'first_name,last_name,pic128x128', method: 'users.getCurrentUser' }).catch(this.processError);

    return {
      firstName: user.first_name,
      lastName: user.last_name,
      avatar: user.pic128x128,
      id: user.uid,
    };
  }

  prepareAd(): Promise<{ result: boolean }> {
    return new Promise((resolve) => {
      this.FAPI.UI.loadAd();
      window.addEventListener('MyLoadAd', (event: CustomEventInit) => {
        console.log({ event: 'MyLoadAd', detail: event.detail });
        resolve({ result: event.detail });
      });
    });
  }

  showAd(): Promise<{ result: boolean }> {
    return new Promise((resolve) => {
      this.FAPI.UI.showLoadedAd();
      window.addEventListener('MyShowAd', (event: CustomEventInit) => {
        console.log({ event: 'MyLoadAd', detail: event.detail });
        resolve({ result: event.detail });
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showStory(image: string): Promise<{ result: boolean }> {
    return new Promise((resolve) => { resolve({ result: false }); });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showShoppingWindow(id: string): Promise<{ result: boolean }> {
    return new Promise((resolve) => { resolve({ result: false }); });
  }
}

const okManager = new OkManager();

export default okManager;
