import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as CarBonus } from '../../assets/images/garage/carBonus.svg';
import gearIcon from '../../assets/images/garage/gear.png';
// import leagueIcon from '../../assets/images/garage/icon-league.png';
import shopIcon from '../../assets/images/garage/icon-shop.png';
import questionMarkIcon from '../../assets/images/garage/question mark.png';
import { ReactComponent as Tip } from '../../assets/images/garage/tip.svg';

import Trashbin from '../../assets/images/garage/trashbin.png';
import { gameStore } from '../../babylon/stores/gameStore';
import { IconPreview } from '../../components/IconPreview';
import { StrokeText } from '../../components/StrokeText';

import { useStore } from '../../hooks/useStore';
import amp from '../../services/amplitude/Amplitude';
import backend from '../../services/backend';
import { Tutorial } from '../Tutorial';
import { AssetPanel } from './AssetPanel';
import { CarFactory } from './CarFactory';
import { Merging } from './Merging';
import { Parking } from './Parking';

import style from './style.module.scss';

export const Garage = observer(() => {
  if (gameStore.room && gameStore.room.connection.isOpen) {
    console.log('Обнаружено соединение с сервером');
    gameStore.room.leave(true).then(() => {
      console.log('Соединение было разорвано, т.к. игрок больше не в игре');
    });
  }

  const [wrappedParking, setWrappedParking] = useState(true);

  const navigate = useNavigate();

  const {
    userStore,
    carsStore,
    tutorialStore,
    parkingStore: { merging, factory, park },
    modalWindowStore: modalStore,
  } = useStore();
  const { user } = userStore;
  const { selectedCar } = carsStore;

  let countCars = 0;
  for (const mergingPlaceProp of merging) {
    if (mergingPlaceProp.statusPlace !== 'merged') continue;
    countCars++;
  }

  for (const factoryPlaceProp of factory) {
    if (factoryPlaceProp.statusPlace !== 'created') continue;
    countCars++;
  }
  for (const parkPlaceProp of park) {
    for (const place of parkPlaceProp.places) {
      if (place.statusPlace !== 'busy') continue;
      countCars++;
    }
  }

  const statusBarMainContainerClass = classNames(style.container, style.container_alignToEnd);
  const toolbarMainContainerClass = classNames(style.container, style.container_alignToStart);
  const toolbarContentContainerClass = classNames(style.toolbar_container, {
    [style.toolbar_container_unwrap]: !wrappedParking,
  });

  const onStartGame = async () => {
    const { fuel, territory } = await carsStore.getMaxTerritory();
    if (fuel) {
      gameStore.setMaxCurTerritories(territory);
      backend.nextTutor(user.id);
      tutorialStore.setStep(null);
      navigate('game');
      amp.gameStart();
    } else {
      navigate('nofuel');
    }
  };
  // const onOpenLeagues = () => {
  //   navigate('leagues');
  // };
  const onOpenShop = () => {
    navigate('shop');
  };

  const onOpenFAQ = () => {
    navigate('faq');
  };

  const onOpenSettings = () => {
    navigate('settings');
  };

  const onClickDeleteButton = () => {
    if (!selectedCar) {
      return;
    }

    navigate('deleteCar');
  };

  const onClickTip = () => {
    if (!selectedCar) {
      return;
    }

    modalStore.setHeader('Бонус к монетам');
    modalStore.setText('Это бонус от уровня выбранного автомобиля, который вы получите к набранным монетам на арене.');
    navigate('tip');
  };
  return (
    <div className={style.garage}>
      <div className={style.statusBar}>
        <div className={statusBarMainContainerClass} style={{ padding: 0 }}>
          {/* <div className={style.statusBar__league}>
            <button type="button" onClickTip={onOpenLeagues}>
              <img src={leagueIcon} alt="league" />
            </button>
          </div> */}
          <div className={style.bottomContainer}>
            <button className={style.deleteButton} type="button" onClick={onClickDeleteButton} disabled={!selectedCar || countCars < 2}>
              <img src={Trashbin} alt="delete" />
            </button>
            <div className={style.carBonus} style={{ display: selectedCar ? 'flex' : 'none' }}>
              <CarBonus />
              {`${selectedCar?.car.lvl}%`}
              <Tip onClick={onClickTip} style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <div className={style.statusBar__car}>{selectedCar && <img src={`/models/cars/${selectedCar.car.model}.png`} alt="car" />}</div>
          <div className={style.statusBar__status}>
            <div className={style.statusBar__status__assets}>
              <AssetPanel value={user.soft} type="coin" />
              <AssetPanel value={user.hard} type="cash" />
            </div>
            <button type="button" onClick={onOpenShop}>
              <img src={shopIcon} alt="shop" />
            </button>
            <button type="button" onClick={onOpenFAQ}>
              <img src={questionMarkIcon} alt="FAQ" />
            </button>
            <button type="button" onClick={onOpenSettings}>
              <img src={gearIcon} alt="settings" />
            </button>
          </div>
        </div>
      </div>
      <div className={style.toolbar}>
        <div className={toolbarMainContainerClass}>
          <div className={style.toolbar__playButton}>
            <div className={style.toolbar__playButton_container}>
              <button
                disabled={!selectedCar}
                className={selectedCar ? style.jumpFlicker : undefined}
                type="button"
                onClick={onStartGame}
                data-tutorial="play-button"
              >
                <StrokeText text="Играть" />
              </button>
            </div>
            <div className={style.toolbar__playButton_extBg} />
          </div>
          <div className={toolbarContentContainerClass}>
            {wrappedParking && (
            <div className={style.fmContainer}>
              <CarFactory />
              <Merging />
            </div>
            )}
            <Parking wrapped={wrappedParking} onWrapped={setWrappedParking} />
          </div>
        </div>
      </div>
      <Tutorial />
      <IconPreview />
      <Outlet />
    </div>
  );
});
