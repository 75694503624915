function abbrNum(number: number, decPlaces = 2): string | number {
  decPlaces = 10 ** decPlaces;
  const abbrev = ['K', 'M', 'B', 'T'];
  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      return `${number}${abbrev[i]}`;
    }
  }

  return number;
}

export default abbrNum;
