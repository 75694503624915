import { Owner } from './owner';

export class Owners {
  owners: Owner[] = [];

  create(name: string) {
    this.owners.push(new Owner(name));
  }

  remove(name: string) {
    const index = this.owners.findIndex((owner) => owner.name === name);
    this.owners.splice(index, 1);
  }

  getOwner(name: string): Owner | undefined {
    for (const owner of this.owners) {
      if (owner.name === name) return owner;
    }
    return undefined;
  }
}
