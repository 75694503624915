//
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
//
// GENERATED USING @colyseus/schema 2.0.6
//

import { ArraySchema, Schema, type } from '@colyseus/schema';
import { CoordinateScheme } from './CoordinateScheme';
import { Zone } from './Zone';

export class Player extends Schema {
  @type('string') public id!: string;
  @type('string') public name!: string;
  @type('string') public photo!: string;
  @type('string') public model!: string;
  @type('number') public rotation!: number;
  @type('string') public colorName!: string;
  @type('number') public lastCommandTick!: number;
  @type('number') public killCounter!: number;
  @type('number') public countPickUpCoins!: number;
  @type('number') public level!: number;
  @type('boolean') public isLive!: boolean;
  @type('boolean') public isMove!: boolean;
  @type('boolean') public inZone!: boolean;
  @type('string') public causeOfDeath!: string;
  @type('string') public killerId!: string;
  @type(CoordinateScheme) public pos: CoordinateScheme = new CoordinateScheme();
  @type(CoordinateScheme) public realPos: CoordinateScheme = new CoordinateScheme();
  @type([CoordinateScheme]) public checkPoint: ArraySchema<CoordinateScheme> = new ArraySchema<CoordinateScheme>();
  @type(Zone) public zone: Zone = new Zone();
  @type([CoordinateScheme]) public tail: ArraySchema<CoordinateScheme> = new ArraySchema<CoordinateScheme>();
}
