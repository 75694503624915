import classNames from 'classnames';
import abbrNum from '../../services/abbrNum';

import { StrokeText } from '../StrokeText';

import style from './style.module.scss';

export interface CoinsCountProps {
  count: number;
  iconSize?: 'big' | 'small' | 'xSmall';
  fontSize?: 'big' | 'small';
  inactive?: boolean;
  place?: 'factory' | 'parking';
}

export function CoinsCount({ count, iconSize, fontSize, inactive, place }: CoinsCountProps) {
  const columnDirection = (iconSize === 'big' && fontSize === 'big') || (iconSize === 'small' && fontSize === 'small');

  const containerClass = classNames(style.container, {
    [style.container_column]: columnDirection,
    [style.container_noGap]: iconSize === 'big',
    [style.container_inactive]: inactive,
  });

  const iconClass = classNames(style.icon, {
    [style.icon_big]: iconSize === 'big',
    [style.icon_small]: iconSize === 'small',
    [style.icon_xSmall]: iconSize === 'xSmall',
  });

  const valueClass = classNames(style.value, {
    [style.value_big]: fontSize === 'big',
    [style.value_small]: fontSize === 'small',
    [style.value_inactive]: inactive,
    [style.value_margin]: !columnDirection,
    [style.value_factory]: place === 'factory',
    [style.value_parking]: place === 'parking',
  });

  return (
    <div className={containerClass}>
      <span className={iconClass} />
      <StrokeText style={valueClass} text={`x ${abbrNum(count, 1)}`} />
    </div>
  );
}

CoinsCount.defaultProps = {
  iconSize: 'small',
  fontSize: 'big',
  inactive: false,
  place: undefined,
};
