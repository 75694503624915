import { difference as differenceTurf } from '@turf/turf';
import { Real } from '../../types/points/real';
import { Convertors } from '../converters';

export function getDifference(subtract: Real[], from: Real[]) {
  if (subtract.length === 0) return [from];
  if (from.length === 0) return [[]];
  const subtractFeature = Convertors.polygons.fromReal(subtract);
  const fromFeature = Convertors.polygons.fromReal(from);
  const result = differenceTurf(fromFeature, subtractFeature);

  if (!result) return [[]];

  if (result.geometry.type === 'Polygon') {
    return [Convertors.polygons.toReal(result.geometry.coordinates)];
  }

  const difference: Real[][] = [];
  for (const regions of result.geometry.coordinates) {
    difference.push(Convertors.polygons.toReal(regions));
  }
  return difference;
}
