import { AbstractMesh } from '@babylonjs/core';
import { rotationAnim } from '../../../animations';
import { changeOpacityAnim } from '../../../animations/changeOpacity.anim';
import { getRandomFloat, getSize } from '../../../helpers';
import { Real } from '../../../types';
import { createShadow } from '../createShadow';
import { optimizationMesh } from '../optimizationMesh';
import { PickupItem } from './pickupItem';

interface ISuperMagnetCreate {
  id: string;
  position: Real;
}

export class SuperMagnet extends PickupItem {
  shadowMesh?: AbstractMesh;

  isReady = false;
  size: { x: number; y: number; z: number };

  constructor({ id, position }: ISuperMagnetCreate) {
    super({ modelName: 'superMagnetBase', position, id, name: 'SuperMagnet', copyMode: 'clone' });

    this.mesh.rotation.x = 0;
    this.mesh.rotation.y = getRandomFloat(0, Math.PI * 2, 6);

    this.size = getSize(this.mesh);
    this.mesh.rotation.x = Math.PI / 4;
    this.createShadow();

    this.startAnimations();
  }

  private createShadow() {
    const shadow = createShadow('SuperMagnetShadow', { size: this.size.z * 1.5 });
    shadow.setEnabled(true);
    shadow.position = this.mesh.position.clone();
    shadow.position.y = 0.01;

    this.shadowMesh = shadow;
  }

  private startAnimations() {
    const speed = 2;

    changeOpacityAnim(this.mesh, {
      speed,
      callback: () => {
        this.isReady = true;
        rotationAnim(this.mesh, 'y', 0.5, this.mesh.getScene());
        this.canPick = true;
        optimizationMesh(this.mesh, AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY);
      },
    });

    if (!this.shadowMesh) return;
    changeOpacityAnim(this.shadowMesh, { speed });
    this.shadowMesh.setParent(this.mesh);
  }
}
