import { makeAutoObservable } from 'mobx';
import amp from '../../services/amplitude/Amplitude';
import backend from '../../services/backend';
import { RootStore } from '../RootStore';
import { steps } from './tutorial';
import { TutorialStep } from './TutorialStep';

export class TutorialStore {
  public currentStepIdx: number | null = null;

  public steps: TutorialStep[] = [...steps];
  public demo = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  public get currentStep(): TutorialStep | undefined {
    return this.steps[this.currentStepIdx as number];
  }

  public get isFirstStep() {
    return this.currentStepIdx === 0;
  }

  public get isLastStep() {
    return this.currentStepIdx !== null && this.currentStepIdx >= this.steps.length - 1;
  }

  public setDemo(state: boolean) {
    this.demo = state;
  }

  public setStep(step: number | null) {
    console.trace({ event: 'setStep for tutorial', step });
    this.currentStepIdx = step;
  }

  public nextStep = async () => {
    if (!this.isLastStep && typeof this.currentStepIdx === 'number') {
      this.setStep(this.currentStepIdx + 1);
      backend.nextTutor(this.rootStore.userStore.user.id);
      amp.stepTutorial(this.currentStepIdx);
    } else {
      console.log({ event: 'tutorial step set null on NEXT step', isLastStep: this.isLastStep, currentStepIdx: this.currentStepIdx });
      this.setStep(null);
    }
  };

  public previousStep = () => {
    if (!this.isFirstStep && typeof this.currentStepIdx === 'number') this.setStep(this.currentStepIdx - 1);
  };
}
