import { useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';

import { LeagueRewards as LeagueRewardsType } from '../../../stores/RewardsStore';
import { RewardPlace } from './RewardPlace';
import { StrokeText } from '../../../components/StrokeText';

import style from './style.module.scss';

export interface LeagueRewardsProps {
  league: LeagueRewardsType;
}

export function LeagueRewards({ league }: LeagueRewardsProps) {
  const { name, level, rewards } = league;
  const places = rewards.map((reward) => <RewardPlace key={reward.id} reward={reward} />);

  const titleRef = useRef<HTMLDivElement>(null);
  const [titleWidth, setTitleWidth] = useState(150);

  const containerClass = classNames(style.container, style[`container_${level}`]);
  const cupClass = style[`header__cup_${level}`];
  const leagueClass = classNames(style.header__title__league, style[`header__title_${level}`]);
  const descriptionClass = classNames(style.header__title__description, style[`header__title_${level}`]);

  useLayoutEffect(() => {
    if (!titleRef.current) return;
    setTitleWidth(Math.floor(0.92 * titleRef.current.offsetWidth));
  }, []);

  return (
    <div className={containerClass}>
      <header className={style.header}>
        <span className={cupClass} />
        <div ref={titleRef} className={style.header__title}>
          <StrokeText style={leagueClass} text={`${name} лига`} />
          <StrokeText style={descriptionClass} text="Добейся повышения, 10 победителей перейдут в новую лигу" wrap maxWidth={titleWidth} />
        </div>
      </header>
      <div className={style.content}>{places}</div>
    </div>
  );
}
