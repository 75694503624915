import React from 'react';
import style from './style.module.sass';

interface IProps {
  name: string;
  value: boolean;
  setter: (value: boolean) => void;
}

export function Checkbox(props: IProps) {
  const { name, value, setter } = props;

  return (
    <div className={style.main}>
      <span>{name}</span>
      <input type="checkbox" checked={value} onChange={(event) => setter(event.currentTarget.checked)} />
    </div>
  );
}
