import React, { CSSProperties } from 'react';
import style from './style.module.sass';

interface IButtonProps {
  text: string;
  color: 'yellow' | 'green';
  onClick: () => void;
  icon?: JSX.Element;
}

export function AppButton(props: IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const { text, color, onClick, icon = null, ...rest } = props;

  const styles: CSSProperties = {};
  if (color === 'green') {
    styles.background = '#53FF3E';
    styles.boxShadow = '0px -3px 2px 0px #35B625 inset, 0px 4px 4px 0px #ACFFA1 inset, 0px 4px 4px 0px #00000033';
    styles.borderColor = '#37AA29';
    styles.textShadow = '2px 0 #3AB22B, -2px 0 #3AB22B, 0 2px #3AB22B, 0 -2px #3AB22B, 1px 1px #3AB22B, -1px -1px #3AB22B, 1px -1px #3AB22B, -1px 1px #3AB22B';
  } else {
    styles.background = '#FFB800';
    styles.boxShadow = '0px -3px 2px 0px #CD7C02 inset, 0px 4px 4px 0px #FFE68B inset, 0px 4px 4px 0px #00000033';
    styles.borderColor = '#A97A00';
    styles.textShadow = '2px 0 #E78A00, -2px 0 #E78A00, 0 2px #E78A00, 0 -2px #E78A00, 1px 1px #E78A00, -1px -1px #E78A00, 1px -1px #E78A00, -1px 1px #E78A00';
  }

  return (
    <button type="button" className={style.button} onClick={onClick} {...rest} style={{ ...styles, ...rest.style }}>
      {text}
      {icon}
    </button>
  );
}
