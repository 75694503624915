import { AbstractMesh, Mesh } from '@babylonjs/core';
import { changeOpacityAnim } from '../../../animations';
import { getSize } from '../../../helpers';
import { Real } from '../../../types';
import { createShadow } from '../createShadow';
import { optimizationMesh } from '../optimizationMesh';
import { PickupItem } from './pickupItem';

interface ISuperMagnetCreate {
  id: string;
  position: Real;
}

export class Bucks extends PickupItem {
  shadowMesh?: AbstractMesh;

  isReady = false;
  size: { x: number; y: number; z: number };

  constructor({ id, position }: ISuperMagnetCreate) {
    super({ modelName: 'cashBase', position, id, name: 'Cash', copyMode: 'clone', height: 0.4 });
    this.size = getSize(this.mesh);
    this.createShadow();
    this.mesh.billboardMode = Mesh.BILLBOARDMODE_ALL;

    this.startAnimations();

    this.onMagnetism.add(() => {
      if (this.scene.isDisposed) return;
      if (!this.shadowMesh) return;
      this.shadowMesh.position = this.mesh.position.clone();
      this.shadowMesh.position.y = 0.01;
    });
  }

  private createShadow() {
    const shadow = createShadow('baseCashShadow', { size: 0.3 });
    shadow.setEnabled(true);
    shadow.position = this.mesh.position.clone();
    shadow.position.y = 0.01;

    this.shadowMesh = shadow;
  }

  private startAnimations() {
    const speed = 2;

    changeOpacityAnim(this.mesh, {
      speed,
      callback: () => {
        this.isReady = true;
        this.canPick = true;
        optimizationMesh(this.mesh, AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY, { dontFreezeWorldMatrix: true });
      },
    });

    if (!this.shadowMesh) return;
    changeOpacityAnim(this.shadowMesh, { speed });
  }
}
