import { motion, useMotionValue } from 'framer-motion';
import { useState } from 'react';
import { CloseButton } from '../../components/CloseButton';
import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import { useStore } from '../../hooks/useStore';
import style from './style.module.scss';

export function Tip() {
  const { modalWindowStore: modalStore } = useStore();
  const [open, setOpen] = useState(false);
  const opacity = useMotionValue(0);
  opacity.on('animationComplete', () => {
    setOpen(true);
  });

  const { text, header } = modalStore;

  return (
    <ModalWindow>
      <div className={style.container_top}>
        <motion.div
          className={style.container}
          style={{ opacity }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
        >
          {open && (
          <>
            <div className={style.close_container}>
              <CloseButton color="purple" path="/" />
            </div>
            <header className={style.header}>
              <StrokeText style={style.title} text={header} maxWidth={500} />
            </header>
            <span className={style.text}>
              {text}
            </span>
          </>
          )}
        </motion.div>
      </div>
    </ModalWindow>
  );
}
