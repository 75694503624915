type User = {
  firstName: string;
  lastName: string;
  avatar: string;
  id: string;
};

class TgManager {
  Telegram: any;

  processError(error: any) {
    console.error(error);
    throw error;
  }

  async loadScript() {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://telegram.org/js/telegram-web-app.js');
    script.setAttribute('defer', '');
    await document.head.appendChild(script);

    return new Promise((resolve, reject) => {
      script.onload = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.Telegram = window.Telegram;

        resolve(true);
      };

      script.onerror = () => {
        const E = new Error();
        E.name = 'Error load script';
        E.message = 'script cant load';
        reject(E);
      };
    });
  }

  async init() {
    const { parent } = window;
    parent.postMessage({ playdeck: { method: 'loading', value: 1 } }, '*');
    await this.loadScript().catch(this.processError);
    if (this.Telegram && this.Telegram.WebApp) {
      this.Telegram.WebApp.ready();
      this.Telegram.WebApp.expand();
      parent.postMessage({ playdeck: { method: 'loading', value: 100 } }, '*');
      console.log('Telegram WebApp initialized');
    } else {
      console.error('Telegram WebApp not available');
    }
  }

  getUserInfo(): Promise<User> {
    return new Promise((resolve) => {
      window.parent.postMessage({ playdeck: { method: 'getUserProfile' } }, '*');
      function messageHandler(event: MessageEvent) {
        const { playdeck } = event.data;
        if (!playdeck) return;
        if (playdeck.method === 'getUserProfile') {
          console.log(playdeck.value); // Profile
          const { firstName, lastName, avatar, telegramId } = playdeck.value;
          window.removeEventListener('message', messageHandler);
          resolve({
            firstName,
            lastName,
            avatar,
            id: String(telegramId),
          });
        }
      }

      window.addEventListener('message', messageHandler);
    });
  }

  prepareAd(): Promise<{ result: boolean }> {
    return new Promise((resolve) => {
      console.log('Приготовить рекламу');
      resolve({ result: false });
    });
  }

  showAd(): Promise<{ result: boolean }> {
    return new Promise((resolve) => {
      window.addEventListener('MyShowAd', (event: CustomEventInit) => {
        console.log({ event: 'MyLoadAd', detail: event.detail });
        resolve({ result: event.detail });
      });
    });
  }

  showStory(image: string): Promise<{ result: boolean }> {
    return new Promise((resolve) => {
      console.log(image);

      resolve({ result: false });
    });
  }

  showShoppingWindow(id: string, amount: number, description: string, photoUrl: string): Promise<{ result: boolean }> {
    const { parent } = window;
    const uniqueParam = new Date().getTime().toString();
    console.log(id);
    return new Promise<{ result: boolean }>((resolve, reject) => {
      const handleMessage = (event: MessageEvent) => {
        const playdeck = event.data?.playdeck;
        if (!playdeck) return;

        console.log('Received message:', playdeck);

        if (playdeck.method === 'requestPayment') {
          const { url } = playdeck.value;
          if (url) {
            parent.postMessage(
              {
                playdeck: {
                  method: 'openTelegramLink',
                  value: url,
                },
              },
              '*',
            );
          } else {
            reject(new Error('Не удалось получить ссылку на оплату'));
          }
        }

        if (playdeck.method === 'invoiceClosed') {
          window.removeEventListener('message', handleMessage);
          const { status } = playdeck.value;
          console.log('Invoice closed with status:', status);
          if (status === 'paid') {
            resolve({ result: true });
          } else {
            resolve({ result: false });
          }
        }
      };

      console.log({ id, amount, description, photoUrl });

      // Добавляем обработчик сообщений
      window.addEventListener('message', handleMessage);

      // Отправляем запрос на открытие окна платежа
      parent.postMessage(
        {
          playdeck: {
            method: 'requestPayment',
            value: {
              amount: 1,
              description,
              externalId: uniqueParam,
              photoUrl,
            },
          },
        },
        '*',
      );
      const paymentLog = { amount, description, externalId: id, photoUrl };
      console.log(JSON.stringify(paymentLog));
    });
  }
}
const tgManager = new TgManager();

export default tgManager;
