import { gameStore } from '../../stores/gameStore';

export function deleteAllMarks() {
  const { scene } = gameStore;
  if (!scene) return;
  const meshes = scene.getMeshesByTags('mark');
  meshes.forEach((mesh) => {
    mesh.dispose();
  });
}
