import { advancedSplice } from './advancedSplice';
import { Real } from '../../types/points/real';

export function getPointsCounterClockwise(start: number, end: number, points: Real[], insertionPoints: Real[]) {
  let deleteCount: number;
  const pointsCounterClockwise = [...points];

  if (end > start) {
    deleteCount = (pointsCounterClockwise.length - end + start) * -1;
  } else {
    deleteCount = end - start;
  }

  if (start !== end) {
    advancedSplice(pointsCounterClockwise, start + 1, deleteCount - 1, ...insertionPoints.reverse());
  } else {
    pointsCounterClockwise.splice(start + 1, 0, ...insertionPoints.reverse());
  }

  return pointsCounterClockwise;
}
