import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { CarIcon } from '../../../../components/CarIcon';
import { Place, PlaceStatusType } from '../../../../components/Place';
import { useStore } from '../../../../hooks/useStore';
import { FactoryPlaceProps, MergingPlaceProps, ParkPlacesPlace } from '../../../../stores/ParkingStore/types';
import style from './style.module.scss';

export interface DropResult {
  place: MergingPlaceProps | ParkPlacesPlace;
}

export const IssuePlace = observer(({ place }: { place: FactoryPlaceProps }) => {
  const { carsStore, parkingStore, userStore, tutorialStore } = useStore();
  const car = carsStore.cars.find((c) => c.id === place.carId);

  const status: PlaceStatusType = carsStore.selectedCar?.id === place.carId ? 'selected' : 'active';
  const refIndicatorFill = useRef<HTMLSpanElement>(null);
  const noFull = car ? car.fuel < car.car.maxFuel : false;
  const indicatorClass = classNames(style.indicator, style.indicator_idle);

  const [, drag, preview] = useDrag(() => ({
    type: 'FactoryPlace',
    item: place,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (!dropResult || !item.carId) return;
      parkingStore
        .moveCar(
          userStore.user.id,
          item.carId,
          dropResult.place.type,
          dropResult.place.idx,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dropResult.place.idxLine ? dropResult.place.idxLine : 0,
        )
        .then(() => {
          if (tutorialStore.currentStepIdx === 5) {
            tutorialStore.nextStep();
          }
        });
    },
  }));

  const onClickPlace = () => {
    if (place.carId) carsStore.setSelectedCar(place.carId);
  };

  useEffect(() => {
    if (!car || !refIndicatorFill.current) return;
    const fill = Math.floor((car.fuel / car.car.maxFuel) * 100);
    const fillEl = refIndicatorFill.current;
    fillEl.style.width = `${fill}%`;
  }, [car, car?.fuel]);

  return (
    <Place type="factory" dataTutorial="factory-created-place" onClick={onClickPlace} status={status}>
      {car && (
      <>
        <CarIcon type="factory" lvl={car.car.lvl} drag={drag} preview={preview} src={`/models/cars/${car.car.model}Icon.png`} />
        {noFull && (
        <div className={indicatorClass}>
          <span ref={refIndicatorFill} className={style.indicator_fill} />
          <span className={style.indicator_text}>{car.fuel}</span>
        </div>
        )}
      </>
      )}
    </Place>
  );
});
