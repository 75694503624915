import { Mesh, TransformNode, Vector3 } from '@babylonjs/core';
import { importModel } from '../../helpers/models/importModel';

export const charactersList = ['orangeJumpsuit'];

export async function loadCharacters() {
  const charactersModel: Mesh[] = [];

  for (let i = 0; i < charactersList.length; i++) {
    const modelName = charactersList[i];
    // eslint-disable-next-line no-await-in-loop
    const [mesh] = await Promise.all([importModel(modelName, 'models/characters/', `${modelName}Base`)]);
    mesh.scaling = new Vector3(0.2, 0.2, 0.2);
    mesh.position.y = 10;
    mesh.setEnabled(false);
    const character = (mesh.getChildren()[0] as TransformNode).getChildren()[1] as Mesh;
    charactersModel.push(character);
  }

  return charactersModel;
}
