import { AbstractMesh, Scene } from '@babylonjs/core';
import { BaseAgent } from './base';

export abstract class ObjectToFollow {
  scene: Scene;
  mesh?: AbstractMesh;
  owner?: BaseAgent;
  height = 1;

  constructor(scene: Scene, height = 1) {
    this.scene = scene;
    this.height = height;
  }

  abstract create(owner: BaseAgent, url?: string): void;

  updatePosition() {
    if (this.scene.isDisposed) return;
    if (!this.mesh || !this.owner) return;

    const ownerPos = this.owner.car.mesh.position.clone();
    ownerPos.y += this.height;

    this.mesh.position = ownerPos;
  }

  updateRotation() {
    if (this.scene.isDisposed) return;
    if (!this.mesh) return;
    this.mesh.rotation.y += 0.01 * this.scene.getAnimationRatio();
  }

  updateOwner(newOwner: BaseAgent) {
    if (this.scene.isDisposed) return;
    if (!this.mesh) return;

    this.owner = newOwner;

    this.mesh.position = this.owner.car.mesh.position.clone();
    this.mesh.position.y += 1;
  }

  updateHeight(height: number) {
    this.height = height;
  }

  delete() {
    if (this.scene.isDisposed) return;
    if (!this.mesh || this.mesh.isDisposed()) return;
    this.mesh.dispose();
    this.owner = undefined;
  }
}
