import { Coordinate } from '../types';

export class Owner {
  name: string;
  tail: Coordinate[] = [];
  zone: Coordinate[] = [];

  constructor(name: string) {
    this.name = name;
  }

  addTailPoints(coords: Coordinate[]) {
    this.tail.push(...coords);
  }

  clearTail() {
    this.tail = [];
  }

  overwriteZone(coords: Coordinate[]) {
    this.zone = coords;
  }
}
