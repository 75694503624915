import { Color3, StandardMaterial, Texture } from '@babylonjs/core';
import { AnimatedGifTexture } from '../textures/animatedGifTexture';

export function createAlphaMaterial(nameMaterial: string, texture: Texture | AnimatedGifTexture) {
  const material = new StandardMaterial(nameMaterial);
  material.disableLighting = true;

  material.diffuseTexture = texture;
  // material.diffuseTexture.hasAlpha = true;
  // material.diffuseTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
  // material.diffuseTexture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;

  // material.emissiveTexture = texture;
  material.emissiveColor = Color3.White();
  // material.emissiveTexture.hasAlpha = true;
  // material.emissiveTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
  // material.emissiveTexture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;

  material.opacityTexture = texture;
  // material.opacityTexture.hasAlpha = true;
  // material.opacityTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
  // material.opacityTexture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;
  // material.backFaceCulling = false;
  return material;
}
