import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ponter from '../../assets/images/garage/pointer.png';

import pers1 from '../../assets/images/tutor/pers-1.png';
import pers2 from '../../assets/images/tutor/pers-2.png';
import pers3 from '../../assets/images/tutor/pers-3.png';
import { StrokeText } from '../../components/StrokeText';
import { useStore } from '../../hooks/useStore';
import amp from '../../services/amplitude/Amplitude';
import backend from '../../services/backend';
import { getDOMRect } from '../../services/paint';

import style from './style.module.scss';

const persImg = [pers1, pers2, pers3];

export const Tutorial = observer(() => {
  const { tutorialStore, parkingStore, carsStore, userStore } = useStore();
  const { currentStep: step, currentStepIdx } = tutorialStore;
  const anime = useRef<Animation>();
  const navigate = useNavigate();

  const [pos, setPos] = useState<DOMRect | null>(null);
  const [descPers, setDesc] = useState<boolean>(false);

  const pointerPosition = {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    'left-bottom': (pos: DOMRect) => {
      if (pos) {
        return {
          top: `${pos.top + pos.height - 15}px`,
          left: `${pos.left - 60}px`,
        };
      }
      return { top: '-500px', left: '-500px' };
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    'left-top': (pos: DOMRect) => {
      if (pos) {
        return {
          top: `${pos.top - 60}px`,
          left: `${pos.left - 60}px`,
        };
      }
      return { top: '-500px', left: '-500px' };
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    'right-bottom': (pos: DOMRect) => {
      if (pos) {
        return {
          top: `${pos.top + pos.height - 15}px`,
          left: `${pos.left + pos.width}px`,
        };
      }
      return { top: '-500px', left: '-500px' };
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    'right-top': (pos: DOMRect) => {
      if (pos) {
        return {
          top: `${pos.top - 65}px`,
          left: `${pos.left + pos.width}px`,
        };
      }
      return { top: '-500px', left: '-500px' };
    },
  };

  const checkPosition = () => {
    anime.current?.cancel();

    const resetClasses = document.getElementsByClassName(style.comp);
    if (resetClasses.length > 0) {
      for (let i = 0; i < resetClasses.length; i++) {
        resetClasses[i].classList.remove(style.comp);
      }
    }

    if (typeof tutorialStore.currentStepIdx === 'number' && step) {
      if (step.typeAnimation === 'point' && step.pointer) {
        const point = getDOMRect(step.pointer);
        if (point) {
          console.log({ event: 'tutorial - found pointer', step: step.num, point });
          point.classList.add(style.comp);
          setPos(point.getBoundingClientRect());
        } else {
          console.log({ event: 'tutorial - not found pointer', step: step.num, pointer: step.pointer });
          const createdCar = parkingStore.factory.find((place) => place.statusPlace === 'created');
          const creatingCar = parkingStore.factory.find((place) => place.statusPlace === 'creating');
          const margingCar = parkingStore.merging.find((place) => place.statusPlace === 'merging');
          const margedCar = parkingStore.merging.find((place) => place.statusPlace === 'merged');

          switch (step.num) {
            case 0:
              break;
            case 1:
              break;
            case 2:
              if (createdCar) {
                carsStore.setSelectedCar(createdCar.carId);
                tutorialStore.setStep(4);
                backend.setTutor(userStore.user.id, 4);
              } else {
                tutorialStore.setStep(null);
              }
              break;
            case 3:
              if (createdCar) {
                tutorialStore.setStep(4);
                backend.setTutor(userStore.user.id, 4);
                return;
              }
              if (!creatingCar) {
                tutorialStore.setStep(null);
                return;
              }
              navigate('score', {
                state: {
                  title: 'Завод',
                  placeIdx: creatingCar.idx,
                  zIndex: 'unset',
                },
              });
              setTimeout(() => {
                if (!step.pointer) { return; }
                const tryFoundPoint = getDOMRect(step.pointer);
                if (!tryFoundPoint) { return; }
                tryFoundPoint.classList.add(style.comp);
                setPos(tryFoundPoint.getBoundingClientRect());
              }, 500);
              break;
            case 4:
              break;
            case 5:
              break;
            case 6:
              break;
            case 7:
              if (margedCar) {
                carsStore.setSelectedCar(margedCar.carId);
                tutorialStore.setStep(9);
                backend.setTutor(userStore.user.id, 9);
              } else {
                tutorialStore.setStep(null);
              }
              break;
            case 8:
              if (margedCar) {
                tutorialStore.setStep(9);
                backend.setTutor(userStore.user.id, 9);
                return;
              }
              if (!margingCar) {
                tutorialStore.setStep(null);
                return;
              }
              navigate('score', {
                state: {
                  title: 'Слияние',
                  placeIdx: margingCar.idx,
                  zIndex: 'unset',
                },
              });
              setTimeout(() => {
                if (!step.pointer) { return; }
                const tryFoundPoint = getDOMRect(step.pointer);
                if (!tryFoundPoint) { return; }
                tryFoundPoint.classList.add(style.comp);
                setPos(tryFoundPoint.getBoundingClientRect());
              }, 500);
              break;
            case 9:
              break;
            case 10:
              break;
            default:
              tutorialStore.setStep(null);
          }
        }
      } else if (step.typeAnimation === 'move') {
        if (step.from && step.to) {
          const pointer = document.getElementById('tutot-poniter');

          const from = getDOMRect(step.from);
          const to = getDOMRect(step.to);

          from?.classList.remove('jumpFlicker');

          from?.classList.add(style.comp);
          to?.classList.add(style.comp);

          const fromRect = from?.getBoundingClientRect();
          const toRect = to?.getBoundingClientRect();

          if (fromRect && toRect && pointer) {
            setPos(fromRect);
            const a = pointerPosition[step.positionMove || 'left-bottom'](fromRect);
            const b = pointerPosition[step.positionMove || 'left-bottom'](toRect);
            anime.current = pointer.animate([a, b], {
              duration: 3000,
              iterations: Infinity,
            });
          } else {
            console.warn({ event: 'tutorial - not found from or to', step: step.num, from, to });
            switch (step.num) {
              case 0:
                break;
              case 1:
                break;
              case 2:
                break;
              case 3:
                break;
              case 4:
                break;
              case 5:
                break;
              case 6:
                break;
              case 7:
                break;
              case 8:
                break;
              case 9:
                break;
              case 10:
                break;
              default:
                tutorialStore.setStep(null);
            }
          }
        }
      } else {
        setPos(null);
      }
    }
  };

  useEffect(() => {
    setDesc(false);
    setTimeout(checkPosition, 200);
    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', checkPosition);
    if (step?.positionPers && typeof step.typePers === 'number') {
      setDesc(true);
      if (step.delayPers) {
        setTimeout(() => {
          setDesc(false);
        }, step.delayPers);
      }
    }
    return () => {
      window.removeEventListener('scroll', checkPosition);
      window.removeEventListener('resize', checkPosition);
    };
  }, [step, currentStepIdx]);

  const classesPointer = classNames(style['pointer-block'], {
    [style['pointer-left-bottom-animate']]: step?.typeAnimation === 'point' && step?.positionPointer === 'left-bottom',
    [style['pointer-left-top-animate']]: step?.typeAnimation === 'point' && step?.positionPointer === 'left-top',
    [style['pointer-right-bottom-animate']]: step?.typeAnimation === 'point' && step?.positionPointer === 'right-bottom',
    [style['pointer-right-top-animate']]: step?.typeAnimation === 'point' && step?.positionPointer === 'right-top',
    [style['pointer-left-bottom']]: step?.positionPointer === 'left-bottom' || step?.positionMove === 'left-bottom',
    [style['pointer-left-top']]: step?.positionPointer === 'left-top' || step?.positionMove === 'left-top',
    [style['pointer-right-bottom']]: step?.positionPointer === 'right-bottom' || step?.positionMove === 'right-bottom',
    [style['pointer-right-top']]: step?.positionPointer === 'right-top' || step?.positionMove === 'right-top',
  });

  const classesPers = classNames(style['pers-block'], {
    [style['pers-block-left']]: step?.positionPers === 'left',
    [style['pers-block-right']]: step?.positionPers === 'right',
  });

  const classesPersDesc = classNames(style['pers-block-text'], {
    [style['pers-block-text-left']]: step?.positionPers === 'left',
    [style['pers-block-text-right']]: step?.positionPers === 'right',
  });

  return typeof currentStepIdx === 'number' ? (
    <div className={style.container}>
      {!descPers && (
      <div className={style.comment} data-tutorial="comment">
        {step?.description}
        {step?.typeAnimation === 'static' ? (
          <button
            type="button"
            className={style.buttonGreat}
            onClick={() => {
							  tutorialStore.nextStep();
            }}
          >
            <StrokeText style={style.buttonGreat__label} text="Отлично!" />
          </button>
        ) : (
          <button
            type="button"
            className={style.buttonSkip}
            onClick={() => {
							  amp.skipStepTutorial(currentStepIdx);
							  tutorialStore.nextStep();
            }}
          >
            Пропустить
          </button>
        )}
      </div>
      )}
      <div
        className={classesPointer}
        id="tutot-poniter"
        style={pointerPosition[step?.positionPointer || step?.positionMove || 'left-bottom'](pos as DOMRect)}
      >
        {/* <div id="tutor-car" className={style['pointer-car']} /> */}
        <img className={style.pointer} src={ponter} alt="ponter" />
      </div>
      {descPers && typeof step?.typePers === 'number' && step.positionPers && (
      <div>
        <div className={classesPersDesc}>
          {step.description}
          {step?.typeAnimation === 'static' ? (
            <button
              type="button"
              className={style.buttonGreat}
              onClick={() => {
								  tutorialStore.nextStep();
								  backend.nextTutor(userStore.user.id);
              }}
            >
              <StrokeText style={style.buttonGreat__label} text="Отлично!!!" />
            </button>
          ) : (
            <button
              type="button"
              className={style.buttonSkip}
              onClick={() => {
								  tutorialStore.nextStep();
              }}
            >
              Пропустить
            </button>
          )}
        </div>
        <div className={classesPers}>
          <img className={style['pers-block-img']} src={persImg[step.typePers]} alt="pers" />
        </div>
      </div>
      )}
    </div>
  ) : null;
});
