import { makeAutoObservable } from 'mobx';

export class ModalWindowStore {
  header = '';
  text = '';
  constructor() {
    makeAutoObservable(this);
  }

  setHeader(header: string) {
    this.header = header;
  }

  setText(text: string) {
    this.text = text;
  }

  clear() {
    this.header = '';
    this.text = '';
  }
}
