import { Scene } from '@babylonjs/core';
import { AdvancedDynamicTexture } from '@babylonjs/gui/2D';
import { Room } from 'colyseus.js';
import { makeAutoObservable } from 'mobx';
import gameSettings from '../../settings/gameSettings';

class GameStore {
  model = '';
  carId = '';
  level = 1;
  name = 'Неизвестный';
  photo = '';
  id = '';

  causeOfDeath = '';
  whoKilled? = '';
  isKillSelf = false;

  moneyPickUp = 0;
  bucksPickUp = 0;
  killCounter = 0;
  ping!: number;
  showPraise = false;
  territoriesCaptured = 0;
  curMaxTerritories = 0;

  textPraise = 'Супер!';
  showDeadMenu = false;
  adIsPreloaded = false;

  room!: Room;
  scene!: Scene;
  gui!: AdvancedDynamicTexture;
  isLoading = false;
  artificialZoom = false;
  showTutorial: boolean;
  showDebugLayer = false;
  idKing?: string;

  canUseJoystick = false;
  joystickDirectional? = { x: 0, y: 0 };
  joystickIsPressed = false;
  staticJoyStick = false;

  onGoHome!: () => void;
  baseOnGoHome!: () => void;
  respawnCallback!: () => void;

  isMobile?: boolean;
  errorCode: number | null = null;

  soundVolume: number;
  musicVolume: number;

  constructor() {
    const staticJoyStick = localStorage.getItem('staticJoyStick');
    if (staticJoyStick && staticJoyStick === 'true') {
      this.staticJoyStick = true;
    }

    const soundVolume = localStorage.getItem('soundVolume');
    if (soundVolume) {
      this.soundVolume = Number(soundVolume);
    } else {
      this.soundVolume = gameSettings.game.SOUND;
      localStorage.setItem('soundVolume', this.soundVolume.toString());
    }

    const musicVolume = localStorage.getItem('musicVolume');
    if (musicVolume) {
      this.musicVolume = Number(musicVolume);
    } else {
      this.musicVolume = gameSettings.game.MUSIC;
      localStorage.setItem('musicVolume', this.musicVolume.toString());
    }

    const showTutorial = localStorage.getItem('showTutorial');
    if (!showTutorial || showTutorial !== 'false') {
      localStorage.setItem('showTutorial', 'true');
      this.showTutorial = true;
    } else {
      this.showTutorial = false;
    }

    makeAutoObservable(this);
  }

  setSoundVolume(volume: number) {
    this.soundVolume = volume;
    localStorage.setItem('soundVolume', volume.toString());
  }

  setMusicVolume(volume: number) {
    this.musicVolume = volume;
    localStorage.setItem('musicVolume', volume.toString());
  }

  setStaticJoyStick(isStatic: boolean) {
    this.staticJoyStick = isStatic;
    localStorage.setItem('staticJoyStick', isStatic.toString());
  }

  setErrorCode(code: number | null) {
    this.errorCode = code;
  }

  setCarId(id: string) {
    this.carId = id;
  }

  setMaxCurTerritories(curMaxTerritories: number) {
    this.curMaxTerritories = curMaxTerritories;
  }

  setIsMobile(isMobile: boolean) {
    this.isMobile = isMobile;
  }

  setMoneyPickUp(moneyPickUp: number) {
    this.moneyPickUp = moneyPickUp;
  }

  setTerritoriesCaptured(territoriesCaptured: number) {
    this.territoriesCaptured = territoriesCaptured;
  }

  async turnShowDebugLayer() {
    if (!this.scene) return;
    if (this.scene.isDisposed) return;
    if (this.scene.debugLayer.isVisible()) {
      this.scene.debugLayer.hide();
      this.setCanUseJoystick(true);
    } else {
      await this.scene.debugLayer.show({
        overlay: true,
      });
      this.setCanUseJoystick(false);
    }
  }

  setIdKing(id?: string) {
    this.idKing = id;
  }

  setScene(scene: Scene) {
    this.scene = scene;
  }

  setCauseOfDeath(cause: string, killerName: string | undefined) {
    this.causeOfDeath = cause;
    this.whoKilled = killerName;
  }

  setId(id: string) {
    this.id = id;
  }

  setPhoto(photo: string) {
    this.photo = photo;
  }

  setPreloadAd(isPreloaded: boolean) {
    this.adIsPreloaded = isPreloaded;
  }

  setShowPraise(show: boolean) {
    if (show && this.showPraise) return;
    if (!show && !this.showPraise) return;
    if (show) {
      this.showPraise = show;
      setTimeout(() => {
        this.setShowPraise(false);
      }, 500);
    } else {
      this.showPraise = show;
    }
  }

  setTextPraise(text: string) {
    this.textPraise = text;
  }

  setLevel(level: number) {
    this.level = level;
  }

  setRespawnCallback(callback: () => void) {
    this.respawnCallback = callback;
  }

  setShowDeadMenu(show: boolean) {
    this.showDeadMenu = show;
  }

  setKills(num: number) {
    this.killCounter = num;
  }

  setModel(nameModel: string) {
    this.model = nameModel;
  }

  createGui() {
    this.gui = AdvancedDynamicTexture.CreateFullscreenUI('mainGUI');
  }

  setRoom(room: Room) {
    this.room = room;
  }

  setGoHome(callback: () => void) {
    this.onGoHome = callback;
    this.baseOnGoHome = callback;
  }

  setAdditionalGoHome(callback: () => void) {
    this.onGoHome = () => {
      callback();
      this.baseOnGoHome();
    };
  }

  setPing(time: number) {
    this.ping = time;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setUserName(name: string) {
    this.name = name;
  }

  setShowTutorial(show: boolean) {
    this.showTutorial = show;
    localStorage.setItem('showTutorial', show ? 'true' : 'false');
  }

  setCanUseJoystick(canUse: boolean) {
    this.canUseJoystick = canUse;
  }

  setDirectionalJoystick(directional: { x: number; y: number } | undefined) {
    this.joystickDirectional = directional;
  }

  setPressedJoystick(isPressed: boolean) {
    this.joystickIsPressed = isPressed;
  }

  setKillSelf(isKillSelf: boolean) {
    this.isKillSelf = isKillSelf;
  }

  setBucksPickUp(bucksPickUp: number) {
    this.bucksPickUp = bucksPickUp;
  }
}

export const gameStore = new GameStore();
