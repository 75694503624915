import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, Mesh, Scene } from '@babylonjs/core';

export function smackAnim(object: Mesh | AbstractMesh, speed: number, scene: Scene, callback?: () => void) {
  const defSize = object.scaling;
  const defPos = object.position;

  const scaleXAnimation = new BABYLON.Animation(
    'scaleXAnimation',
    'scaling.x',
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
  );

  scaleXAnimation.setKeys([{
    frame: 0,
    value: defSize.x * 2,
  }, {
    frame: 50,
    value: defSize.x,
  }]);

  const scaleYAnimation = new BABYLON.Animation(
    'scaleYAnimation',
    'scaling.y',
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
  );

  scaleYAnimation.setKeys([{
    frame: 0,
    value: defSize.y * 2,
  }, {
    frame: 50,
    value: defSize.y,
  }]);

  const loweringAnimation = new BABYLON.Animation(
    'loweringAnimation',
    'position.y',
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
  );

  loweringAnimation.setKeys([{
    frame: 0,
    value: 10,
  }, {
    frame: 50,
    value: defPos.y,
  }, {
    frame: 100,
    value: defPos.y,
  }]);

  object.animations = [scaleXAnimation, scaleYAnimation, loweringAnimation];
  return scene.beginAnimation(object, 0, 100, false, speed, callback);
}
