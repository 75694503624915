import { motion, useMotionValue } from 'framer-motion';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ModalWindow } from '../../components/ModalWindow';
import { ShareButton } from '../../components/ShareButton';
import { StrokeText } from '../../components/StrokeText';
import { useStore } from '../../hooks/useStore';

import style from './style.module.scss';

export function Congrats() {
  const { carsStore } = useStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const car = carsStore.cars.find((c) => c.id === id);
  const [open, setOpen] = useState(false);
  const opacity = useMotionValue(0);
  opacity.on('animationComplete', () => {
    setOpen(true);
  });

  const navigate = useNavigate();
  const onGreat = () => {
    navigate('/', { replace: true });
  };

  return (
    <ModalWindow>
      <div className={style.container_top}>
        <motion.div
          className={style.container}
          style={{ opacity }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
        >
          {open && (
            <>
              <header className={style.header}>
                <StrokeText style={style.header__title} text="Поздравляем!" />
                <StrokeText style={style.header__description} text="У Вас новый автомобиль" />
              </header>
              {car && <img className={style.car_image} src={`/models/cars/${car.car.model}.png`} alt="a new car" />}
              <div className={style.buttonsGroup}>
                <button type="button" className={style.buttonGreat} onClick={onGreat}>
                  <StrokeText style={style.buttonGreat__label} text="Отлично!" />
                </button>
                {/* <ShareButton type="wall" /> */}
                <ShareButton car={car} type="story" />
              </div>
            </>
          )}
        </motion.div>
      </div>
    </ModalWindow>
  );
}
