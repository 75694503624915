import { ReactComponent as Dummy } from '../../svg/dummy.svg';
import style from './style.module.sass';

interface IProps {
  color: { r: number; g: number; b: number };
  name: string;
  area: number;
  position: number;
  photo: string;
}

export function Position(props: IProps) {
  const { name, area, color, position, photo } = props;

  const dynamicStyleBar = { background: `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})` };

  return (
    <div className={style.container}>
      <div className={style.bar} style={dynamicStyleBar}>
        <span className={style.position}>{`${position}.`}</span>
        <span className={style.area}>{`${area}%`}</span>
        <div className={style.img}>
          {photo ? <img src={photo} alt="Аватарка" className={style.avatar} /> : <Dummy />}
        </div>
      </div>
      <div className={style.name}>{name}</div>
    </div>
  );
}
