import { BaseAgent, IBaseAgent, SpawnParams } from '../agent';
import { Avatar } from '../agent/avatar';

export class TrackedEntity extends BaseAgent {
  qtyLastReceivedPoints = 0;
  isVisible = true;
  avatar: Avatar;

  constructor(props: IBaseAgent) {
    super(props);

    if (this.zone.mesh) {
      this.zone.mesh.position.y = 0.001;
    }

    this.tail.setLift(0.004);
    this.zone.setLift(0.001);

    this.avatar = new Avatar(this.scene, 1);
    this.avatar.create(this, this.photo || './textures/forGui/dummy.svg');
  }

  spawn(params: SpawnParams) {
    super.spawn(params);

    if (!this.avatar) return;
    if (!this.avatar.mesh) return;
    this.avatar.updatePosition();
    this.avatar.mesh.setEnabled(true);
  }

  death() {
    super.death();

    if (!this.avatar) return;
    if (!this.avatar.mesh) return;
    this.avatar.mesh.setEnabled(false);
  }

  writeTail() {
    if (!this.isAlive) return;
    if (this.inZone) return;
    if (!this.isWriteTail) return;
    const curPosition = this.getPos();
    if (!curPosition) return;
    this.tail.addPoints([curPosition]);
  }
}
