import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, Scene, Vector3 } from '@babylonjs/core';

export function shadowMoveAnim(scene: Scene, object: AbstractMesh, endPosition: Vector3, callback?: () => void, speedModifier = 1) {
  // Создаем ключевые кадры для анимации
  const keys = [];
  keys.push({ frame: 0, value: object.position });
  keys.push({ frame: 40, value: endPosition });

  // Создаем ключевые кадры для масштабирования
  const scaleKeys = [];
  scaleKeys.push({ frame: 0, value: object.scaling });
  scaleKeys.push({ frame: 20, value: object.scaling.multiply(new Vector3(1.5, 1.5, 1.5)) });
  scaleKeys.push({ frame: 40, value: object.scaling });

  // Создаем анимацию
  const animation = new BABYLON.Animation(
    'moveAndScaleAnimation',
    'position',
    60,
    BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
  );
  animation.setKeys(keys);

  const scaleAnimation = new BABYLON.Animation(
    'scaleAnimation',
    'scaling',
    60,
    BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
  );
  scaleAnimation.setKeys(scaleKeys);

  // запуск анимации
  object.animations = [animation, scaleAnimation];
  return scene.beginAnimation(object, 0, 100, false, speedModifier, callback);
}
