import { useStore } from '../../hooks/useStore';
import { LeagueRewards } from './LeagueRewards';
import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import { CloseButton } from '../../components/CloseButton';

import style from './style.module.scss';

export function Rewards() {
  const { rewardsStore } = useStore();

  const rewards = rewardsStore.leagues.map((league) => <LeagueRewards key={league.name} league={league} />);

  return (
    <ModalWindow>
      <div className={style.container}>
        <header className={style.header}>
          <StrokeText style={style.header__title} text="Лиги: Награды за достижения" />
          <CloseButton className={style.header__close} path="/leagues" color="purple" />
        </header>
        <div className={style.content}>
          <div className={style.content_container}>{rewards}</div>
        </div>
      </div>
    </ModalWindow>
  );
}
