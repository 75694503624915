import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import style from './style.module.scss';

export interface ModalWindowProps {
  children: React.ReactNode;
  zIndex?: number | 'unset';
}

export function ModalWindow({ children, zIndex = 101 }: ModalWindowProps) {
  const navigate = useNavigate();

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const elem = event.target as HTMLDivElement;
    if (elem?.id === 'modal') {
      navigate('/');
    }
  };

  return (
    <AnimatePresence>
      <motion.div id="modal" key="modal" initial={{ opacity: 0, zIndex }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={style.modal} onClick={onClick}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
