import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import managerApi from '../../api/managerApi';
import { CloseButton } from '../../components/CloseButton';
import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';

import { useStore } from '../../hooks/useStore';
import amp from '../../services/amplitude/Amplitude';

import style from './style.module.scss';

export const Score = observer(() => {
  const location = useLocation();
  console.log({ location });
  const locationState = location.state;
  const navigate = useNavigate();
  const [buttonAd, setButtonAd] = useState(false);

  const { scoreStore, userStore, parkingStore, tutorialStore, carsStore } = useStore();
  const { user, viewAd } = userStore;
  const title = !locationState ? '' : locationState.title;
  const placeIdx = !locationState ? -1 : locationState.placeIdx;
  scoreStore.setTitle(title);
  scoreStore.setPlaceIdx(placeIdx);

  const btnRewardedClass = classNames(style.item__button, style.item__button_rewarded);
  const iconRewardedClass = classNames(style.item__button__icon, style.item__button__icon_rewarded);

  const btnHardClass = classNames(style.item__button, style.item__button_hard);
  const iconHardClass = classNames(style.item__button__icon, style.item__button__icon_hard);

  const leftSkips = user.skipsLeft ?? { merge: 0, factory: 0 };
  const type = scoreStore.adType;
  let showAdSkip = false;

  const tutorStep = tutorialStore.currentStepIdx;
  const hardPrice = (tutorStep === 8 || tutorStep === 3 ? 0 : (scoreStore.hardPrice ?? 0));

  switch (type) {
    case 'mergeSkip':
      showAdSkip = leftSkips.merge > 0;
      break;
    case 'createSkip':
      showAdSkip = leftSkips.factory > 0;
      break;
    default:
      showAdSkip = false;
  }

  const onClickRewarded = async () => {
    if (scoreStore.adType) {
      const idAd = await userStore.createViewAd(scoreStore.adType);
      if (idAd) {
        managerApi.showAd().then(async (data) => {
          if (data.result) {
            if (type) {
              amp.adsShowed(type);
            }

            const res = await userStore.checkAd(idAd, {
              position: scoreStore.placeIdx,
            });
            if (res) {
              userStore.loadViewAd();
              parkingStore.loadParking(user.id);
              navigate('/');
            }
          } else {
            console.log('Ошибка при показе');
          }
        })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const onClickHard = async () => {
    const res = await scoreStore.scoreForHard();
    if (res) {
      if (scoreStore.hardPrice) {
        amp.hardSpent(scoreStore.hardPrice, title);
      }

      console.log({ event: 'click on hard', tutorStep });
      if (tutorStep === 3) {
        const place = parkingStore.factory[placeIdx];
        if (place.carId) {
          carsStore.setSelectedCar(place.carId);
        } else {
          carsStore.setSelectedCar('anything');
        }
        await tutorialStore.nextStep();
      }

      if (tutorStep === 8) {
        const place = parkingStore.merging[placeIdx];
        if (place.carId) {
          carsStore.setSelectedCar(place.carId);
        } else {
          carsStore.setSelectedCar('anything');
        }
        await tutorialStore.nextStep();
      }

      navigate('/');
    }
  };

  useEffect(() => {
    managerApi.prepareAd()
      .then((data) => {
        if (data.result) {
          setButtonAd(true);
        } else {
          console.log('Рекламные материалы не найдены.');
          setButtonAd(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setButtonAd(false);
      });
  }, []);

  useEffect(() => {
    if (!scoreStore.hardTime) navigate('/');
    if (!locationState) navigate('/');
  }, [scoreStore.hardTime, locationState]);

  return (
    <ModalWindow zIndex={locationState.zIndex}>
      <div className={style.score}>
        <header className={style.header}>
          <StrokeText style={style.header__title} text={`Ускорить ${title}?`} />
          <CloseButton className={style.header__close} color="purple" />
        </header>
        <span className={style.logo} />
        {viewAd[scoreStore.adType as string] > 0 && showAdSkip && (
        <div className={style.item}>
          <StrokeText style={style.item__value} text={scoreStore.rewardedTimeFormat} />
          <button type="button" className={btnRewardedClass} disabled={!buttonAd} onClick={onClickRewarded}>
            <span>
              <StrokeText style={style.item__button_rewarded__label} text="смотреть" />
            </span>
            <span className={iconRewardedClass} />
          </button>
        </div>
        )}
        {scoreStore.hardPrice && (
        <div className={style.item}>
          <StrokeText style={style.item__value} text={scoreStore.hardTimeFormat} />
          <button type="button" className={btnHardClass} disabled={user.hard - hardPrice < 0} onClick={onClickHard} data-tutorial="speedUp-button-hard">
            <span>
              <StrokeText style={style.item__button_hard__label} text={hardPrice} />
            </span>
            <span className={iconHardClass} />
          </button>
        </div>
        )}
      </div>
    </ModalWindow>
  );
});
