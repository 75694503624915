import managerApi from '../../api/managerApi';
import { CarsStore } from '../CarsStore';
import { LeaguesStore } from '../LeaguesStore';
import { ModalWindowStore } from '../ModalWindowStore';
import { ParkingStore } from '../ParkingStore';
import { RewardsStore } from '../RewardsStore';
import { ScoreStore } from '../ScoreStore';
import { ShopStore } from '../ShopStore';
import { TutorialStore } from '../TutorialStore';
import { UserStore } from '../UserStore';

export class RootStore {
  public userStore: UserStore;
  public carsStore: CarsStore;
  public parkingStore: ParkingStore;
  public scoreStore: ScoreStore;
  public leaguesStore: LeaguesStore;
  public rewardsStore: RewardsStore;
  public shopStore: ShopStore;
  public tutorialStore: TutorialStore;
  public modalWindowStore: ModalWindowStore;

  constructor() {
    this.tutorialStore = new TutorialStore(this);
    this.userStore = new UserStore(this);
    this.carsStore = new CarsStore(this);
    this.parkingStore = new ParkingStore(this);
    this.scoreStore = new ScoreStore(this);
    this.leaguesStore = new LeaguesStore();
    this.rewardsStore = new RewardsStore();
    this.shopStore = new ShopStore(this);
    this.modalWindowStore = new ModalWindowStore();

    managerApi.init().then((platform) => {
      console.log({ event: 'init manager api', platform });
      this.loadData();
    });
  }

  async loadData() {
    this.userStore
      .loadUserData()
      .then(() => {
        const userId = this.userStore.user.id;
        this.parkingStore.loadParking(userId).then(() => {
          this.carsStore.loadCarsByUser(userId).then(() => {
            this.carsStore.setSelectedCar(this.carsStore.cars[0].id);
          });
        });
        this.shopStore.loadShop();
        this.shopStore.getUsersPurchases();
      })
      .catch(() => {
        this.userStore.createUser().then(() => {
          this.loadData();
        });
      });
  }

  getChargingCar(idLine: number) {
    if (!this.parkingStore) return undefined;
    if (!this.parkingStore.park[idLine]) return undefined;
    const line = this.parkingStore.park[idLine];
    return line.places.find((carLoop) => {
      const carInLine = this.carsStore.cars.find((cycleCar) => cycleCar.id === carLoop.carId);
      if (!carInLine) return false;
      return carInLine.fuel < carInLine.car.maxFuel;
    });
  }
}

const rootStore = new RootStore();

export default rootStore;
