import axios from 'axios';

export interface ISettings {
  startScene: string;
  isLocal: boolean;
  gridSize: number;
  defaultModel: string;
  botNumber: number;
  playerNumber: number;
  pointToKill: number;
  pointToCoin: number;
  movementFrequency: number;
  pickupRadius: number;
  moveSpeed: number;
  thickness: number;
  zonePolygons: number;
  zoneFreeSpaceMultiplier: number;
  zoneSize: number;
  cameraRange: number;
  groundRadius: number;
  marginFromTheEdgeOfTheMap: number;
  volume: number;
  botMove: boolean;
  devMode: boolean;
  useLocalSetting: boolean;
}

export async function getSettings(): Promise<ISettings> {
  // const headers = { authorization: `Bearer ${await authorization()}` };
  const { data } = await axios.get(`${process.env.SERVER_URL}/settings`);
  return data.options;
}
