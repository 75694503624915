import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { CoinsCount } from '../../../../../components/CoinsCount';
import { Place } from '../../../../../components/Place';

import { useStore } from '../../../../../hooks/useStore';
import amp from '../../../../../services/amplitude/Amplitude';
import { ParkPlacesPlace } from '../../../../../stores/ParkingStore/types';

export const EmptyPlace = observer(({ place }: { place: ParkPlacesPlace }) => {
  const { parkingStore, userStore } = useStore();
  const [, drop] = useDrop(() => ({
    accept: ['FactoryPlace', 'MergingPlace', 'ParkingPlace'],
    drop: () => ({ place }),
  }));

  let interval: NodeJS.Timeout;
  let timeout: NodeJS.Timeout;

  const stopTimers = () => {
    clearInterval(interval);
    clearTimeout(timeout);
  };
  const setMoney = (money: number) => {
    if (money >= 30) stopTimers();
    parkingStore.setPlaceMoney(place.idx, place.idxLine, money >= 30 ? 30 : money);
  };

  const setTimer = (timer: number) => {
    parkingStore.setPlaceTimer(place.idx, place.idxLine, timer);
  };

  const startTimer = () => {
    stopTimers();
    const timer = place.fullTimer - place.timer;

    timeout = setTimeout(() => {
      setTimer(0);
      setMoney(place.money + 1);
      if (place.money >= 30) return;

      interval = setInterval(() => {
        setMoney(place.money + 1);
      }, place.fullTimer);
    }, timer);
  };

  const onTakeRevenue = () => {
    stopTimers();
    amp.gameReward(place.money);
    setMoney(0);
    parkingStore.takeRevenue(userStore.user.id, place.idx, place.idxLine);
  };

  if (place.money < 30) {
    startTimer();
  }

  useEffect(() => () => {
    stopTimers();
  });

  return (
    <Place type="parking" status={place.money > 0 ? 'active' : 'inactive'} drop={drop} onClick={onTakeRevenue}>
      <CoinsCount count={place.money} iconSize="big" inactive={place.money <= 0} place="parking" />
    </Place>
  );
});
