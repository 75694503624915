import { observer } from 'mobx-react';
import React, { CSSProperties, useEffect } from 'react';
import style from './style.module.scss';

interface IProps {
  category: string;
  icon: React.ReactNode;
  maxValue: number;
  minValue: number;
  value: number;
  setter: (value: number) => void;
  countPoints?: number;
}

const Slider = observer((props: IProps) => {
  const { category, icon, maxValue, minValue, value, setter, countPoints = 100 } = props;
  const valuePercent = Math.round((value * 100) / maxValue);
  const [percent, setPercent] = React.useState(valuePercent);
  const inputStyle: CSSProperties = {
    background: `linear-gradient(to right, #F0C62A 0%, #F0C62A ${percent}%, #fff ${percent}%, #fff 100%)`,
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    const result = minValue + newValue / countPoints * (maxValue - minValue);
    const rounded = +result.toFixed(4);

    setPercent(Math.round(newValue));
    return setter(rounded);
  };

  useEffect(() => {
    setPercent(valuePercent);
  }, [valuePercent]);

  return (
    <div className={style.container}>
      <div className={style.icon}>{icon}</div>
      <div className={style.content}>
        <div className={style.text}>
          <span className={style.category}>{category}</span>
          <span className={style.value}>{`${percent}%`}</span>
        </div>
        <div className={style.slider}>
          <input className={style.input} style={inputStyle} onChange={onChange} type="range" min={0} max={countPoints} value={percent} />
        </div>
      </div>
    </div>
  );
});
export default Slider;
