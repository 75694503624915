import * as BABYLON from '@babylonjs/core';
import { getSize, importModel } from '../../helpers';

import { createShadow } from './createShadow';

export async function createCoinModel(fileName: string) {
  const parent = await importModel(fileName, 'models/coins/', `${fileName}Base`);
  const coin = parent.getChildren()[0] as BABYLON.Mesh;
  coin.name = `${fileName}Base`;
  coin.setParent(null);
  parent.dispose();

  coin.position.y = -5;
  coin.scaling.x = 0.75;
  coin.scaling.z = 0.75;
  coin.scaling.y = 0.75;

  coin.checkCollisions = false;
  coin.isPickable = false;
  coin.material?.freeze();
  coin.alwaysSelectAsActiveMesh = true;
  coin.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
  coin.setEnabled(false);

  const size = getSize(coin);
  const sizeShadow = Math.max(size.x, size.z);
  createShadow('baseCoinShadow', { size: sizeShadow });

  coin.rotation.z = 0;

  return coin;
}
