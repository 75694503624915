import { ReactNode, createContext } from 'react';

import { rootStore, RootStore } from '../stores/RootStore';

export const StoreContext = createContext<RootStore>({} as RootStore);

export interface StoreProviderProps {
  children: ReactNode;
}

export function StoreProvider({ children }: StoreProviderProps) {
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
}
