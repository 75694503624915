import React from 'react';
import { ReactComponent as Mark } from '../../../assets/images/garage/questionMark.svg';
import { StrokeText } from '../../../components/StrokeText';
import style from './style.module.scss';

interface IProps {
  index: number;
  header: string;
  text: string;
}

function BlockContent(props: IProps) {
  const { index, header, text } = props;

  const isOdd = index % 2 === 1;
  const orderIndex = (index % 4) + 1;
  const questionMark = (
    <Mark className={style.oneMark} />
  );
  const questionMarks = (
    <div className={style.moreMarks}>
      <Mark className={style.one} />
      <Mark className={style.two} />
      <Mark className={style.three} />
    </div>
  );

  return (
    <div className={style.container}>
      <div className={style.header}><StrokeText text={header} maxWidth={210} style={style.headerText} wrap /></div>
      <div className={style.text}>{`"${text}"`}</div>
      {!isOdd && (orderIndex === 3 ? questionMarks : questionMark)}
    </div>
  );
}

export default BlockContent;
