import { makeObservable, observable, action } from 'mobx';

import { RootStore } from '../RootStore';
import { Pack } from './Pack';
import backend from '../../services/backend';
// import { Timer, Countdown, convertCountdownToFullFormat } from '../../services/timer';

export class ShopStore {
  public packs: Pack[] = [];
  public usersPurchases: string[] = [];

  constructor(private rootStore: RootStore) {
    makeObservable(this, {
      packs: observable,
      usersPurchases: observable,
      loadShopSuccess: action,
    });
  }

  public loadShop() {
    backend.loadShop().then(this.loadShopSuccess);
  }

  loadShopSuccess = (packs: Pack[]) => {
    this.packs = packs;
  };

  public async getUsersPurchases(): Promise<boolean> {
    const purchases = await backend.getUsersPurchaseToday(this.rootStore.userStore.user.id);
    if (this.usersPurchases.length !== purchases.length) {
      this.usersPurchases = purchases;
      return true;
    }
    return false;
  }
}
