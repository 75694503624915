import { observer } from 'mobx-react';

import { useStore } from '../../../hooks/useStore';
import { Panel } from '../../../components/Panel';
import { StrokeText } from '../../../components/StrokeText';
import { OrderPlace } from './OrderPlace';
import { IssuePlace } from './IssuePlace/IssuePlace';
import { BuyPlace } from './BuyPlace';
import { WaitingPlace } from './WaitingPlace';

export const CarFactory = observer(() => {
  const { parkingStore } = useStore();
  const { factory } = parkingStore;

  const header = <StrokeText text="Завод" />;

  const content = factory.map((place) => {
    if (place.statusPlace === 'sale') {
      return <BuyPlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'waiting') {
      return <OrderPlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'creating') {
      return <WaitingPlace key={place.idx} place={place} />;
    }
    if (place.statusPlace === 'created') {
      return <IssuePlace key={place.idx} place={place} />;
    }
    return null;
  });

  return <Panel type="factory" header={header} content={content} />;
});
