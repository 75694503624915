import { TutorialStep } from './TutorialStep';

export const steps: TutorialStep[] = [
  {
    id: '0',
    num: 0,
    typeAnimation: 'point',
    pointer: 'play-button',
    positionPointer: 'left-bottom',
    description: "Добро пожаловать в Цветной обгон! Готов стартануть? Жми  на 'Играть' и вперед!",
    typePers: 0,
    positionPers: 'left',
  },
  {
    id: '1',
    num: 1,
    typeAnimation: 'point',
    pointer: 'factory-waiting-place',
    positionPointer: 'right-bottom',
    description: 'Кликни здесь, чтобы создать новую машинку!',
    typePers: 2,
    positionPers: 'right',
  },
  {
    id: '2',
    num: 2,
    typeAnimation: 'point',
    pointer: 'factory-creating-place',
    positionPointer: 'right-bottom',
    description: 'Нажми сюда, чтобы ускорить создание!',
    typePers: 0,
    positionPers: 'right',
  },
  {
    id: '3',
    num: 3,
    typeAnimation: 'point',
    pointer: 'speedUp-button-hard',
    positionPointer: 'right-bottom',
    description: 'Первое ускорение завода бесплатно!',
  },
  {
    id: '4',
    num: 4,
    description: 'Класс! Твоя новая тачка готова к гонкам.',
    typeAnimation: 'point',
    pointer: 'play-button',
    positionPointer: 'left-bottom',
    typePers: 0,
    positionPers: 'left',
  },
  {
    id: '5',
    num: 5,
    description: 'Скомбинируй две одинаковые машинки и получи одну, но круче!',
    typeAnimation: 'move',
    positionMove: 'right-bottom',
    from: 'factory-created-place',
    to: 'merging-empty-place',
  },
  {
    id: '6',
    num: 6,
    description: 'Скомбинируй две одинаковые машинки и получи одну, но круче!',
    typeAnimation: 'move',
    positionMove: 'right-bottom',
    from: 'park-busy-place',
    to: 'merging-merge-place',
  },
  {
    id: '7',
    num: 7,
    description: 'Теперь можешь ускорить процесс комбинирования машинок. Нажми на эту иконку создания!',
    typeAnimation: 'point',
    pointer: 'merging-waiting-place',
    positionPointer: 'left-bottom',
    typePers: 1,
    positionPers: 'right',
  },
  {
    id: '8',
    num: 8,
    typeAnimation: 'point',
    pointer: 'speedUp-button-hard',
    positionPointer: 'right-bottom',
    description: 'Первое ускорение тюнинга бесплатно!',
  },
  {
    id: '9',
    num: 9,
    description: 'Отлично справился! Эта тачка теперь еще мощнее. Покажи, на что она способна на арене.',
    typeAnimation: 'point',
    pointer: 'play-button',
    positionPointer: 'left-bottom',
    typePers: 0,
    positionPers: 'left',
  },
  {
    id: '10',
    num: 10,
    description: "Ты освоил основы! Желаем крутых побед и много адреналина на арене 'Цветного обгона'!",
    typeAnimation: 'static',
    typePers: 2,
    positionPers: 'right',
  },
];
