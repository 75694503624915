import { getPointsBetweenStrict } from '../helpers';
import { Coordinate, Point } from '../types';
import { Owners } from './owners';

export type Category = 'tail' | 'zone';

export class Grid {
  grid: Point[][][];
  size: number;
  owners = new Owners();

  constructor(size: number) {
    this.grid = Array.from(Array(size), () => Array.from(Array(size), () => []));
    this.size = size;
  }

  getElems(coord: Coordinate) {
    const { x, y } = coord;
    return this.grid[x][y];
  }

  coordIsEmpty(coord: Coordinate) {
    return !this.getElems(coord).length;
  }

  coordContains(coord: Coordinate, category: Category, ownerName: string) {
    const elems = this.getElems(coord);
    for (const point of elems) {
      if (point.category === category && point.ownerName === ownerName) return true;
    }
    return false;
  }

  private clearCell(coordinate: Coordinate, category: Category, ownerName: string) {
    const coordinateName = category + ownerName;

    if (!coordinateName) {
      const cell = this.getElems(coordinate);
      cell.length = 0;
    }

    const elems = this.getElems(coordinate);
    const index = elems.findIndex((elem) => elem.getFullName() === coordinateName);
    if (index === -1) return;
    elems.splice(index, 1);
  }

  private setPosition(coord: Coordinate, category: Category, ownerName: string) {
    // Если координата пустая - записываем туда значение
    if (this.coordIsEmpty(coord)) {
      this.addPoint(coord, category, ownerName);
      return;
    }

    // Если координата уже содержит это значение - ничего не делаем
    if (this.coordContains(coord, category, ownerName)) {
      return;
    }

    // Если ничего из вышеуказанного не подходит
    // добавляем к значениям координаты текущее значение
    this.addPoint(coord, category, ownerName);
  }

  private addPoint(coord: Coordinate, category: Category, ownerName: string) {
    this.grid[coord.x][coord.y].push(new Point(category, ownerName));

    const owner = this.owners.getOwner(ownerName);
    if (!owner) return;

    if (category === 'tail') {
      owner.addTailPoints([coord]);
    }
  }

  addTailPoint(coord: Coordinate, ownerName: string) {
    const tailPoints = this.owners.getOwner(ownerName)?.tail;
    if (!tailPoints) return;
    if (tailPoints.length === 0) {
      this.setPosition(coord, 'tail', ownerName);
      return;
    }

    const start = tailPoints.at(-1);
    if (!start) return;
    const between = getPointsBetweenStrict({ start, end: coord });
    between.splice(0, 1);
    for (const coordinate of between) {
      this.setPosition(coordinate, 'tail', ownerName);
    }
  }

  clearTailPoints(ownerName: string) {
    const owner = this.owners.getOwner(ownerName);
    if (!owner) return;
    for (const coordinate of owner.tail) {
      this.clearCell(coordinate, 'tail', ownerName);
    }
    owner.clearTail();
  }

  overwriteZonePoints(coords: Coordinate[], ownerName: string) {
    const owner = this.owners.getOwner(ownerName);
    if (!owner) return;
    for (const coordinate of owner.zone) {
      this.clearCell(coordinate, 'zone', ownerName);
    }

    for (const coord of coords) {
      this.setPosition(coord, 'zone', ownerName);
    }

    owner.overwriteZone(coords);
  }

  createOwner(nameOwner: string) {
    this.owners.create(nameOwner);
  }

  removeOwner(nameOwner: string) {
    this.owners.remove(nameOwner);
  }
}
