// CONSTANTS

const gameSettings = {
  // Описание - Стартовая зона. По умолчанию 'GameScene'
  START_SCENE: 'GameScene',
  IS_LOCAL: false,

  grid: {
    SIZE: 500, // Размер сетки
  },

  entity: {
    DEFAULT_MODEL: 'retro1',
    // По умолчанию 50, измеряется в мс
    MOVEMENT_FREQUENCY: 50,
    // По умолчанию 1
    PICKUP_RADIUS: 2,
    // По умолчанию 0.06, измеряется в realPos
    MOVE_SPEED: 0.25,
    bot: {
      NUMBER: 5, // По умолчанию 3
    },
    player: {
      NUMBER: 1, // По умолчанию 1
      POINT_TO_KILL: 5,
      POINT_TO_COIN: 1,
    },
  },

  game: {
    CAMERA_RANGE: 10, // По умолчанию 10
    GROUND_RADIUS: 25, // По умолчанию 50
    MARGIN_FROM_THE_EDGE_OF_THE_MAP: 0.65, // По умолчанию 0.5
    SOUND: 0.25,
    MUSIC: 0.05,

    tail: {
      THICKNESS: 0.05, // По умолчанию 20
    },
    zone: {
      // По умолчанию 2 ↓↓↓ (в два раза больше SIZE)
      FREE_SPACE_MULTIPLIER: 2, // множитель для формирования "Чистой зоны", в этой зоне не должно быть зон и шлейфов
      POLYGONS: 60, // По умолчанию 60
      SIZE: 3, // По умолчанию 1
    },
  },

  dev: {
    BOT_MOVE: true,
    DEV_MODE: true,
    USE_LOCAL_SETTING: false,
  },
};

export default gameSettings;
