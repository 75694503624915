import { makeAutoObservable } from 'mobx';
import { ISettings } from '../../api/getSettings';

class GameSettingsStore {
  startScene = 'GameScene';
  isLocal = false;
  gridSize = 500;
  defaultModel = 'retro1';
  botNumber = 5;
  playerNumber = 3;
  pointToKill = 5;
  pointToCoin = 1;
  movementFrequency = 50;
  pickupRadius = 2;
  moveSpeed = 0.25;
  thickness = 0.05;
  zonePolygons = 60;
  zoneFreeSpaceMultiplier = 2;
  zoneSize = 3;
  cameraRange = 10;
  groundRadius = 25;
  marginFromTheEdgeOfTheMap = 0.65;
  volume = 0.25;
  botMove = true;
  devMode = true;
  useLocalSetting = true;

  constructor() {
    makeAutoObservable(this);
  }

  setPlayerNumber(value: number) {
    this.playerNumber = value;
  }

  setUseLocalSetting(value: boolean) {
    this.useLocalSetting = value;
  }

  setDevMode(value: boolean) {
    this.devMode = value;
  }

  setBotMove(value: boolean) {
    this.botMove = value;
  }

  setVolume(value: number) {
    this.volume = value;
  }

  setMarginFromTheEdgeOfTheMap(value: number) {
    this.marginFromTheEdgeOfTheMap = value;
  }

  setGroundRadius(value: number) {
    this.groundRadius = value;
  }

  setCameraRange(value: number) {
    this.cameraRange = value;
  }

  setZoneSize(value: number) {
    this.zoneSize = value;
  }

  setZoneFreeSpaceMultiplier(value: number) {
    this.zoneFreeSpaceMultiplier = value;
  }

  setZonePolygons(value: number) {
    this.zonePolygons = value;
  }

  setThickness(value: number) {
    this.thickness = value;
  }

  setMoveSpeed(value: number) {
    this.moveSpeed = value;
  }

  setPickupRadius(value: number) {
    this.pickupRadius = value;
  }

  setMovementFrequency(value: number) {
    this.movementFrequency = value;
  }

  setPointToCoin(value: number) {
    this.pointToCoin = value;
  }

  setPointToKill(value: number) {
    this.pointToKill = value;
  }

  setBotNumber(value: number) {
    this.botNumber = value;
  }

  setDefaultModel(value: string) {
    this.defaultModel = value;
  }

  setGridSize(value: number) {
    this.gridSize = value;
  }

  setIsLocal(value: boolean) {
    this.isLocal = value;
  }

  setStartScene(value: string) {
    this.startScene = value;
  }

  generateJson() {
    return {
      startScene: this.startScene,
      isLocal: this.isLocal,
      gridSize: this.gridSize,
      defaultModel: this.defaultModel,
      botNumber: this.botNumber,
      playerNumber: this.playerNumber,
      pointToKill: this.pointToKill,
      pointToCoin: this.pointToCoin,
      movementFrequency: this.movementFrequency,
      pickupRadius: this.pickupRadius,
      moveSpeed: this.moveSpeed,
      thickness: this.thickness,
      zonePolygons: this.zonePolygons,
      zoneFreeSpaceMultiplier: this.zoneFreeSpaceMultiplier,
      zoneSize: this.zoneSize,
      cameraRange: this.cameraRange,
      groundRadius: this.groundRadius,
      marginFromTheEdgeOfTheMap: this.marginFromTheEdgeOfTheMap,
      volume: this.volume,
      botMove: this.botMove,
      devMode: this.devMode,
      useLocalSetting: this.useLocalSetting,
    };
  }

  applyJson(json: ISettings) {
    Object.assign(this, json);
  }
}

export const storeSettings = new GameSettingsStore();
