import * as BABYLON from '@babylonjs/core';
import { PBRMaterial } from '@babylonjs/core';

export class Skybox {
  static async fromModelFile(fileName: string) {
    const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', 'environment/skyboxes/', fileName);
    const skybox = meshes[1];
    skybox.scaling.x = 200;
    skybox.scaling.z = 200;
    skybox.scaling.y = 200;

    skybox.position.x = -30;
    skybox.position.y = 30;
    skybox.position.z = -100;

    if (skybox.material) {
      const { albedoTexture } = skybox.material as PBRMaterial;
      if (albedoTexture) {
        albedoTexture.level = 1.6;
      }
    }

    const { parent } = skybox;
    skybox.setParent(null);
    parent?.dispose(false, true);

    return skybox;
  }
}
