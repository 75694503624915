import * as BABYLON from '@babylonjs/core';
import { Mesh, Scene, Sound } from '@babylonjs/core';
import { gameStore } from '../../stores/gameStore';
import { syncLoadCars } from './car';
import { createArcCamera } from './createArcCamera';
import { createBucksModel } from './createBucksModel';
import { createCoinModel } from './createCoinModel';
import { createCrown } from './createCrown';
import { createCrushSprite, createDisposeEffect } from './createDeathEffects';
import { createLight } from './createLight';
import { createShadow } from './createShadow';
import { createSuperMagnet } from './createSuperMagnet';
import { loadCharacters } from './loadCharacters';
import { optimizationMesh } from './optimizationMesh';

interface IOptions {
  attachControl: boolean;
}

export async function createBaseElements(scene: Scene, allowedCars: string[], options?: IOptions) {
  const meshForOptimization: Array<Mesh> = [];

  const { attachControl = false } = options ?? {};

  const light = createLight(scene);
  const camera = createArcCamera();
  if (attachControl) {
    const canvas = scene.getEngine().getRenderingCanvas();
    camera.attachControl(canvas, true);
  }

  createShadow('baseCarShadow', { width: 0.7, height: 1.1 });
  createBucksModel();

  const disposeEffect = createDisposeEffect('steam.gif');
  meshForOptimization.push(disposeEffect);

  const crown = await createCrown();
  meshForOptimization.push(crown);

  const superMagnet = await createSuperMagnet();
  meshForOptimization.push(superMagnet);

  const crushSprite = createCrushSprite('skull.png');
  if (crushSprite.material) {
    crushSprite.material.freeze();
  }
  meshForOptimization.push(crushSprite);

  await createCoinModel('coin');

  await syncLoadCars(allowedCars, scene);

  await loadCharacters();

  for (const mesh of meshForOptimization) {
    optimizationMesh(mesh);
  }

  const { musicVolume } = gameStore;
  const music = new Sound('faster', 'music/faster.mp3', scene, null, {
    volume: musicVolume,
    loop: true,
    autoplay: true,
  });

  const pipeline = new BABYLON.DefaultRenderingPipeline('basePipeline', true, scene, [camera]);
  pipeline.samples = pipeline.samples === 1 ? 16 : 1;
  pipeline.imageProcessingEnabled = false;

  window.addEventListener('blur', () => {
    if (scene.isDisposed) return;
    music.pause();
  });

  window.addEventListener('focus', () => {
    if (scene.isDisposed) return;
    music.play();
  });

  return { camera, light, music, defaultPipeline: pipeline };
}
