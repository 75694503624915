import { AbstractMesh, Animation, Curve3, Scene, Vector3 } from '@babylonjs/core';

function calculateControlPoint(p1: Vector3, p3: Vector3, y: number): Vector3 {
  // вычисляем координаты x и z для второй точки
  const x = (p3.x - p1.x) / 2 + p1.x;
  const z = (p3.z - p1.z) / 2 + p1.z;

  return new Vector3(x, y, z);
}

export function onCurveAnim(scene: Scene, object: AbstractMesh, curvePoints: Vector3[], callback?: () => void, speedModifier = 1) {
  const NUMBER_OF_FRAMES = 40;

  // создание кривой Безье
  const curve = Curve3.CreateQuadraticBezier(
    curvePoints[0],
    calculateControlPoint(curvePoints[0], curvePoints[1], 4),
    curvePoints[1],
    NUMBER_OF_FRAMES,
  );

  // установка начальной позиции объекта
  const [firstCurvePoint] = curvePoints;
  object.position = firstCurvePoint;

  // создание анимации
  const animation = new Animation('animation', 'position', 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);

  // создание ключевых кадров
  const keys = [];
  for (let i = 0; i <= NUMBER_OF_FRAMES; i++) {
    const position = curve.getPoints()[i];
    keys.push({
      frame: i,
      value: position,
    });
  }

  // добавление ключевых кадров к анимации
  animation.setKeys(keys);

  // запуск анимации
  object.animations.push(animation);
  return scene.beginAnimation(object, 0, 100, false, speedModifier, callback);
}
