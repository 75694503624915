import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DndProvider, MouseTransition, TouchTransition } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import ReactDOM from 'react-dom/client';

import App from './App';
import { StoreProvider } from './contexts/storeContext';

import './index.css';

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
      skipDispatchOnTransition: true,
    },
  ],
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <DndProvider options={HTML5toTouch}>
        <App />
      </DndProvider>
    </StoreProvider>
  </React.StrictMode>,
);
