import { makeAutoObservable } from 'mobx';
import { BaseAgent } from '../../multiplayer/agent';
import { gameStore } from '../../stores/gameStore';
import { Real } from '../../types/points/real';

export interface IEntity {
  distance: number;
  angle: number;
  color: { r: number; g: number; b: number };
  isAlive: boolean;
  isKing: boolean;
}

class PointersStore {
  entity: Map<string, IEntity> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  calculateNear(entityArray: BaseAgent[], currentPosition: Real) {
    for (const entity of entityArray) {
      if (entity.name === 'Ты') continue;
      const transformedEntity = this.transformEntity(entity, currentPosition);
      if (this.entity.has(entity.name)) {
        const oldAngle = this.entity.get(entity.name)?.angle as number;
        const newAngle = transformedEntity.angle;
        const shortestAngle = this.shortestAngle(oldAngle, newAngle);
        transformedEntity.angle = oldAngle + shortestAngle;
        this.entity.set(entity.name, transformedEntity);
      } else {
        this.entity.set(entity.name, transformedEntity);
      }
    }
  }

  transformEntity(entity: BaseAgent, currentPosition: Real): IEntity {
    const distance = entity.getPos().distanceTo(currentPosition);
    const angle = this.calculateAngle(currentPosition, entity.getPos());
    const color = { r: entity.color.r, g: entity.color.g, b: entity.color.b };
    const { isAlive } = entity;
    const isKing = entity.id === gameStore.idKing;
    return { angle, distance, color, isAlive, isKing };
  }

  calculateAngle(firstPoint: Real, secondPoint: Real) {
    // Расчет разницы в координатах X и Y между двумя точками
    const dx = secondPoint.x - firstPoint.x;
    const dy = secondPoint.y - firstPoint.y;

    // Расчет угла в радианах с помощью функции арктангенса
    return Math.atan2(dx, dy);
  }

  shortestAngle(oldAngle: number, newAngle: number): number {
    let diff = newAngle - oldAngle;
    if (diff < -Math.PI) diff += 2 * Math.PI;
    if (diff > Math.PI) diff -= 2 * Math.PI;
    return diff;
  }

  clearEntityArray(){
    this.entity.clear();
  }
}

export const pointersStore = new PointersStore();
