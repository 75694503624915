// import { useStore } from '../../hooks/useStore';
import { CloseButton } from '../../components/CloseButton';
import { ModalWindow } from '../../components/ModalWindow';
import { StrokeText } from '../../components/StrokeText';
import amp from '../../services/amplitude/Amplitude';
import { SimplePacks } from './SimplePacks';
// import { SpecialOffer } from './SpecialOffer';
import style from './style.module.scss';

// import { SpecialPack } from './SpecialPack';

export function Shop() {
  // const { shopStore } = useStore();
  // const specialPacks = shopStore.specialPacks.map((pack) => <SpecialPack key={pack.id} pack={pack} />);

  amp.openedStore();

  return (
    <ModalWindow>
      <div className={style.container}>
        <header className={style.header}>
          <div className={style.header_container}>
            <div className={style.header__title}>
              <StrokeText style={style.header__title__text} text="Магазин" />
            </div>
          </div>
          <div className={style.header__extBg} />
        </header>
        <div className={style.close_container}>
          <CloseButton color="blue" />
        </div>
        <div className={style.content}>
          <div className={style.content_container}>
            <SimplePacks />
            {/* <SpecialOffer /> */}
            {/* {specialPacks} */}
          </div>
        </div>
      </div>
    </ModalWindow>
  );
}
