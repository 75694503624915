import { Vector2, Vector3 } from '@babylonjs/core';
import gameSettings from '../../../settings/gameSettings';

export class BasePoint {
  protected sizeArray = gameSettings.grid.SIZE;
  protected halfSize = this.sizeArray / 2;
  protected step = this.sizeArray / gameSettings.game.GROUND_RADIUS / 2;

  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  static fromV2(vector: Vector2) {
    return this.constructor(vector.x, vector.y);
  }

  static fromV3(vector: Vector3) {
    return this.constructor(vector.x, vector.z);
  }

  toV2() {
    return new Vector2(this.x, this.y);
  }

  toV3(y = 0) {
    return new Vector3(this.x, y, this.y);
  }

  toObj() {
    return { x: this.x, y: this.y };
  }

  distanceTo(point: BasePoint) {
    return Math.sqrt((this.x - point.x) ** 2 + (this.y - point.y) ** 2);
  }

  toArray() {
    return [this.x, this.y];
  }
}
