import { AbstractMesh, Mesh } from '@babylonjs/core';
import { Ground } from './ground';
import { Skybox } from './skybox';

export async function createLocation(location: string) {
  console.log(location);
  const meshes = await Ground.fromImg(`${location}.glb`);
  const skybox = await Skybox.fromModelFile(`${location}.glb`);
  const [root] = meshes.getChildren() as Mesh[];
  const [fence, ground] = root.getChildMeshes();
  ground.setParent(null);
  fence.setParent(null);
  meshes.dispose();
  ground.name = 'ground';
  fence.name = 'fence';
  skybox.name = 'skybox';

  for (const mesh of [ground, fence, skybox]) {
    mesh.checkCollisions = false;
    mesh.isPickable = false;
    mesh.material?.freeze();
    mesh.freezeWorldMatrix();
    mesh.doNotSyncBoundingInfo = true;
    mesh.alwaysSelectAsActiveMesh = true;
    mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY;
  }
}
