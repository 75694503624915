import { Panel } from '@vkontakte/vkui';
import * as earcut from 'earcut';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../babylon/components';
import { GameScene } from '../babylon/scens';
import { Bonuses } from '../babylon/scens/tests/bonuses/Bonuses';
import { BucksTest } from '../babylon/scens/tests/bucks/bucksTest';
import { DeadEffects } from '../babylon/scens/tests/deadEffects/deadEffects';
import { Rename } from '../babylon/scens/tests/forCopy/Rename';
import { GapsFix } from '../babylon/scens/tests/gaps/gapsFix';
import { testAnimState } from '../babylon/scens/tests/testStateAnim/testAnimState';
import { gameStore } from '../babylon/stores/gameStore';
import GameWindow from '../blocks/gameWindow';
import Ui from '../blocks/UI/ui';
import { useStore } from '../hooks/useStore';
import amp from '../services/amplitude/Amplitude';
import gameSettings from '../settings/gameSettings';
import { IScenes } from './interfaces/IScenes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.earcut = earcut;

function Game({ id }: IProps) {
  const [showCase, setShowCase] = useState(gameSettings.START_SCENE);
  const navigate = useNavigate();
  const { userStore, carsStore, parkingStore, modalWindowStore, tutorialStore } = useStore();
  const { user, userPlatformData } = userStore;
  gameStore.setModel(carsStore.selectedCar?.car.model.split('/')[1] || 'buggy1');
  gameStore.setCarId(carsStore.selectedCar?.id ?? '');
  gameStore.setLevel(carsStore.selectedCar?.car.lvl || 1);
  gameStore.setUserName(userPlatformData?.firstName ?? '');
  gameStore.setPhoto(userPlatformData?.avatar ?? '');
  gameStore.setId(user.id);

  const onGoHome = () => {
    console.log({ userTutorStep: user.tutorStep });
    if (user.tutorStep !== undefined && user.tutorStep < tutorialStore.steps.length) {
      tutorialStore.setStep(user.tutorStep + 1);
    }
    userStore.loadUserData();
    switch (gameStore.errorCode) {
      case 4000:
        amp.failedTryPlay();
        gameStore.setErrorCode(null);
        modalWindowStore.setHeader('Сервер не доступен');
        modalWindowStore.setText('Приносим глубочайшие извинения. Мы уже работает над решением данной проблемы. Пожалуйста, попробуйте позже.');
        navigate('/tip');
        break;
      case 4001:
        gameStore.setErrorCode(null);
        modalWindowStore.setHeader('Такой игрок уже в игре');
        modalWindowStore.setText('Пожалуйста, убедитесь, что вы уже не находить в игре. Возможно вы играете через другое устройство или в другой.'
          + ' вкладке.');
        navigate('/tip');
        break;
      case 4002:
        gameStore.setErrorCode(null);
        navigate('/nofuel');
        break;
      default:
        navigate('/');
    }

    parkingStore.loadParking(user.id);
    carsStore.loadCarsByUser(user.id);
  };

  gameStore.setGoHome(onGoHome);

  const scenes: IScenes = {
    GameScene,
    Rename,
    GapsFix,
    DeadEffects,
    testAnimState,
    Bonuses,
    Bucks: BucksTest,
  };

  return (
    <Panel id={id}>
      <Observer>{() => <>{gameStore.isLoading && <Loader />}</>}</Observer>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <GameWindow
          adaptToDeviceRatio
          engineOptions={{ limitDeviceRatio: true, renderEvenInBackground: true }}
          onSceneReady={scenes[showCase]}
          setShowCase={setShowCase}
          id="my-canvas"
        />
        <Observer>{() => <>{!gameStore.isLoading && <Ui />}</>}</Observer>
      </div>
    </Panel>
  );
}

interface IProps {
  id: string;
}

export default Game;
