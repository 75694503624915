import classNames from 'classnames';
import { observer } from 'mobx-react';
import managerApi from '../../../../api/managerApi';
import { StrokeText } from '../../../../components/StrokeText';
import { useStore } from '../../../../hooks/useStore';
import amp from '../../../../services/amplitude/Amplitude';
import { Pack } from '../../../../stores/ShopStore';

import style from './style.module.scss';

export interface SimplePackProps {
  pack: Pack;
}

export const SimplePack = observer(({ pack }: SimplePackProps) => {
  const { shopStore, userStore } = useStore();

  const iconClass = classNames({
    [style.icon_soft_min]: pack.type_money === 'soft' && pack.money < 100,
    [style.icon_soft_average]: pack.type_money === 'soft' && !(pack.money < 100 || pack.money > 1000),
    [style.icon_soft_max]: pack.type_money === 'soft' && pack.money > 1000,
    [style.icon_hard_min]: pack.type_money === 'hard' && pack.money < 100,
    [style.icon_hard_average]: pack.type_money === 'hard' && !(pack.money < 100 || pack.money > 1000),
    [style.icon_hard_max]: pack.type_money === 'hard' && pack.money > 1000,
  });

  const priceIconClass = classNames(style.price__icon, {
    [style.price__tg_icon]: managerApi.platform === 'tg',
    [style.price__vk_icon]: managerApi.platform === 'vk',
  });

  const onPay = () => {
    const showShoppingWindowPromise =
      managerApi.platform === 'tg'
        ? managerApi.showShoppingWindow(pack.item_id, pack.price, pack.title, pack.photo_url)
        : managerApi.showShoppingWindow(pack.item_id);
    showShoppingWindowPromise
      .then((data) => {
        console.log('data is', data);
        console.log('VKWebAppShowOrderBox: result', data);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        console.log(!data.success);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!data.success) return;
        console.log('data is success');

        amp.buyInStore(pack.type_money, pack.money, pack.price);

        let pool: NodeJS.Timeout | null = null;
        pool = setInterval(async () => {
          const isPay = await shopStore.getUsersPurchases();
          if (!isPay) return;

          if (pool) clearInterval(pool);
          await userStore.loadUserData();
        }, 1500);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  return (
    <div className={style.container}>
      <span className={iconClass} />
      <div className={style.count_container}>
        {pack.type_money === 'soft' && <StrokeText style={style.count_soft} text={pack.money} />}
        {pack.type_money === 'hard' && <StrokeText style={style.count_hard} text={pack.money} />}
      </div>
      <button type="button" className={style.price__button} onClick={onPay}>
        <StrokeText style={style.price__value} text={pack.price} />
        <span className={priceIconClass} />
      </button>
      {/* {purchase === 'rewarded' && !paid && (
        <button type="button" className={style.rewarded__button}>
          <StrokeText style={style.rewarded__value} text="смотреть" />
          {!paid && <span className={style.rewarded__icon} />}
        </button>
      )} */}
      {/* {shopStore.usersPurchases.includes(pack.item_id) && (
        <span className={style.paid}>
          <StrokeText style={style.paid__text} text="куплено" />
        </span>
      )} */}
      {/* {shopStore.usersPurchases.includes(pack.item_id) && <span className={style.blocked} />} */}
    </div>
  );
});
