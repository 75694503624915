import { AbstractMesh } from '@babylonjs/core';

interface IOptions {
  dontIsPickable?: boolean;
  dontCheckCollisions?: boolean;
  dontFreezeWorldMatrix?: boolean;
  dontDoNotSyncBoundingInfo?: boolean;
  dontAlwaysSelectAsActiveMesh?: boolean;
  dontCullingStrategy?: boolean;
  dontFreezeMaterial?: boolean;
}

export function optimizationMesh(
  mesh: AbstractMesh,
  cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY,
  options?: IOptions,
) {
  // Optimistic Inclusion Then Bounding Sphere Only - This can be the fastest test when a cullable object is expected to be almost always in the camera frustum
  const {
    dontIsPickable = false,
    dontCheckCollisions = false,
    dontFreezeWorldMatrix = false,
    dontDoNotSyncBoundingInfo = false,
    dontAlwaysSelectAsActiveMesh = false,
    dontCullingStrategy = false,
    dontFreezeMaterial = false,
  } = options ?? {};

  if (!dontIsPickable) mesh.isPickable = false;
  if (!dontCheckCollisions) mesh.checkCollisions = true;
  if (!dontFreezeWorldMatrix) mesh.freezeWorldMatrix();
  if (!dontDoNotSyncBoundingInfo) mesh.doNotSyncBoundingInfo = true;
  if (!dontAlwaysSelectAsActiveMesh) mesh.alwaysSelectAsActiveMesh = true;
  if (!dontCullingStrategy) mesh.cullingStrategy = cullingStrategy;
  if (!dontFreezeMaterial) mesh.material?.freeze();
}
