export function decay(initialValue: number): number {
  const min = 0.01;
  const decayRate = 0.7;
  const max = 0.3;
  const nextValue = initialValue - initialValue * decayRate;

  // если следующее значение меньше минимального, возвращаем минимальное
  if (nextValue < min) {
    return min;
  }

  if (nextValue > max) {
    return max;
  }

  return +nextValue.toFixed(2);
}
