import * as BABYLON from '@babylonjs/core';
import { AbstractMesh, Mesh, Scene } from '@babylonjs/core';

export function resizeAnim(object: Mesh | AbstractMesh, startSize: number, endSize: number, speed: number, scene: Scene, callback?: () => void) {
  const scaleXAnimation = new BABYLON.Animation(
    'scaleXAnimation',
    'scaling.x',
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
  );

  scaleXAnimation.setKeys([{
    frame: 0,
    value: startSize,
  }, {
    frame: 60,
    value: endSize,
  }, {
    frame: 120,
    value: startSize,
  }]);

  const scaleYAnimation = new BABYLON.Animation(
    'scaleYAnimation',
    'scaling.y',
    30,
    BABYLON.Animation.ANIMATIONTYPE_FLOAT,
    BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
  );

  scaleYAnimation.setKeys([{
    frame: 0,
    value: startSize,
  }, {
    frame: 60,
    value: endSize,
  }, {
    frame: 120,
    value: startSize,
  }]);

  object.animations = [scaleXAnimation, scaleYAnimation];
  return scene.beginAnimation(object, 0, 120, false, speed, callback);
}
