interface IEntityColor {
  name: string;
  color: { r: number; g: number; b: number };
  isActive: boolean;
}

export class EntityColors {
  static colors: IEntityColor[] = [
    { name: 'Оранжевый', isActive: true, color: { r: 255 / 255, g: 170 / 255, b: 0 } },
    { name: 'Морозное небо', isActive: true, color: { r: 0 / 255, g: 170 / 255, b: 255 / 255 } },
    { name: 'Синий Градуса', isActive: true, color: { r: 0 / 255, g: 110 / 255, b: 255 / 255 } },
    { name: 'Маджента', isActive: true, color: { r: 195 / 255, g: 0 / 255, b: 255 / 255 } },
    { name: 'Пурпурная пицца', isActive: true, color: { r: 255 / 255, g: 0 / 255, b: 196 / 255 } },
    { name: 'Карминово-красный', isActive: true, color: { r: 255 / 255, g: 0 / 255, b: 85 / 255 } },
    { name: 'Насыщенный оранжевый', isActive: true, color: { r: 255 / 255, g: 116 / 255, b: 36 / 255 } },
    { name: 'Голубой колокольчик', isActive: true, color: { r: 164 / 255, g: 165 / 255, b: 200 / 255 } },
    { name: 'Насыщенный розовый', isActive: true, color: { r: 218 / 255, g: 123 / 255, b: 126 / 255 } },
    { name: 'Умеренный желтовато-зеленый', isActive: true, color: { r: 95 / 255, g: 155 / 255, b: 83 / 255 } },
    { name: 'Темное зеленое море', isActive: true, color: { r: 141 / 255, g: 188 / 255, b: 133 / 255 } },
    { name: 'Желтого школьного автобуса', isActive: true, color: { r: 255 / 255, g: 220 / 255, b: 0 / 255 } },
    { name: 'Зеленая лужайка', isActive: true, color: { r: 120 / 255, g: 255 / 255, b: 0 / 255 } },
    { name: 'Ярко-зеленый', isActive: true, color: { r: 53 / 255, g: 212 / 255, b: 0 / 255 } },
    { name: 'Ярко-фиолетовый', isActive: true, color: { r: 185 / 255, g: 0 / 255, b: 215 / 255 } },
    { name: 'Светло ярко-фиолетовый', isActive: true, color: { r: 217 / 255, g: 0 / 255, b: 230 / 255 } },
    { name: 'Радикальный красный', isActive: true, color: { r: 255 / 255, g: 91 / 255, b: 109 / 255 } },
    { name: 'Кобальтовый', isActive: true, color: { r: 0 / 255, g: 86 / 255, b: 218 / 255 } },
    { name: 'Ярко-сиреневый', isActive: true, color: { r: 207 / 255, g: 174 / 255, b: 255 / 255 } },
    { name: 'Глубокая фуксия', isActive: true, color: { r: 183 / 255, g: 80 / 255, b: 199 / 255 } },
    { name: 'Розовый фламинго', isActive: true, color: { r: 255 / 255, g: 117 / 255, b: 223 / 255 } },
    { name: 'Мятный', isActive: true, color: { r: 61 / 255, g: 255 / 255, b: 141 / 255 } },
    { name: 'Лимонный', isActive: true, color: { r: 247 / 255, g: 255 / 255, b: 15 / 255 } },
    { name: 'Георгиново-желтый', isActive: true, color: { r: 255 / 255, g: 204 / 255, b: 5 / 255 } },
    { name: 'Сине-лиловый', isActive: true, color: { r: 141 / 255, g: 50 / 255, b: 244 / 255 } },
    { name: 'Аква', isActive: true, color: { r: 0 / 255, g: 154 / 255, b: 218 / 255 } },
    { name: 'Фиалковый', isActive: true, color: { r: 217 / 255, g: 134 / 255, b: 255 / 255 } },
    { name: 'Голубой Крайола', isActive: true, color: { r: 142 / 255, g: 219 / 255, b: 214 / 255 } },
    { name: 'Электрик', isActive: true, color: { r: 99 / 255, g: 232 / 255, b: 255 / 255 } },
  ];

  private static shuffle(array: IEntityColor[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  static getFreeColor() {
    this.shuffle(this.colors);
    const freeColor = this.colors.find((material) => !material.isActive);
    if (!freeColor) throw Error('Свободного цвета нет');
    freeColor.isActive = true;
    return freeColor;
  }

  static getColorFromName(name: string, activated = false) {
    const color = this.colors.find((material) => material.name === name);
    if (!color) return undefined;
    color.isActive = activated ? true : color?.isActive;
    return color;
  }

  static clearLabels() {
    for (const materialElement of this.colors) {
      materialElement.isActive = false;
    }
  }

  static activateColor(name: string) {
    const foundMaterial = EntityColors.getColorFromName(name);
    if (!foundMaterial) return;
    foundMaterial.isActive = true;
  }
}
