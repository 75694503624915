import { Mesh, Scene } from '@babylonjs/core';

import { carList } from './carList';
import { loadCar } from './loadCar';

async function loadAllCars(scene: Scene) {
  const carsModel: Mesh[] = [];

  for (const key in carList) {
    if (!Object.prototype.hasOwnProperty.call(carList, key)) continue;
    const category = key;
    const models = carList[key];
    for (let i = 0; i < models.length; i++) {
      const modelName = models[i];
      // eslint-disable-next-line no-await-in-loop
      const car = await loadCar(modelName, category, scene);
      carsModel.push(car);
    }
  }
  return carsModel;
}

async function loadCars(cars: string[], scene: Scene) {
  const carsModel: Mesh[] = [];

  for (const carName of cars) {
    for (const key in carList) {
      if (!Object.prototype.hasOwnProperty.call(carList, key)) continue;
      const category = key;
      const models = carList[key];
      if (!models.includes(carName)) continue;
      // eslint-disable-next-line no-await-in-loop
      const car = await loadCar(carName, category, scene);
      carsModel.push(car);
    }
  }

  return carsModel;
}

export async function syncLoadCars(cars: string[], scene: Scene) {
  let carsModel;
  if (!cars.length) {
    console.log('Будут загружены все машины');
    carsModel = await loadAllCars(scene);
  } else {
    carsModel = await loadCars(cars, scene);
  }

  return carsModel;
}
