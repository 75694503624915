import { AbstractMesh } from '@babylonjs/core';

export function getSize(parent: AbstractMesh, useHierarchy = true) {
  let minimum;
  let maximum;

  if (useHierarchy) {
    const sizes = parent.getHierarchyBoundingVectors();
    minimum = sizes.min;
    maximum = sizes.max;
  } else {
    const sizes = parent.getBoundingInfo().boundingBox;
    minimum = sizes.minimum;
    maximum = sizes.maximum;
  }

  // Calculate the dimensions of the mesh
  const width = maximum.x - minimum.x;
  const height = maximum.y - minimum.y;
  const depth = maximum.z - minimum.z;

  return {
    x: width,
    y: height,
    z: depth,
  };
}
