import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import plusIcon from '../../../../assets/images/factory/icon-plus.png';
import { CoinsCount } from '../../../../components/CoinsCount';
// import { FactoryPlaceProps } from '../FactoryPlaceProps';
import { Place } from '../../../../components/Place';
import { useStore } from '../../../../hooks/useStore';
import { FactoryPlaceProps } from '../../../../stores/ParkingStore/types';

import style from './style.module.scss';

export const BuyPlace = observer(({ place }: { place: FactoryPlaceProps }) => {
  const { userStore, parkingStore, tutorialStore } = useStore();
  const { user } = userStore;
  const navigate = useNavigate();
  const status = !place.isNoBuy && user.soft >= place.pricePlace ? 'active' : 'inactive';
  const onBuy = async () => {
    if (user.soft - place.pricePlace < 0) {
      navigate('/shop');
      return;
    }

    parkingStore.buyPlace(userStore.user.id, 'factory', place.idx).then(() => {
      if (tutorialStore.currentStepIdx === 2) tutorialStore.nextStep();
    });
  };

  const iconLockClass = classNames(style.icon, {
    [style.icon_noBuy]: status === 'inactive',
    [style.icon_small]: place.statusPlace === 'sale' && !place.isNoBuy,
    [style.icon_lock]: place.isNoBuy,
    [style.icon_small_noBuy]: !place.isNoBuy,
  });

  return (
    <Place type="factory" status="inactive">
      <span className={iconLockClass} />
      {!place.isNoBuy && (
      <>
        <CoinsCount count={place.pricePlace} place="factory" />
        <button type="button" className={style.plus} onClick={onBuy}>
          {place.pricePlace && <img src={plusIcon} alt="add place" />}
        </button>
      </>
      )}
    </Place>
  );
});
