import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { setSettings } from '../../api/setSettings';
import { Block, Checkbox, Field } from './block';
import { storeSettings } from './storeSettings';
import style from './style.module.sass';

export const GameSettings = observer(() => {
  const navigate = useNavigate();
  const username = localStorage.getItem('admin_login');
  const password = localStorage.getItem('admin_password');
  const isUserAvailable = username === process.env.REACT_APP_ADMIN_PANEL_LOGIN && password === process.env.REACT_APP_ADMIN_PANEL_PASSWORD;
  if (!isUserAvailable) {
    return <Navigate to="/" />;
  }

  const onSave = async () => {
    const json = storeSettings.generateJson();
    const status = await setSettings(json);
    if (status >= 200 && status < 300) {
      alert('Сохранено');
      return;
    }
    alert('Не удалось сохранить');
  };

  const onDefault = async () => {
    storeSettings.applyJson({
      startScene: 'GameScene',
      isLocal: false,
      gridSize: 500,
      defaultModel: 'retro1',
      botNumber: 5,
      playerNumber: 3,
      pointToKill: 5,
      pointToCoin: 1,
      movementFrequency: 50,
      pickupRadius: 2,
      moveSpeed: 0.25,
      thickness: 0.05,
      zonePolygons: 60,
      zoneFreeSpaceMultiplier: 2,
      zoneSize: 3,
      cameraRange: 10,
      groundRadius: 25,
      marginFromTheEdgeOfTheMap: 0.65,
      volume: 0.25,
      botMove: true,
      devMode: true,
      useLocalSetting: true,
    });

    alert('Установлено по умолчанию, но не сохраненно');
  };

  const onGoHome = () => {
    navigate('/');
  };

  return (
    <div className={style.main}>
      <Block name="Базовые настройки">
        <Field
          name="Начальная сцена"
          value={storeSettings.startScene}
          setter={storeSettings.setStartScene.bind(storeSettings)}
          tip="Сцена, с которой начинается игра"
        />
      </Block>

      <Block name="Сетка">
        <Field
          name="Размер сетки"
          value={storeSettings.gridSize}
          setter={storeSettings.setGridSize.bind(storeSettings)}
          tip="Размер сетки в отдельных юнитах не привязанные к карте"
        />
      </Block>

      <Block name="Сущности">
        <Field
          name="Стандартная модель"
          value={storeSettings.defaultModel}
          setter={storeSettings.setDefaultModel.bind(storeSettings)}
          tip="Модель с которой заспавниться игрок, если выбранную не удалось загрузить"
        />
        <Field
          name="Частота движения"
          value={storeSettings.movementFrequency}
          setter={storeSettings.setMovementFrequency.bind(storeSettings)}
          tip="С какой скоростью сервер будет двигать сущности. Указывается в миллисекундах. Чем больше значение, тем меньше сервер будет отправлять на клиенты данные"
        />
        <Field
          name="Радиус подбора"
          value={storeSettings.pickupRadius}
          setter={storeSettings.setPickupRadius.bind(storeSettings)}
          tip="Радиус подбора монет. В юнитах"
        />
        <Field
          name="Скорость движения"
          value={storeSettings.moveSpeed}
          setter={storeSettings.setMoveSpeed.bind(storeSettings)}
          tip="Скорость движения сущностей. В юнитах, за цикл частоты движения. К примеру, за 50 мс - 0.25 юнитов"
        />

        <Block name="Боты">
          <Field
            name="Количество ботов"
            value={storeSettings.botNumber}
            setter={storeSettings.setBotNumber.bind(storeSettings)}
            tip="Кол-во ботов в одной комнате"
          />
        </Block>

        <Block name="Игрок">
          <Field
            name="Количество игроков"
            value={storeSettings.playerNumber}
            setter={storeSettings.setPlayerNumber.bind(storeSettings)}
            tip="Кол-во игроков в одной комнате"
          />
          <Field
            name="Монет за убийство"
            value={storeSettings.pointToKill}
            setter={storeSettings.setPointToKill.bind(storeSettings)}
            tip="Сколько игрок получит за убийство"
          />
          <Field
            name="Монет за подбор монет"
            value={storeSettings.pointToCoin}
            setter={storeSettings.setPointToCoin.bind(storeSettings)}
            tip="Сколько игрок получит за подбор монеты"
          />
        </Block>
      </Block>

      <Block name="Игра">
        <Field
          name="Отдаление камеры"
          value={storeSettings.cameraRange}
          setter={storeSettings.setCameraRange.bind(storeSettings)}
          tip="На сколько первоначальна будет отдалена камера от игрока. Указывается в юнитах"
        />
        <Field
          name="Размер арены"
          value={storeSettings.groundRadius}
          setter={storeSettings.setGroundRadius.bind(storeSettings)}
          tip="Размер арены в юнитах"
        />
        <Field
          name="Отклонение от края карты"
          value={storeSettings.marginFromTheEdgeOfTheMap}
          setter={storeSettings.setMarginFromTheEdgeOfTheMap.bind(storeSettings)}
          tip="Через сколько игрока начнёт отталкивать от границы карты, грубо говоря отступ от границы карты. Указывается в юнитах."
        />
        <Field
          name="Громкость"
          value={storeSettings.volume}
          setter={storeSettings.setVolume.bind(storeSettings)}
          tip="Громкость воспроизведения всех звуков и музыки"
        />

        <Block name="Зона">
          <Field
            name="Множитель чистой зоны"
            value={storeSettings.zoneFreeSpaceMultiplier}
            setter={storeSettings.setZoneFreeSpaceMultiplier.bind(storeSettings)}
            tip="Сколько свободного пространства должно быть для формирования зоны относительно стандартной зоны игрока. Например, если обычная зона 2, а множитель 2,  то чистая зона: 2*2=4, то есть в этом радиусе не должно быть чужой зоны,   чужого шлейфа и других игроков. Если такой зоны не будет найдено, то игрок    заспавниться в случайном углу карты вырезав себе место"
          />
          <Field
            name="Количество полигонов"
            value={storeSettings.zonePolygons}
            setter={storeSettings.setZonePolygons.bind(storeSettings)}
            tip={'Сколько вершин будет у "круга" начальной зоны'}
          />
          <Field name="Размер" value={storeSettings.zoneSize} setter={storeSettings.setZoneSize.bind(storeSettings)} tip="Радиус зоны в юнитах" />
        </Block>

        <Block name="Шлейф">
          <Field
            name="Толщина"
            value={storeSettings.thickness}
            setter={storeSettings.setThickness.bind(storeSettings)}
            tip="Толщина шлейфа в отдельных единицах"
          />
        </Block>
      </Block>

      <Block name="Разработка">
        <Checkbox name="Боты двигаются" value={storeSettings.botMove} setter={storeSettings.setBotMove.bind(storeSettings)} />
        <Checkbox name="Dev hud" value={storeSettings.devMode} setter={storeSettings.setDevMode.bind(storeSettings)} />
        <Checkbox
          name="Использовать локальные настройки"
          value={storeSettings.useLocalSetting}
          setter={storeSettings.setUseLocalSetting.bind(storeSettings)}
        />
      </Block>

      <div className={style.controller}>
        <button type="button" onClick={onDefault}>
          По умолчанию
        </button>

        <button type="button" onClick={onSave}>
          Сохранить
        </button>

        <button type="button" onClick={onGoHome}>
          Назад
        </button>
      </div>
    </div>
  );
});
