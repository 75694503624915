import { Vector2, Vector3 } from '@babylonjs/core';
import { Real } from '../../types/points/real';

export class ConvertorVector {
  static toReal(vector: Vector2 | Vector3) {
    if (vector instanceof Vector3) {
      return new Real(vector.x, vector.z);
    }
    return new Real(vector.x, vector.y);
  }

  static toV2(vector: Vector2 | Vector3) {
    if (vector instanceof Vector2) {
      return vector;
    }

    return new Vector2(vector.x, vector.z);
  }

  static toV3(vector: Vector2 | Vector3, y = 0) {
    if (vector instanceof Vector3) {
      return vector;
    }

    return new Vector3(vector.x, y, vector.y);
  }
}
