import { AbstractMesh, Animation, Scene, Vector3 } from '@babylonjs/core';

export function stateAnim(
  target: AbstractMesh,
  targetState: { targetRotation?: number; targetPosition: Vector3 },
  duration: number,
  scene: Scene,
  animationSpeed = 40,
  framePerSecond = 60,
) {
  const animationMove = new Animation(
    'rotationAnimation',
    'position', // анимируемое свойство (в данном случае, поворот по оси Y)
    framePerSecond, // скорость анимации (фреймов в секунду)
    Animation.ANIMATIONTYPE_VECTOR3, // тип анимации (в данном случае, числовое значение)
    Animation.ANIMATIONLOOPMODE_CONSTANT, // режим повтора анимации (в данном случае, постоянный)
  );

  // Установите конечное значение анимации
  animationMove.setKeys([
    { frame: 0, value: target.position },
    { frame: duration, value: targetState.targetPosition },
  ]);

  target.animations = [];

  target.animations.push(animationMove);
  if (targetState.targetRotation) {
    // Установите конечное значение анимации
    const animationRotation = new Animation(
      'rotationAnimation',
      'rotation.y', // анимируемое свойство (в данном случае, поворот по оси Y)
      framePerSecond, // скорость анимации (фреймов в секунду)
      Animation.ANIMATIONTYPE_FLOAT, // тип анимации (в данном случае, числовое значение)
      Animation.ANIMATIONLOOPMODE_CONSTANT, // режим повтора анимации (в данном случае, постоянный)
    );

    let { targetRotation } = targetState;
    let startRotation = target.rotation.y;

    if (startRotation > targetRotation) {
      if (Math.abs(startRotation - targetRotation) > Math.abs(startRotation - targetRotation + Math.PI * 2)) {
        targetRotation += Math.PI * 2;
      } else if (Math.abs(startRotation - targetRotation) > Math.abs(startRotation - targetRotation - Math.PI * 2)) {
        startRotation -= Math.PI * 2;
      }
    } else if (Math.abs(startRotation - targetRotation) > Math.abs(startRotation - targetRotation + Math.PI * 2)) {
      startRotation += Math.PI * 2;
    } else if (Math.abs(startRotation - targetRotation) > Math.abs(startRotation - targetRotation - Math.PI * 2)) {
      targetRotation -= Math.PI * 2;
    }

    animationRotation.setKeys([
      { frame: 0, value: startRotation }, // начальное значение анимации (текущее значение поворота объекта)
      { frame: duration, value: targetRotation }, // конечное значение анимации (новое значение поворота)
    ]);
    target.animations.push(animationRotation);
  }

  // Запустите анимацию на объекте
  return scene.beginAnimation(target, 0, duration, false, animationSpeed);
}
