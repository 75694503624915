import React from 'react';
import BuyNewSlot from '../img/buyNewSlot.png';
import CrossTail from '../img/crossTail.png';
import DontCrossSelfTail from '../img/dontCrossSelf.png';
import FinishTail from '../img/extendZone.png';
import InviteFriends from '../img/inviteFriend.png';
import Merging from '../img/merging.png';
import OfflineIncome from '../img/offlineIncome.png';
import Refill from '../img/refill.png';

import styles from './styles.module.sass';

interface ITip {
  color: string;
  tip: string;
  text: string;
}

export function Loader() {
  const tipList: ITip[] = [
    {
      tip: FinishTail,
      text: 'Заканчивай свой шлейф',
      color: 'blue',
    },
    {
      tip: CrossTail,
      text: 'Переезжай чужие шлейфы',
      color: 'blue',
    },
    {
      tip: DontCrossSelfTail,
      text: 'Не касайся своего шлейфа',
      color: 'red',
    },
    {
      tip: Refill,
      text: 'Энергия машинок тратится , \n'
        + 'каждый раз когда на ней играют. \n'
        + 'Заправка восстанавливает энергию.',
      color: 'blue',
    },
    {
      tip: Merging,
      text: 'Не забывай улучшать свои \n'
        + 'машинки через Тюнинг',
      color: 'blue',
    },
    {
      tip: BuyNewSlot,
      text: 'Расширяй парковку, \n'
        + 'покупая новые места',
      color: 'blue',
    },
    {
      tip: InviteFriends,
      text: 'Приглашай друзей в игру\n'
        + ' и соревнуйтесь - так веселее !',
      color: 'blue',
    },
    {
      tip: OfflineIncome,
      text: 'Свободные места на парковке\n'
        + 'приносят оффлайн доход',
      color: 'blue',
    },
  ];

  const curTip = tipList[Math.floor(Math.random() * tipList.length)];

  const stylesTip: React.CSSProperties = {
    color: curTip.color === 'blue' ? '#8FA7FF' : '#FF5C7E',
    borderColor: curTip.color === 'blue' ? '#8FA7FF' : '#FF5C7E',
  };

  return (
    <div className={styles.main}>
      <div className={styles.tipText} style={stylesTip}>
        {curTip.text}
      </div>
      <img src={curTip.tip} className={styles.img} alt="" />
      <span className={styles.textLoad}>Загрузка ...</span>
    </div>
  );
}
