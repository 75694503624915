import managerApi from '../../../api/managerApi';
import { gameStore } from '../../stores/gameStore';

export class AdController {
  protected skipInDev = true;
  protected logging = false;

  load() {
    if (this.skipInDev && process.env.NODE_ENV === 'development') {
      gameStore.setPreloadAd(true);
      return;
    }

    managerApi
      .prepareAd()
      .then((data) => {
        this.logLoad(data.result);
        gameStore.setPreloadAd(data.result);
      })
      .catch((error) => {
        console.error(error); /* Ошибка */
      });
  }

  show(callback: () => void) {
    if (this.skipInDev && process.env.NODE_ENV === 'development') {
      callback();
      return;
    }

    managerApi
      .showAd()
      .then((data) => {
        this.logShow(data.result);
        callback();
        setInterval(() => {
          this.load();
        }, 1000 * 30);
      })
      .catch((error) => {
        console.log({ setPreloadAd: false });
        gameStore.setPreloadAd(false);
        console.error(error); /* Ошибка */
      });
  }

  protected logShow(isShowed: boolean) {
    if (!this.logging) return;
    if (isShowed) {
      if (process.env.NODE_ENV === 'development') console.trace('Реклама успешно показана');
      return;
    }

    if (process.env.NODE_ENV === 'development') console.trace('Ошибка при показе');
  }

  protected logLoad(isLoaded: boolean) {
    if (!this.logging) return;

    if (!isLoaded) {
      if (process.env.NODE_ENV === 'development') console.trace('Реклама не загружена');
      return;
    }

    if (process.env.NODE_ENV === 'development') console.trace('Реклама загружена');
  }
}
