//
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
//
// GENERATED USING @colyseus/schema 2.0.6
//

import { Schema, type } from '@colyseus/schema';
import { CoordinateScheme } from './CoordinateScheme';

export class Coin extends Schema {
  @type('string') public id!: string;
  @type(CoordinateScheme) public realPos: CoordinateScheme = new CoordinateScheme();
  @type(CoordinateScheme) public startPos: CoordinateScheme = new CoordinateScheme();
  @type('string') public owner!: string;
}
