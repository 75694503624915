import { Real } from '../../types';

export function getArea(vertices: Real[]): number {
  let area = 0;
  const numVertices = vertices.length;

  for (let i = 0; i < numVertices; i++) {
    const currentVertex = vertices[i];
    const nextVertex = vertices[(i + 1) % numVertices];

    area += currentVertex.x * nextVertex.y - nextVertex.x * currentVertex.y;
  }

  return Math.abs(area / 2);
}
