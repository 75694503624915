import { Real } from './real';
import { BasePoint } from './basePoint';

export class Coordinate extends BasePoint {
  toReal() {
    const newX = (this.halfSize - this.x) / this.step;
    const newY = (this.halfSize * -1 + this.y) / this.step;
    return new Real(newX, newY);
  }
}
