import okManager from './managers/Ok';
import tgManager from './managers/TG';
import vkManager from './managers/Vk';

export interface IManagerUser {
  firstName: string;
  lastName: string;
  avatar: string;
  id: string;
}

class ManagerApi {
  isInit = false;
  platform: 'vk' | 'ok' | 'tg' = 'vk';

  userData?: IManagerUser;

  checkPlatform() {
    const url = window.location.ancestorOrigins[0];
    //  || new URL(window.location.href).origin;
    console.log(`ancestorOrigins: ${url}`);
    switch (url) {
      case 'https://vk.com':
      case 'https://m.vk.com':
      case 'https://vk.ru':
        this.platform = 'vk';
        break;
      case 'https://ok.ru':
        this.platform = 'ok';
        break;
      // case 'https://overtaking.cookieapp.ru':
      // case 'https://127.0.0.1:10888':
      case 'https://web.telegram.org':
      case 'https://wrapper.playdeck.io':
        this.platform = 'tg';
        break;
      default:
        this.platform = 'vk';
    }
  }

  init() {
    this.checkPlatform();

    return new Promise((resolve) => {
      switch (this.platform) {
        case 'vk':
          vkManager.init().then(() => resolve(this.platform));
          return;
        case 'ok':
          okManager.init().then(() => resolve(this.platform));
          return;
        case 'tg':
          tgManager.init().then(() => resolve(this.platform));
          return;
        default:
          throw new Error('Unknown platform');
      }
    });
  }

  async getUserData(): Promise<IManagerUser> {
    switch (this.platform) {
      case 'vk':
        return vkManager.getUserInfo();
      case 'ok':
        return okManager.getUserInfo();
      case 'tg':
        return tgManager.getUserInfo();
      default:
        throw new Error('Unknown platform');
    }
  }

  prepareAd(): Promise<{ result: boolean }> {
    switch (this.platform) {
      case 'vk':
        return vkManager.prepareAd();
      case 'ok':
        return okManager.prepareAd();
      case 'tg':
        return tgManager.prepareAd();
      default:
        throw new Error('Unknown platform');
    }
  }

  showAd(): Promise<{ result: boolean }> {
    switch (this.platform) {
      case 'vk':
        return vkManager.showAd();
      case 'ok':
        return okManager.showAd();
      case 'tg':
        return tgManager.showAd();
      default:
        throw new Error('Unknown platform');
    }
  }

  showStory(image: string): Promise<{ result: boolean }> {
    switch (this.platform) {
      case 'vk':
        return vkManager.showStory(image);
      case 'ok':
        return okManager.showStory(image);
      case 'tg':
        return tgManager.showStory(image);
      default:
        throw new Error('Unknown platform');
    }
  }

  showShoppingWindow(id: string, amount = 0, description = '', photoUrl = ''): Promise<{ result: boolean }> {
    switch (this.platform) {
      case 'vk':
        return vkManager.showShoppingWindow(id);
      case 'ok':
        return okManager.showShoppingWindow(id);
      case 'tg':
        return tgManager.showShoppingWindow(id, amount, description, photoUrl);
      default:
        throw new Error('Unknown platform');
    }
  }
}

export default new ManagerApi();
